(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cZ.aN === region.dB.aN)
	{
		return 'on line ' + region.cZ.aN;
	}
	return 'on lines ' + region.cZ.aN + ' through ' + region.dB.aN;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gz,
		impl.h4,
		impl.hI,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		af: func(record.af),
		c_: record.c_,
		cG: record.cG
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.af;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.c_;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cG) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gz,
		impl.h4,
		impl.hI,
		function(sendToApp, initialModel) {
			var view = impl.id;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gz,
		impl.h4,
		impl.hI,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cR && impl.cR(sendToApp)
			var view = impl.id;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.aZ);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ac) && (_VirtualDom_doc.title = title = doc.ac);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.g0;
	var onUrlRequest = impl.g1;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cR: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ep === next.ep
							&& curr.dV === next.dV
							&& curr.ek.a === next.ek.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gz: function(flags)
		{
			return A3(impl.gz, flags, _Browser_getUrl(), key);
		},
		id: impl.id,
		h4: impl.h4,
		hI: impl.hI
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gm: 'hidden', fv: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gm: 'mozHidden', fv: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gm: 'msHidden', fv: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gm: 'webkitHidden', fv: 'webkitvisibilitychange' }
		: { gm: 'hidden', fv: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		hw: _Browser_getScene(),
		eZ: {
			ii: _Browser_window.pageXOffset,
			e5: _Browser_window.pageYOffset,
			e1: _Browser_doc.documentElement.clientWidth,
			gl: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		e1: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		gl: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			hw: {
				e1: node.scrollWidth,
				gl: node.scrollHeight
			},
			eZ: {
				ii: node.scrollLeft,
				e5: node.scrollTop,
				e1: node.clientWidth,
				gl: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			hw: _Browser_getScene(),
			eZ: {
				ii: x,
				e5: y,
				e1: _Browser_doc.documentElement.clientWidth,
				gl: _Browser_doc.documentElement.clientHeight
			},
			f0: {
				ii: x + rect.left,
				e5: y + rect.top,
				e1: rect.width,
				gl: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.a4.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.a4.b, xhr)); });
		$elm$core$Maybe$isJust(request.by) && _Http_track(router, xhr, request.by.a);

		try {
			xhr.open(request.bg, request.bB, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.bB));
		}

		_Http_configureRequest(xhr, request);

		request.aZ.a && xhr.setRequestHeader('Content-Type', request.aZ.a);
		xhr.send(request.aZ.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.a8; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.bw.a || 0;
	xhr.responseType = request.a4.d;
	xhr.withCredentials = request.fc;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		bB: xhr.responseURL,
		hG: xhr.status,
		hH: xhr.statusText,
		a8: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			hB: event.loaded,
			O: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			hi: event.loaded,
			O: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.r) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.v),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.v);
		} else {
			var treeLen = builder.r * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.w) : builder.w;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.r);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.v) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.v);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{w: nodeList, r: (len / $elm$core$Array$branchFactor) | 0, v: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {dO: fragment, dV: host, eh: path, ek: port_, ep: protocol, eq: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$App$User$Messages$DoLoad = {$: 4};
var $author$project$App$Messages$UserMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$AppBar$Model$Search = 0;
var $author$project$App$AppBar$Model$init = 0;
var $author$project$App$Wiktionary$Model$Entities = 1;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$App$Wiktionary$Model$init = F3(
	function (context, maybeAccess, ids) {
		return {de: maybeAccess, dp: context, dq: $elm$core$Dict$empty, dL: $elm$core$Maybe$Nothing, b3: ids, eG: $elm$core$Set$empty, eI: $elm$core$Maybe$Nothing, e4: $elm$core$Dict$empty};
	});
var $author$project$App$Effort$Model$init = function (maybeAccess) {
	return {
		de: maybeAccess,
		dz: $elm$core$Maybe$Nothing,
		el: _List_Nil,
		eM: $elm$core$Maybe$Nothing,
		e2: A3($author$project$App$Wiktionary$Model$init, 1, maybeAccess, _List_Nil)
	};
};
var $author$project$App$Entities$Model$init = $author$project$App$Wiktionary$Model$init(1);
var $author$project$App$Metrics$Model$init = {dX: _List_Nil, d7: $elm$core$Maybe$Nothing};
var $author$project$App$Wiktionary$Model$Search = 0;
var $author$project$App$Search$SearchBar$Model$Idle = {$: 0};
var $author$project$App$Search$SearchBar$Model$init = function (maybeAccess) {
	return {de: maybeAccess, cc: $elm$core$Maybe$Nothing, cP: '', eM: $author$project$App$Search$SearchBar$Model$Idle};
};
var $author$project$App$Search$Model$initSearchResult = {fA: _List_Nil, fT: _List_Nil, h2: $elm$core$Maybe$Nothing, e2: _List_Nil};
var $author$project$App$Search$Model$init = function (maybeAccess) {
	return {
		de: maybeAccess,
		e9: $elm$core$Maybe$Nothing,
		ex: $author$project$App$Search$Model$initSearchResult,
		eB: $author$project$App$Search$SearchBar$Model$init(maybeAccess),
		eM: $elm$core$Maybe$Nothing,
		e3: A3($author$project$App$Wiktionary$Model$init, 0, maybeAccess, _List_Nil)
	};
};
var $author$project$App$User$Model$LoggedOut = {$: 1};
var $author$project$App$User$LogIn$Model$GetUserId = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$User$LogIn$Model$SignUp = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$User$LogIn$Model$init = function (isToSignUp) {
	return {
		dM: isToSignUp ? $author$project$App$User$LogIn$Model$SignUp(
			{dA: '', gd: '', gH: ''}) : $author$project$App$User$LogIn$Model$GetUserId(
			{dA: ''}),
		eM: $elm$core$Maybe$Nothing,
		eU: false,
		eY: $elm$core$Maybe$Nothing
	};
};
var $author$project$App$User$Model$init = {
	gF: $elm$core$Maybe$Nothing,
	d5: $author$project$App$User$LogIn$Model$init(false),
	eM: $author$project$App$User$Model$LoggedOut,
	h0: $elm$core$Maybe$Nothing,
	eX: $elm$core$Maybe$Nothing,
	h6: $elm$core$Maybe$Nothing
};
var $author$project$App$Model$init = {
	de: $elm$core$Maybe$Nothing,
	di: $author$project$App$AppBar$Model$init,
	ds: $elm$core$Maybe$Nothing,
	dy: $author$project$App$Effort$Model$init($elm$core$Maybe$Nothing),
	dC: A2($author$project$App$Entities$Model$init, $elm$core$Maybe$Nothing, _List_Nil),
	d7: $author$project$App$Metrics$Model$init,
	eA: $author$project$App$Search$Model$init($elm$core$Maybe$Nothing),
	eX: $author$project$App$User$Model$init
};
var $author$project$App$Messages$AppBarMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Messages$EffortMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Messages$EntitiesMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Messages$MetricsMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Messages$SearchMsg = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$App$Messages$GotWiktIds = function (a) {
	return {$: 8, a: a};
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.hG));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {et: reqs, eP: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.by;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.et));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.eP)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					fc: r.fc,
					aZ: r.aZ,
					a4: A2(_Http_mapExpect, func, r.a4),
					a8: r.a8,
					bg: r.bg,
					bw: r.bw,
					by: r.by,
					bB: r.bB
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{fc: false, aZ: r.aZ, a4: r.a4, a8: r.a8, bg: r.bg, bw: r.bw, by: r.by, bB: r.bB}));
};
var $author$project$Api$timeout = 10000;
var $author$project$Api$urlPrefix = 'api';
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Decoders$wiktIdsDecoder = $elm$json$Json$Decode$list($elm$json$Json$Decode$int);
var $author$project$App$Commands$getEntities = function (_v0) {
	var token = _v0.h0;
	var userId = _v0.h6;
	var languageId = _v0.gF;
	var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/reviews'))));
	var headerVal = 'Bearer ' + token;
	return $elm$http$Http$request(
		{
			aZ: $elm$http$Http$emptyBody,
			a4: A2($elm$http$Http$expectJson, $author$project$App$Messages$GotWiktIds, $author$project$Decoders$wiktIdsDecoder),
			a8: _List_fromArray(
				[
					A2($elm$http$Http$header, 'Authorization', headerVal)
				]),
			bg: 'GET',
			bw: $elm$core$Maybe$Just($author$project$Api$timeout),
			by: $elm$core$Maybe$Nothing,
			bB: url
		});
};
var $author$project$App$Effort$Messages$GotPreEfforts = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Models$EntityDependencyEffort$PreEffort = F4(
	function (isQuiz, entity, dependencyType, effortedDependencies) {
		return {ad: dependencyType, fZ: effortedDependencies, aa: entity, d$: isQuiz};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Models$Effort$Conjugation = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$Effort$Definition = {$: 4};
var $author$project$Models$Effort$Dummy = {$: 6};
var $author$project$Models$Effort$Example = function (a) {
	return {$: 5, a: a};
};
var $author$project$Models$Effort$Gender = {$: 0};
var $author$project$Models$Effort$Plural = {$: 1};
var $author$project$Models$Effort$Variant = function (a) {
	return {$: 2, a: a};
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Models$Conjugation$Imperative = {$: 2};
var $author$project$Models$Conjugation$Indicative = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Models$Conjugation$Subjunctive = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Models$Conjugation$Perfect = 1;
var $author$project$Models$Conjugation$Progressive = 2;
var $author$project$Models$Conjugation$Simple = 0;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Decoders$aspectDecoder = function () {
	var toAspect = function (string) {
		switch (string) {
			case 'Simple':
				return $elm$json$Json$Decode$succeed(0);
			case 'Perfect':
				return $elm$json$Json$Decode$succeed(1);
			case 'Progressive':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid sub tense variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toAspect, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Models$Conjugation$Future = 2;
var $author$project$Models$Conjugation$Past = 1;
var $author$project$Models$Conjugation$Present = 0;
var $author$project$Decoders$tenseDecoder = function () {
	var toTense = function (string) {
		switch (string) {
			case 'Present':
				return $elm$json$Json$Decode$succeed(0);
			case 'Past':
				return $elm$json$Json$Decode$succeed(1);
			case 'Future':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid tense variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toTense, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$mtaDecoder = function () {
	var subjunctiveData = A4(
		$elm$json$Json$Decode$map3,
		$author$project$Models$Conjugation$Subjunctive,
		A2($elm$json$Json$Decode$index, 0, $author$project$Decoders$tenseDecoder),
		A2($elm$json$Json$Decode$index, 1, $author$project$Decoders$aspectDecoder),
		A2(
			$elm$json$Json$Decode$index,
			2,
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)));
	var partial = function (string) {
		if (string === 'Imperative') {
			return $elm$json$Json$Decode$succeed($author$project$Models$Conjugation$Imperative);
		} else {
			return $elm$json$Json$Decode$fail('invalid verbType variant');
		}
	};
	var indicativeData = A3(
		$elm$json$Json$Decode$map2,
		$author$project$Models$Conjugation$Indicative,
		A2($elm$json$Json$Decode$index, 0, $author$project$Decoders$tenseDecoder),
		A2($elm$json$Json$Decode$index, 1, $author$project$Decoders$aspectDecoder));
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$field, 'Indicative', indicativeData),
				A2($elm$json$Json$Decode$field, 'Subjunctive', subjunctiveData),
				A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string)
			]));
}();
var $author$project$Decoders$dependencyTypeDecoder = function () {
	var xmplDecoder = A2(
		$elm$json$Json$Decode$map,
		$author$project$Models$Effort$Example,
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string));
	var variantDecoder = A2(
		$elm$json$Json$Decode$map,
		$author$project$Models$Effort$Variant,
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string));
	var partial = function (string) {
		switch (string) {
			case 'Gender':
				return $elm$json$Json$Decode$succeed($author$project$Models$Effort$Gender);
			case 'Plural':
				return $elm$json$Json$Decode$succeed($author$project$Models$Effort$Plural);
			case 'Definition':
				return $elm$json$Json$Decode$succeed($author$project$Models$Effort$Definition);
			case 'Dummy':
				return $elm$json$Json$Decode$succeed($author$project$Models$Effort$Dummy);
			default:
				return $elm$json$Json$Decode$fail('invalid PoS variant');
		}
	};
	var conjugationDecoder = A2($elm$json$Json$Decode$map, $author$project$Models$Effort$Conjugation, $author$project$Decoders$mtaDecoder);
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$field, 'Variant', variantDecoder),
				A2($elm$json$Json$Decode$field, 'Example', xmplDecoder),
				A2($elm$json$Json$Decode$field, 'Conjugation', conjugationDecoder),
				A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string)
			]));
}();
var $author$project$Models$EntityDependencyEffort$EffortedDependency = F2(
	function (dependency, effort) {
		return {fQ: dependency, dy: effort};
	});
var $author$project$Models$Effort$Effort = function (id) {
	return function (dependencyId) {
		return function (userLanguageId) {
			return function (countQuiz) {
				return function (countCorrectStreak) {
					return function (lastCorrect) {
						return function (createdAt) {
							return function (updatedAt) {
								return function (countCorrect) {
									return function (countReview) {
										return function (lastReview) {
											return function (lastQuiz) {
												return {fD: countCorrect, fE: countCorrectStreak, fF: countQuiz, fG: countReview, fH: createdAt, fR: dependencyId, gt: id, gG: lastCorrect, gI: lastQuiz, gJ: lastReview, h5: updatedAt, h7: userLanguageId};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Decoders$effortDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastQuiz',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'lastReview',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'countReview',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'countCorrect',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'updatedAt',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'createdAt',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'lastCorrect',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'countCorrectStreak',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'countQuiz',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'userLanguageId',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'dependencyId',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$elm$json$Json$Decode$int,
												$elm$json$Json$Decode$succeed($author$project$Models$Effort$Effort)))))))))))));
var $author$project$Models$Entity$UserEntityDependency = F6(
	function (id, entityId, dependency, createdAt, updatedAt, wiktId) {
		return {fH: createdAt, fQ: dependency, bW: entityId, gt: id, h5: updatedAt, $8: wiktId};
	});
var $author$project$Models$Entity$Conjugation = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $author$project$Models$Entity$Definition = function (a) {
	return {$: 4, a: a};
};
var $author$project$Models$Entity$Dummy = {$: 6};
var $author$project$Models$Entity$Example = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Models$Entity$Gender = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Entity$Plural = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$Entity$Variant = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Models$Entity$Feminine = 0;
var $author$project$Models$Entity$Masculine = 1;
var $author$project$Models$Entity$Neuter = 2;
var $author$project$Decoders$genderDecoder = function () {
	var toGender = function (string) {
		switch (string) {
			case 'Feminine':
				return $elm$json$Json$Decode$succeed(0);
			case 'Masculine':
				return $elm$json$Json$Decode$succeed(1);
			case 'Neuter':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid gender');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toGender, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Models$Conjugation$Plural = 1;
var $author$project$Models$Conjugation$Singular = 0;
var $author$project$Decoders$verbCountDecoder = function () {
	var toVerbCount = function (string) {
		switch (string) {
			case 's':
				return $elm$json$Json$Decode$succeed(0);
			case 'p':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('invalid verb count');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toVerbCount, $elm$json$Json$Decode$string);
}();
var $author$project$Models$Conjugation$First = 0;
var $author$project$Models$Conjugation$Second = 1;
var $author$project$Models$Conjugation$Third = 2;
var $author$project$Decoders$verbPersonDecoder = function () {
	var toVerbPerson = function (string) {
		switch (string) {
			case '1':
				return $elm$json$Json$Decode$succeed(0);
			case '2':
				return $elm$json$Json$Decode$succeed(1);
			case '3':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid verb person');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toVerbPerson, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$dependencyDecoder = function () {
	var xmplDecoder = A3(
		$elm$json$Json$Decode$map2,
		$author$project$Models$Entity$Example,
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
	var variantDecoder = A3(
		$elm$json$Json$Decode$map2,
		$author$project$Models$Entity$Variant,
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
	var partial = function (string) {
		if (string === 'Dummy') {
			return $elm$json$Json$Decode$succeed($author$project$Models$Entity$Dummy);
		} else {
			return $elm$json$Json$Decode$fail('invalid PoS variant');
		}
	};
	var conjugationDecoder = A5(
		$elm$json$Json$Decode$map4,
		$author$project$Models$Entity$Conjugation,
		A2($elm$json$Json$Decode$index, 0, $author$project$Decoders$mtaDecoder),
		A2($elm$json$Json$Decode$index, 1, $author$project$Decoders$verbPersonDecoder),
		A2($elm$json$Json$Decode$index, 2, $author$project$Decoders$verbCountDecoder),
		A2($elm$json$Json$Decode$index, 3, $elm$json$Json$Decode$string));
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Models$Entity$Gender,
				A2($elm$json$Json$Decode$field, 'Gender', $author$project$Decoders$genderDecoder)),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Models$Entity$Plural,
				A2($elm$json$Json$Decode$field, 'Plural', $elm$json$Json$Decode$string)),
				A2($elm$json$Json$Decode$field, 'Variant', variantDecoder),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Models$Entity$Definition,
				A2($elm$json$Json$Decode$field, 'Definition', $elm$json$Json$Decode$string)),
				A2($elm$json$Json$Decode$field, 'Example', xmplDecoder),
				A2($elm$json$Json$Decode$field, 'Conjugation', conjugationDecoder),
				A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string)
			]));
}();
var $author$project$Decoders$userEntityDependencyDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'wiktId',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdAt',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'dependency',
				$author$project$Decoders$dependencyDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'entityId',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Models$Entity$UserEntityDependency)))))));
var $author$project$Decoders$effortedDependencyDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'effort',
	$elm$json$Json$Decode$maybe($author$project$Decoders$effortDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dependency',
		$author$project$Decoders$userEntityDependencyDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$EntityDependencyEffort$EffortedDependency)));
var $author$project$Decoders$effortedDependenciesDecoder = $elm$json$Json$Decode$list($author$project$Decoders$effortedDependencyDecoder);
var $author$project$Models$Entity$UserEntity = F6(
	function (id, entity, entityType, userLanguageId, createdAt, updatedAt) {
		return {fH: createdAt, aa: entity, bX: entityType, gt: id, h5: updatedAt, h7: userLanguageId};
	});
var $author$project$Models$Entity$Adjective = 2;
var $author$project$Models$Entity$Adverb = 3;
var $author$project$Models$Entity$Article = 11;
var $author$project$Models$Entity$Conjunction = 5;
var $author$project$Models$Entity$Determiner = 14;
var $author$project$Models$Entity$Infinitive = 1;
var $author$project$Models$Entity$Interjection = 13;
var $author$project$Models$Entity$Noun = 0;
var $author$project$Models$Entity$Particle = 12;
var $author$project$Models$Entity$Phrase = 10;
var $author$project$Models$Entity$Postposition = 7;
var $author$project$Models$Entity$PrepPhrase = 9;
var $author$project$Models$Entity$Preposition = 6;
var $author$project$Models$Entity$Pronoun = 4;
var $author$project$Models$Entity$Proverb = 8;
var $author$project$Decoders$entityTypeDecoder = function () {
	var toEntityType = function (string) {
		switch (string) {
			case 'Noun':
				return $elm$json$Json$Decode$succeed(0);
			case 'Infinitive':
				return $elm$json$Json$Decode$succeed(1);
			case 'Adjective':
				return $elm$json$Json$Decode$succeed(2);
			case 'Adverb':
				return $elm$json$Json$Decode$succeed(3);
			case 'Pronoun':
				return $elm$json$Json$Decode$succeed(4);
			case 'Conjunction':
				return $elm$json$Json$Decode$succeed(5);
			case 'Preposition':
				return $elm$json$Json$Decode$succeed(6);
			case 'Postposition':
				return $elm$json$Json$Decode$succeed(7);
			case 'Proverb':
				return $elm$json$Json$Decode$succeed(8);
			case 'PrepPhrase':
				return $elm$json$Json$Decode$succeed(9);
			case 'Phrase':
				return $elm$json$Json$Decode$succeed(10);
			case 'Article':
				return $elm$json$Json$Decode$succeed(11);
			case 'Particle':
				return $elm$json$Json$Decode$succeed(12);
			case 'Interjection':
				return $elm$json$Json$Decode$succeed(13);
			case 'Determiner':
				return $elm$json$Json$Decode$succeed(14);
			default:
				return $elm$json$Json$Decode$fail('invalid entity type');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toEntityType, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$userEntityDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'createdAt',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'userLanguageId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'entityType',
				$author$project$Decoders$entityTypeDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'entity',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Models$Entity$UserEntity)))))));
var $author$project$Decoders$preEffortDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'effortedDependencies',
	$author$project$Decoders$effortedDependenciesDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dependencyType',
		$author$project$Decoders$dependencyTypeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'entity',
			$author$project$Decoders$userEntityDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'isQuiz',
				$elm$json$Json$Decode$bool,
				$elm$json$Json$Decode$succeed($author$project$Models$EntityDependencyEffort$PreEffort)))));
var $author$project$Decoders$preEffortsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$preEffortDecoder);
var $author$project$App$Commands$getPreEfforts = function (_v0) {
	var token = _v0.h0;
	var userId = _v0.h6;
	var languageId = _v0.gF;
	var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/efforts'))));
	var headerVal = 'Bearer ' + token;
	return A2(
		$elm$core$Platform$Cmd$map,
		$author$project$App$Messages$EffortMsg,
		$elm$http$Http$request(
			{
				aZ: $elm$http$Http$emptyBody,
				a4: A2($elm$http$Http$expectJson, $author$project$App$Effort$Messages$GotPreEfforts, $author$project$Decoders$preEffortsDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'GET',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			}));
};
var $author$project$App$Entities$Messages$WiktComponentMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Wiktionary$Messages$GotWiktRec = function (a) {
	return {$: 6, a: a};
};
var $author$project$Models$Wikt$WiktRec = function (id) {
	return function (word) {
		return function (pos) {
			return function (senses) {
				return function (forms) {
					return function (sounds) {
						return function (related) {
							return function (derived) {
								return function (synonyms) {
									return function (antonyms) {
										return function (addedAt) {
											return {fb: addedAt, dh: antonyms, fS: derived, gh: forms, gt: id, cE: pos, hl: related, hA: senses, hC: sounds, eQ: synonyms, bC: word};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Models$Wikt$Form = F3(
	function (form, tags, source) {
		return {dM: form, hD: source, c1: tags};
	});
var $author$project$Decoders$maybeStringDecoder = $elm$json$Json$Decode$maybe($elm$json$Json$Decode$string);
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Decoders$stringsDecoder = $elm$json$Json$Decode$list($elm$json$Json$Decode$string);
var $author$project$Decoders$stringSetDecoder = A2($elm$json$Json$Decode$map, $elm$core$Set$fromList, $author$project$Decoders$stringsDecoder);
var $author$project$Decoders$formDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'source',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tags',
		$author$project$Decoders$stringSetDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'form',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Models$Wikt$Form))));
var $author$project$Decoders$formsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$formDecoder);
var $author$project$Models$Wikt$Relationship = function (word) {
	return function (tags) {
		return function (english) {
			return function (sense) {
				return function (topics) {
					return function (alt) {
						return function (roman) {
							return function (taxonomic) {
								return function (source) {
									return function (extra) {
										return {fd: alt, f1: english, dI: extra, ht: roman, hz: sense, hD: source, c1: tags, hM: taxonomic, eV: topics, bC: word};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Decoders$maybeStringsDecoder = $elm$json$Json$Decode$maybe($author$project$Decoders$stringsDecoder);
var $author$project$Decoders$relationshipDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extra',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'source',
		$author$project$Decoders$maybeStringDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'taxonomic',
			$author$project$Decoders$maybeStringDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'roman',
				$author$project$Decoders$maybeStringDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'alt',
					$author$project$Decoders$maybeStringDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'topics',
						$author$project$Decoders$maybeStringsDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'sense',
							$author$project$Decoders$maybeStringDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'english',
								$author$project$Decoders$maybeStringDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'tags',
									$author$project$Decoders$maybeStringsDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'word',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Models$Wikt$Relationship)))))))))));
var $author$project$Decoders$relationshipsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$relationshipDecoder);
var $author$project$Models$Wikt$Sense = function (glosses) {
	return function (rawGlosses) {
		return function (tags) {
			return function (topics) {
				return function (examples) {
					return function (synonyms) {
						return function (antonyms) {
							return function (hyponyms) {
								return function (formOf) {
									return function (altOf) {
										return function (qualifier) {
											return {fe: altOf, dh: antonyms, f8: examples, gf: formOf, gj: glosses, gq: hyponyms, he: qualifier, hg: rawGlosses, eQ: synonyms, c1: tags, eV: topics};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Models$Wikt$Example = F5(
	function (etext, etype, english, ref, roman) {
		return {f1: english, f4: etext, f5: etype, hj: ref, ht: roman};
	});
var $author$project$Decoders$exampleDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'roman',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ref',
		$author$project$Decoders$maybeStringDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'english',
			$author$project$Decoders$maybeStringDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'type',
				$author$project$Decoders$maybeStringDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'text',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Models$Wikt$Example))))));
var $author$project$Decoders$examplesDecoder = $elm$json$Json$Decode$list($author$project$Decoders$exampleDecoder);
var $author$project$Decoders$maybeStringSetDecoder = $elm$json$Json$Decode$maybe($author$project$Decoders$stringSetDecoder);
var $author$project$Models$Wikt$WithWord = F2(
	function (word, extra) {
		return {dI: extra, bC: word};
	});
var $author$project$Decoders$withWordDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extra',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'word',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Models$Wikt$WithWord)));
var $author$project$Decoders$withWordsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$withWordDecoder);
var $author$project$Decoders$senseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'qualifier',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'altOf',
		$elm$json$Json$Decode$maybe($author$project$Decoders$withWordsDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'formOf',
			$elm$json$Json$Decode$maybe($author$project$Decoders$withWordsDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'hyponyms',
				$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'antonyms',
					$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'synonyms',
						$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'examples',
							$elm$json$Json$Decode$maybe($author$project$Decoders$examplesDecoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'topics',
								$author$project$Decoders$maybeStringsDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'tags',
									$author$project$Decoders$maybeStringSetDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'rawGlosses',
										$author$project$Decoders$maybeStringsDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'glosses',
											$author$project$Decoders$maybeStringsDecoder,
											$elm$json$Json$Decode$succeed($author$project$Models$Wikt$Sense))))))))))));
var $author$project$Decoders$sensesDecoder = $elm$json$Json$Decode$list($author$project$Decoders$senseDecoder);
var $author$project$Models$Wikt$Sound = function (ipa) {
	return {gC: ipa};
};
var $author$project$Decoders$soundDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'ipa',
	$author$project$Decoders$maybeStringDecoder,
	$elm$json$Json$Decode$succeed($author$project$Models$Wikt$Sound));
var $author$project$Decoders$soundsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$soundDecoder);
var $author$project$Models$Wikt$Adj = 2;
var $author$project$Models$Wikt$Adv = 3;
var $author$project$Models$Wikt$Article = 19;
var $author$project$Models$Wikt$Character = 17;
var $author$project$Models$Wikt$Circumfix = 23;
var $author$project$Models$Wikt$Circumpos = 24;
var $author$project$Models$Wikt$Conj = 14;
var $author$project$Models$Wikt$Contradiction = 16;
var $author$project$Models$Wikt$Det = 13;
var $author$project$Models$Wikt$Infix = 26;
var $author$project$Models$Wikt$Interfix = 22;
var $author$project$Models$Wikt$Intj = 8;
var $author$project$Models$Wikt$Name = 4;
var $author$project$Models$Wikt$Noun = 0;
var $author$project$Models$Wikt$Num = 6;
var $author$project$Models$Wikt$Particle = 21;
var $author$project$Models$Wikt$Phrase = 5;
var $author$project$Models$Wikt$PostP = 20;
var $author$project$Models$Wikt$Prefix = 10;
var $author$project$Models$Wikt$Prep = 12;
var $author$project$Models$Wikt$PrepPhrase = 15;
var $author$project$Models$Wikt$Pron = 7;
var $author$project$Models$Wikt$Proverb = 11;
var $author$project$Models$Wikt$Punct = 25;
var $author$project$Models$Wikt$Suffix = 9;
var $author$project$Models$Wikt$Symbol = 18;
var $author$project$Models$Wikt$Verb = 1;
var $author$project$Decoders$wiktPoSDecoder = function () {
	var toPoS = function (s) {
		switch (s) {
			case 'noun':
				return $elm$json$Json$Decode$succeed(0);
			case 'verb':
				return $elm$json$Json$Decode$succeed(1);
			case 'adj':
				return $elm$json$Json$Decode$succeed(2);
			case 'adv':
				return $elm$json$Json$Decode$succeed(3);
			case 'name':
				return $elm$json$Json$Decode$succeed(4);
			case 'phrase':
				return $elm$json$Json$Decode$succeed(5);
			case 'num':
				return $elm$json$Json$Decode$succeed(6);
			case 'pron':
				return $elm$json$Json$Decode$succeed(7);
			case 'intj':
				return $elm$json$Json$Decode$succeed(8);
			case 'suffic':
				return $elm$json$Json$Decode$succeed(9);
			case 'prefix':
				return $elm$json$Json$Decode$succeed(10);
			case 'proverb':
				return $elm$json$Json$Decode$succeed(11);
			case 'prep':
				return $elm$json$Json$Decode$succeed(12);
			case 'det':
				return $elm$json$Json$Decode$succeed(13);
			case 'conj':
				return $elm$json$Json$Decode$succeed(14);
			case 'prep_phrase':
				return $elm$json$Json$Decode$succeed(15);
			case 'contradiction':
				return $elm$json$Json$Decode$succeed(16);
			case 'character':
				return $elm$json$Json$Decode$succeed(17);
			case 'symbol':
				return $elm$json$Json$Decode$succeed(18);
			case 'article':
				return $elm$json$Json$Decode$succeed(19);
			case 'postp':
				return $elm$json$Json$Decode$succeed(20);
			case 'particle':
				return $elm$json$Json$Decode$succeed(21);
			case 'interfix':
				return $elm$json$Json$Decode$succeed(22);
			case 'circumfix':
				return $elm$json$Json$Decode$succeed(23);
			case 'circumpos':
				return $elm$json$Json$Decode$succeed(24);
			case 'punct':
				return $elm$json$Json$Decode$succeed(25);
			case 'infix':
				return $elm$json$Json$Decode$succeed(26);
			default:
				return $elm$json$Json$Decode$fail('invalid frequency variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toPoS, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$wiktRecDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'addedAt',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'antonyms',
		$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'synonyms',
			$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'derived',
				$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'related',
					$elm$json$Json$Decode$maybe($author$project$Decoders$relationshipsDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'sounds',
						$elm$json$Json$Decode$maybe($author$project$Decoders$soundsDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'forms',
							$elm$json$Json$Decode$maybe($author$project$Decoders$formsDecoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'senses',
								$author$project$Decoders$sensesDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'pos',
									$author$project$Decoders$wiktPoSDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'word',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$elm$json$Json$Decode$int,
											$elm$json$Json$Decode$succeed($author$project$Models$Wikt$WiktRec))))))))))));
var $author$project$App$Wiktionary$Commands$getWiktRec = F2(
	function (_v0, id) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + ('/wikt/' + $elm$core$String$fromInt(id))))));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$emptyBody,
				a4: A2($elm$http$Http$expectJson, $author$project$App$Wiktionary$Messages$GotWiktRec, $author$project$Decoders$wiktRecDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'GET',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $author$project$App$Commands$getWiktRec = F2(
	function (a, id) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Messages$EntitiesMsg,
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$App$Entities$Messages$WiktComponentMsg,
				A2($author$project$App$Wiktionary$Commands$getWiktRec, a, id)));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$App$AppBar$Model$Effort = 2;
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$App$AppBar$Update$update = F2(
	function (msg, model) {
		var cmd = function (nav) {
			return ((nav === 2) && (model !== 2)) ? $elm$core$Platform$Cmd$none : $elm$core$Platform$Cmd$none;
		};
		var nav = msg;
		return _Utils_Tuple2(
			nav,
			cmd(nav));
	});
var $author$project$App$Effort$Model$Posting = 0;
var $author$project$App$Effort$Messages$WiktMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Effort$PostAnswers = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Models$Effort$ShowAnswers = function (a) {
	return {$: 2, a: a};
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Models$Effort$areAllAnswersCorrect = function (checkedAs) {
	var filterFn = function (cA) {
		if (cA.$ === 1) {
			return true;
		} else {
			return false;
		}
	};
	return A2(
		$elm$core$List$all,
		filterFn,
		$elm$core$Dict$values(checkedAs));
};
var $author$project$Models$Effort$Correct = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Models$Effort$Wrong = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Util$commaSeparated = function (l) {
	return A2($elm$core$String$join, ', ', l);
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$trim = _String_trim;
var $elm$core$String$words = _String_words;
var $author$project$Models$Effort$isOneAnswerCorrect = F2(
	function (correctAnswer, userAnswer) {
		var processed = $elm$core$String$trim(userAnswer);
		return _Utils_eq(processed, correctAnswer) || (_Utils_eq(
			$elm$core$String$toLower(processed),
			$elm$core$String$toLower(correctAnswer)) || _Utils_eq(
			$elm$core$String$words(processed),
			$elm$core$String$words(correctAnswer)));
	});
var $author$project$Util$maybeStrToStr = function (maybeStr) {
	if (!maybeStr.$) {
		var str = maybeStr.a;
		return str;
	} else {
		return '';
	}
};
var $author$project$Models$Effort$checkUserAnswers = F2(
	function (userAs, correctAs) {
		var toChecked = function (_v7) {
			var maybeInd = _v7.a;
			var correct = _v7.b;
			var uA = _v7.c;
			return correct ? A2($author$project$Models$Effort$Correct, maybeInd, uA) : A2($author$project$Models$Effort$Wrong, maybeInd, uA);
		};
		var checkFn = function (_v6) {
			var k = _v6.a;
			var v = _v6.b;
			switch (userAs.$) {
				case 0:
					var dico = userAs.a;
					var userAnswer = A2($elm$core$Dict$get, k, dico);
					return _Utils_Tuple3(
						$elm$core$Maybe$Just(k),
						A2(
							$author$project$Models$Effort$isOneAnswerCorrect,
							v,
							$author$project$Util$maybeStrToStr(userAnswer)),
						userAnswer);
				case 1:
					var s = userAs.a;
					return _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						A2($author$project$Models$Effort$isOneAnswerCorrect, v, s),
						$elm$core$Maybe$Just(s));
				default:
					var dico = userAs.a;
					var thirdOfThree = function (_v5) {
						var a = _v5.c;
						return a;
					};
					var secondOfThree = function (_v4) {
						var a = _v4.b;
						return a;
					};
					var makeTuple = function (_v3) {
						var kk = _v3.a;
						var vv = _v3.b;
						return _Utils_Tuple3(
							$elm$core$Maybe$Just(kk),
							A2($author$project$Models$Effort$isOneAnswerCorrect, v, vv),
							$elm$core$Maybe$Just(vv));
					};
					var tups = A2(
						$elm$core$List$map,
						makeTuple,
						$elm$core$Dict$toList(dico));
					var _v2 = A2($elm$core$List$filter, secondOfThree, tups);
					if (_v2.b) {
						var first = _v2.a;
						return first;
					} else {
						return _Utils_Tuple3(
							$elm$core$Maybe$Nothing,
							false,
							$elm$core$Maybe$Just(
								$author$project$Util$commaSeparated(
									A2(
										$elm$core$List$map,
										$author$project$Util$maybeStrToStr,
										A2($elm$core$List$map, thirdOfThree, tups)))));
					}
			}
		};
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var k = _v0.a;
					var v = _v0.b;
					return _Utils_Tuple2(
						k,
						toChecked(
							checkFn(
								_Utils_Tuple2(k, v))));
				},
				$elm$core$Dict$toList(correctAs)));
	});
var $author$project$Models$Effort$Quiz = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Effort$WithDependentId = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Models$Effort$WithEffortId = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Models$Effort$checkedAnswersToMapped = F2(
	function (dico, checkedAnswers) {
		var checkedToEffortType = function (c) {
			if (!c.$) {
				var a = c.b;
				return $author$project$Models$Effort$Quiz(
					_Utils_Tuple2(false, a));
			} else {
				var a = c.b;
				return $author$project$Models$Effort$Quiz(
					_Utils_Tuple2(true, a));
			}
		};
		var mapFn = function (_v1) {
			var dId = _v1.a;
			var checked = _v1.b;
			var _v0 = A2($elm$core$Dict$get, dId, dico);
			if (!_v0.$) {
				var eId = _v0.a;
				return A2(
					$author$project$Models$Effort$WithEffortId,
					eId,
					checkedToEffortType(checked));
			} else {
				return A2(
					$author$project$Models$Effort$WithDependentId,
					dId,
					checkedToEffortType(checked));
			}
		};
		return A2(
			$elm$core$List$map,
			mapFn,
			$elm$core$Dict$toList(checkedAnswers));
	});
var $author$project$App$Effort$Update$checkAnswer = function (model) {
	var getNewState = function (eD) {
		var state = eD.eL;
		var effortIds = eD.fY;
		var effortType = eD.bU;
		var correctAnswers = eD.fC;
		var _v1 = _Utils_Tuple2(state, effortType);
		_v1$3:
		while (true) {
			if (!_v1.b.$) {
				switch (_v1.a.$) {
					case 1:
						var _v2 = _v1.a;
						var userAs = _v1.b.a;
						var checkedAnswers = A2($author$project$Models$Effort$checkUserAnswers, userAs, correctAnswers);
						return $author$project$Models$Effort$areAllAnswersCorrect(checkedAnswers) ? A2(
							$author$project$Models$Effort$PostAnswers,
							checkedAnswers,
							A2($author$project$Models$Effort$checkedAnswersToMapped, effortIds, checkedAnswers)) : $author$project$Models$Effort$ShowAnswers(checkedAnswers);
					case 2:
						var checked = _v1.a.a;
						return A2(
							$author$project$Models$Effort$PostAnswers,
							checked,
							A2($author$project$Models$Effort$checkedAnswersToMapped, effortIds, checked));
					case 3:
						var _v3 = _v1.a;
						var x = _v3.a;
						var y = _v3.b;
						return A2($author$project$Models$Effort$PostAnswers, x, y);
					default:
						break _v1$3;
				}
			} else {
				break _v1$3;
			}
		}
		return state;
	};
	return _Utils_update(
		model,
		{
			dz: function () {
				var _v0 = model.dz;
				if (!_v0.$) {
					var data = _v0.a;
					return $elm$core$Maybe$Just(
						_Utils_update(
							data,
							{
								eL: getNewState(data)
							}));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}()
		});
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$EntityDependencyEffort$extractWiktId = function (_v0) {
	var effortedDependencies = _v0.fZ;
	return $elm$core$List$head(
		A2(
			$elm$core$List$filterMap,
			function ($) {
				return $.$8;
			},
			A2(
				$elm$core$List$map,
				function ($) {
					return $.fQ;
				},
				effortedDependencies)));
};
var $author$project$App$Effort$Commands$getWiktRec = F2(
	function (a, id) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Effort$Messages$WiktMsg,
			A2($author$project$App$Wiktionary$Commands$getWiktRec, a, id));
	});
var $author$project$App$Effort$Messages$PostedEffort = function (a) {
	return {$: 5, a: a};
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Encoders$effortTypeEncoder = F2(
	function (varEncoder, et) {
		if (!et.$) {
			var val = et.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Quiz',
						varEncoder(val))
					]));
		} else {
			return $elm$json$Json$Encode$string('Review');
		}
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Encoders$maybeEncoder = F2(
	function (encoder, maybea) {
		if (!maybea.$) {
			var a = maybea.a;
			return encoder(a);
		} else {
			return $elm$json$Json$Encode$null;
		}
	});
var $author$project$Encoders$mappedAnswerEncoder = function (mappedAnswer) {
	var eTE = function (_v1) {
		var correct = _v1.a;
		var maybeAnswer = _v1.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$bool(correct),
					A2($author$project$Encoders$maybeEncoder, $elm$json$Json$Encode$string, maybeAnswer)
				]));
	};
	if (!mappedAnswer.$) {
		var id = mappedAnswer.a;
		var eT = mappedAnswer.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'WithEffortId',
					A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$int(id),
								A2($author$project$Encoders$effortTypeEncoder, eTE, eT)
							])))
				]));
	} else {
		var id = mappedAnswer.a;
		var eT = mappedAnswer.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'WithDependentId',
					A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$int(id),
								A2($author$project$Encoders$effortTypeEncoder, eTE, eT)
							])))
				]));
	}
};
var $author$project$Encoders$effortPayloadEncoder = function (mAs) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'efforts',
				A2($elm$json$Json$Encode$list, $author$project$Encoders$mappedAnswerEncoder, mAs))
			]));
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$App$Effort$Commands$postEfforts = F2(
	function (_v0, mappedAnswers) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/effort'))));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$jsonBody(
					$author$project$Encoders$effortPayloadEncoder(mappedAnswers)),
				a4: $elm$http$Http$expectWhatever($author$project$App$Effort$Messages$PostedEffort),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'POST',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $author$project$App$Effort$Model$Posted = 1;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$Effort$AwaitingAnswers = {$: 1};
var $author$project$Models$Effort$AwaitingReview = {$: 0};
var $author$project$Models$Effort$EffortData = F9(
	function (dependencyType, entity, dependencies, depOrders, effortIds, correctAnswers, effortType, state, wiktRec) {
		return {fC: correctAnswers, fP: depOrders, a0: dependencies, ad: dependencyType, fY: effortIds, bU: effortType, aa: entity, eL: state, ig: wiktRec};
	});
var $author$project$Models$Effort$Review = {$: 1};
var $author$project$Models$Effort$effortTypeFromUserAnswers = F2(
	function (userAs, isQuiz) {
		return isQuiz ? $author$project$Models$Effort$Quiz(userAs) : $author$project$Models$Effort$Review;
	});
var $author$project$Models$Conjugation$orderVerbPersonCount = F2(
	function (p, c) {
		var _v0 = _Utils_Tuple2(p, c);
		if (!_v0.b) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return 1;
				case 1:
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return 2;
				default:
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					return 3;
			}
		} else {
			switch (_v0.a) {
				case 0:
					var _v7 = _v0.a;
					var _v8 = _v0.b;
					return 4;
				case 1:
					var _v9 = _v0.a;
					var _v10 = _v0.b;
					return 5;
				default:
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					return 6;
			}
		}
	});
var $author$project$Models$Entity$getConjugationDepOrders = function (uEDs) {
	var fnc = function (uED) {
		var _v0 = uED.fQ;
		if (_v0.$ === 3) {
			var p = _v0.b;
			var c = _v0.c;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(
					uED.gt,
					A2($author$project$Models$Conjugation$orderVerbPersonCount, p, c)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return $elm$core$Dict$fromList(
		A2($elm$core$List$filterMap, fnc, uEDs));
};
var $author$project$Models$Entity$genderToDefiniteArticle = function (g) {
	switch (g) {
		case 0:
			return 'die';
		case 1:
			return 'der';
		default:
			return 'das';
	}
};
var $author$project$Models$EntityDependencyEffort$getCorrectAnswer = F2(
	function (_v0, _v1) {
		var entity = _v0.aa;
		var id = _v1.gt;
		var dependency = _v1.fQ;
		switch (dependency.$) {
			case 0:
				var g = dependency.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(
						id,
						$author$project$Models$Entity$genderToDefiniteArticle(g)));
			case 1:
				var p = dependency.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(id, p));
			case 3:
				var v = dependency.d;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(id, v));
			case 4:
				var d = dependency.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(id, entity));
			case 6:
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(id, entity));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$EntityDependencyEffort$getCorrectAnswers = function (_v0) {
	var entity = _v0.aa;
	var effortedDependencies = _v0.fZ;
	var pairs = A2(
		$elm$core$List$filterMap,
		$author$project$Models$EntityDependencyEffort$getCorrectAnswer(entity),
		A2(
			$elm$core$List$map,
			function ($) {
				return $.fQ;
			},
			effortedDependencies));
	return ($elm$core$List$length(pairs) > 0) ? $elm$core$Maybe$Just(
		$elm$core$Dict$fromList(pairs)) : $elm$core$Maybe$Nothing;
};
var $author$project$Models$Effort$ManyToMany = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$Effort$OneToMany = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$Effort$OneToOne = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $author$project$Models$Effort$initUserAnswers = F2(
	function (dT, answers) {
		var getFirstLetter = function (s) {
			var _v3 = $elm$core$String$toList(s);
			if (_v3.b) {
				var c = _v3.a;
				return $elm$core$String$fromList(
					_List_fromArray(
						[c]));
			} else {
				return '';
			}
		};
		var initOneToOne = function (nswrs) {
			return $elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (_v2) {
						var dId = _v2.a;
						var answer = _v2.b;
						return _Utils_Tuple2(
							dId,
							getFirstLetter(answer));
					},
					$elm$core$Dict$toList(nswrs)));
		};
		var answersLen = $elm$core$List$length(
			$elm$core$Dict$toList(answers));
		var answersFromLen = function (count) {
			return $elm$core$Dict$fromList(
				A3(
					$elm$core$List$map2,
					$elm$core$Tuple$pair,
					A2($elm$core$List$range, 1, count),
					A2(
						$elm$core$List$map,
						getFirstLetter,
						$elm$core$Dict$values(answers))));
		};
		switch (dT.$) {
			case 0:
				return $elm$core$Maybe$Just(
					$author$project$Models$Effort$ManyToMany(
						answersFromLen(answersLen)));
			case 1:
				return $elm$core$Maybe$Just(
					$author$project$Models$Effort$ManyToMany(
						answersFromLen(answersLen)));
			case 4:
				return $elm$core$Maybe$Just(
					$author$project$Models$Effort$OneToMany(
						function () {
							var _v1 = $elm$core$Dict$values(answers);
							if (_v1.b) {
								var first = _v1.a;
								return getFirstLetter(first);
							} else {
								return '';
							}
						}()));
			case 6:
				return $elm$core$Maybe$Just(
					$author$project$Models$Effort$OneToOne(
						initOneToOne(answers)));
			case 3:
				return $elm$core$Maybe$Just(
					$author$project$Models$Effort$OneToOne(
						initOneToOne(answers)));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$EntityDependencyEffort$makeAnswers = function (preEffort) {
	var dependencyType = preEffort.ad;
	var _v0 = $author$project$Models$EntityDependencyEffort$getCorrectAnswers(preEffort);
	if (!_v0.$) {
		var answers = _v0.a;
		var _v1 = A2($author$project$Models$Effort$initUserAnswers, dependencyType, answers);
		if (!_v1.$) {
			var userAnswers = _v1.a;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(answers, userAnswers));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$EntityDependencyEffort$makeEffortIdsDict = function (eDs) {
	var filterMapFn = function (_v1) {
		var dependency = _v1.fQ;
		var effort = _v1.dy;
		var _v0 = _Utils_Tuple2(dependency, effort);
		if (!_v0.b.$) {
			var d = _v0.a;
			var e = _v0.b.a;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(d.gt, e.gt));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return $elm$core$Dict$fromList(
		A2($elm$core$List$filterMap, filterMapFn, eDs));
};
var $author$project$Models$EntityDependencyEffort$preEffortToEffortData = F2(
	function (preEffort, maybeWiktRec) {
		var isQuiz = preEffort.d$;
		var initState = isQuiz ? $author$project$Models$Effort$AwaitingAnswers : $author$project$Models$Effort$AwaitingReview;
		var _v0 = preEffort;
		var entity = _v0.aa;
		var effortedDependencies = _v0.fZ;
		var dependencyType = _v0.ad;
		var deps = A2(
			$elm$core$List$map,
			function ($) {
				return $.fQ;
			},
			effortedDependencies);
		var depOrders = function () {
			if (dependencyType.$ === 3) {
				return $elm$core$Maybe$Just(
					$author$project$Models$Entity$getConjugationDepOrders(deps));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var _v1 = _Utils_Tuple2(
			effortedDependencies,
			$author$project$Models$EntityDependencyEffort$makeAnswers(preEffort));
		if (_v1.a.b && (!_v1.b.$)) {
			var _v2 = _v1.a;
			var rest = _v2.b;
			var _v3 = _v1.b.a;
			var cAs = _v3.a;
			var uAs = _v3.b;
			return $elm$core$Maybe$Just(
				A9(
					$author$project$Models$Effort$EffortData,
					dependencyType,
					entity,
					deps,
					depOrders,
					$author$project$Models$EntityDependencyEffort$makeEffortIdsDict(effortedDependencies),
					cAs,
					A2($author$project$Models$Effort$effortTypeFromUserAnswers, uAs, isQuiz),
					initState,
					maybeWiktRec));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$EntityDependencyEffort$initEffortData = F2(
	function (pEs, dico) {
		var getWiktRec = function (id) {
			return A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hh;
				},
				A2($elm$core$Dict$get, id, dico));
		};
		if (pEs.b) {
			var first = pEs.a;
			return A2(
				$author$project$Models$EntityDependencyEffort$preEffortToEffortData,
				first,
				A2(
					$elm$core$Maybe$andThen,
					getWiktRec,
					$author$project$Models$EntityDependencyEffort$extractWiktId(first)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Effort$Update$removeFromPreEfforts = function (model) {
	var effortData = model.dz;
	var preEfforts = model.el;
	var popped = function () {
		if (preEfforts.b) {
			var first = preEfforts.a;
			var rest = preEfforts.b;
			return rest;
		} else {
			return _List_Nil;
		}
	}();
	return _Utils_update(
		model,
		{
			dz: A2($author$project$Models$EntityDependencyEffort$initEffortData, popped, model.e2.dq),
			el: popped,
			eM: $elm$core$Maybe$Just(1)
		});
};
var $author$project$App$Effort$Update$setEffortData = function (model) {
	return _Utils_update(
		model,
		{
			dz: A2($author$project$Models$EntityDependencyEffort$initEffortData, model.el, model.e2.dq)
		});
};
var $author$project$App$Effort$Update$setModel = F2(
	function (model, preEfforts) {
		return $author$project$App$Effort$Update$setEffortData(
			_Utils_update(
				model,
				{el: preEfforts, eM: $elm$core$Maybe$Nothing}));
	});
var $author$project$Models$Wikt$InList = 0;
var $author$project$Models$Wikt$Reviewed = 1;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$diff = F2(
	function (t1, t2) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, t) {
					return A2($elm$core$Dict$remove, k, t);
				}),
			t1,
			t2);
	});
var $elm$core$Set$diff = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$diff, dict1, dict2);
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $author$project$Util$getUnique = function (vs) {
	return $elm$core$Set$toList(
		$elm$core$Set$fromList(vs));
};
var $author$project$Models$Wikt$getRelationshipWords = function (wikt) {
	var senseRelsss = _List_fromArray(
		[
			A2(
			$elm$core$List$filterMap,
			function ($) {
				return $.eQ;
			},
			wikt.hA),
			A2(
			$elm$core$List$filterMap,
			function ($) {
				return $.dh;
			},
			wikt.hA)
		]);
	var rootRelss = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[wikt.eQ, wikt.dh, wikt.hl, wikt.fS]));
	var fromMaybe = $elm$core$Maybe$map(
		$elm$core$List$map(
			function ($) {
				return $.bC;
			}));
	return $author$project$Util$getUnique(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.bC;
			},
			$elm$core$List$concat(
				$elm$core$List$concat(
					A2($elm$core$List$cons, rootRelss, senseRelsss)))));
};
var $author$project$App$Wiktionary$Update$getNewWords = F2(
	function (_v0, wiktRec) {
		var wordIds = _v0.e4;
		var recsWords = $elm$core$Set$fromList(
			$author$project$Models$Wikt$getRelationshipWords(wiktRec));
		var currentWords = $elm$core$Set$fromList(
			$elm$core$Dict$keys(wordIds));
		return $elm$core$Set$toList(
			A2($elm$core$Set$diff, recsWords, currentWords));
	});
var $author$project$App$Wiktionary$Messages$GotWordIds = function (a) {
	return {$: 7, a: a};
};
var $author$project$Encoders$wiktIdsPayloadEncoder = function (words) {
	var wordsEncoder = $elm$json$Json$Encode$list($elm$json$Json$Encode$string);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'words',
				wordsEncoder(words))
			]));
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Decoders$wiktWordIdsDecoder = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int));
var $author$project$App$Wiktionary$Commands$getWordIds = F2(
	function (_v0, words) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/wikts'))));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$jsonBody(
					$author$project$Encoders$wiktIdsPayloadEncoder(words)),
				a4: A2($elm$http$Http$expectJson, $author$project$App$Wiktionary$Messages$GotWordIds, $author$project$Decoders$wiktWordIdsDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'POST',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$App$Wiktionary$Update$getNewWordIds = F2(
	function (model, wiktRec) {
		var getWithAccess = function (access) {
			return A2(
				$author$project$App$Wiktionary$Commands$getWordIds,
				access,
				A2($author$project$App$Wiktionary$Update$getNewWords, model, wiktRec));
		};
		return (!model.dp) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2($elm$core$Maybe$map, getWithAccess, model.de)) : $elm$core$Platform$Cmd$none;
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$App$Wiktionary$Update$getWiktRecsForWord = F2(
	function (model, word) {
		var doTheThing = function (access) {
			var isNew = function (wid) {
				return !A2($elm$core$Dict$member, wid, model.dq);
			};
			var ids = A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Dict$get, word, model.e4));
			var idsNew = A2($elm$core$List$filter, isNew, ids);
			return $elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					$author$project$App$Wiktionary$Commands$getWiktRec(access),
					idsNew));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2($elm$core$Maybe$map, doTheThing, model.de));
	});
var $author$project$Models$Wikt$AdjDep = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$Wikt$NounDep = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Wikt$VerbDep = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Dict$intersect = F2(
	function (t1, t2) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v0) {
					return A2($elm$core$Dict$member, k, t2);
				}),
			t1);
	});
var $elm$core$Set$intersect = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$intersect, dict1, dict2);
	});
var $author$project$Util$setAinB = F2(
	function (setA, setB) {
		return _Utils_eq(
			setA,
			A2($elm$core$Set$intersect, setA, setB));
	});
var $author$project$Util$setsIntersect = F2(
	function (setA, setB) {
		return !_Utils_eq(
			$elm$core$Set$empty,
			A2($elm$core$Set$intersect, setA, setB));
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Models$Wikt$filterForms = F3(
	function (tagsMust, tagsMustNot, forms) {
		var tagsCount = $elm$core$Set$size(tagsMust);
		var sortByFnc = function (_v2) {
			var tags = _v2.c1;
			return $elm$core$Set$size(tags) - tagsCount;
		};
		var formHasNoNonTag = function (_v1) {
			var tags = _v1.c1;
			return !A2($author$project$Util$setsIntersect, tags, tagsMustNot);
		};
		var formHasAllTags = function (_v0) {
			var tags = _v0.c1;
			return A2($author$project$Util$setAinB, tagsMust, tags);
		};
		var conds = function (f) {
			return formHasAllTags(f) && formHasNoNonTag(f);
		};
		return A2(
			$elm$core$List$sortBy,
			sortByFnc,
			A2($elm$core$List$filter, conds, forms));
	});
var $author$project$Models$Wikt$getForm = function (forms) {
	var makeUnique = function (vs) {
		return $elm$core$Set$toList(
			$elm$core$Set$fromList(vs));
	};
	return makeUnique(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.dM;
			},
			forms));
};
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$core$Set$union = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$union, dict1, dict2);
	});
var $author$project$Util$setsUnion = function (sets) {
	return A3($elm$core$List$foldl, $elm$core$Set$union, $elm$core$Set$empty, sets);
};
var $author$project$Models$Wikt$getNounGender = function (_v0) {
	var senses = _v0.hA;
	var strToGender = function (s) {
		switch (s) {
			case 'feminine':
				return $elm$core$Maybe$Just(0);
			case 'masculine':
				return $elm$core$Maybe$Just(1);
			case 'neuter':
				return $elm$core$Maybe$Just(2);
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var hasNoPlural = function (ts) {
		return !A2($elm$core$Set$member, 'plural', ts);
	};
	var tagsSet = $author$project$Util$setsUnion(
		A2(
			$elm$core$List$filter,
			hasNoPlural,
			A2(
				$elm$core$List$filterMap,
				function ($) {
					return $.c1;
				},
				senses)));
	var _v1 = A2(
		$elm$core$List$filterMap,
		strToGender,
		$elm$core$Set$toList(tagsSet));
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var genders = _v1;
		return $elm$core$Maybe$Just(genders);
	}
};
var $author$project$Models$Wikt$getNounPlural = function (_v0) {
	var forms = _v0.gh;
	var tagsHasPlural = function (_v4) {
		var tags = _v4.c1;
		return A2($elm$core$Set$member, 'plural', tags);
	};
	var tagsHasOneMember = function (_v3) {
		var tags = _v3.c1;
		return $elm$core$Set$size(tags) === 1;
	};
	var pluralForms = function () {
		if (!forms.$) {
			var fs = forms.a;
			return A2(
				$elm$core$List$filter,
				tagsHasOneMember,
				A2($elm$core$List$filter, tagsHasPlural, fs));
		} else {
			return _List_Nil;
		}
	}();
	var _v1 = A2(
		$elm$core$List$map,
		function ($) {
			return $.dM;
		},
		pluralForms);
	if (!_v1.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var vs = _v1;
		return $elm$core$Maybe$Just(vs);
	}
};
var $author$project$Models$Wikt$Irregular = 2;
var $author$project$Models$Wikt$Strong = 1;
var $author$project$Models$Wikt$Weak = 0;
var $author$project$Models$Wikt$getVerbCat = function (_v0) {
	var senses = _v0.hA;
	var strToCat = function (s) {
		switch (s) {
			case 'weak':
				return $elm$core$Maybe$Just(0);
			case 'strong':
				return $elm$core$Maybe$Just(1);
			case 'irregular':
				return $elm$core$Maybe$Just(2);
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var cats = A2(
		$elm$core$List$filterMap,
		strToCat,
		$elm$core$Set$toList(
			$author$project$Util$setsUnion(
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.c1;
					},
					senses))));
	return ($elm$core$List$length(cats) > 0) ? $elm$core$Maybe$Just(cats) : $elm$core$Maybe$Nothing;
};
var $author$project$Models$Wikt$getWiktDep = function (wikt) {
	var unqCuts = function (vs) {
		return $elm$core$Set$toList(
			$elm$core$Set$fromList(
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						$elm$core$String$split(' or '),
						vs))));
	};
	var op = F2(
		function (v1, v2) {
			return $author$project$Models$Wikt$getForm(
				function () {
					var _v1 = wikt.gh;
					if (!_v1.$) {
						var fs = _v1.a;
						return A3(
							$author$project$Models$Wikt$filterForms,
							$elm$core$Set$fromList(v1),
							$elm$core$Set$fromList(v2),
							fs);
					} else {
						return _List_Nil;
					}
				}());
		});
	var _v0 = wikt.cE;
	switch (_v0) {
		case 0:
			return $elm$core$Maybe$Just(
				$author$project$Models$Wikt$NounDep(
					{
						dP: $author$project$Models$Wikt$getNounGender(wikt),
						g7: $author$project$Models$Wikt$getNounPlural(wikt)
					}));
		case 1:
			return $elm$core$Maybe$Just(
				$author$project$Models$Wikt$VerbDep(
					{
						fi: unqCuts(
							A2(
								op,
								_List_fromArray(
									['auxiliary']),
								_List_Nil)),
						fu: $author$project$Models$Wikt$getVerbCat(wikt),
						g6: A2(
							op,
							_List_fromArray(
								['participle', 'past']),
							_List_Nil),
						cB: A2(
							op,
							_List_fromArray(
								['indicative', 'perfect', 'singular', 'third-person']),
							_List_fromArray(
								['subordinate-clause'])),
						g9: A2(
							op,
							_List_fromArray(
								['indicative', 'present', 'singular', 'third-person']),
							_List_fromArray(
								['subordinate-clause'])),
						ha: A2(
							op,
							_List_fromArray(
								['indicative', 'preterite', 'singular', 'third-person']),
							_List_fromArray(
								['subordinate-clause']))
					}));
		case 2:
			return $elm$core$Maybe$Just(
				$author$project$Models$Wikt$AdjDep(
					{
						fy: $elm$core$List$head(
							A2(
								op,
								_List_fromArray(
									['comparative']),
								_List_fromArray(
									['indefinite', 'definite', 'without-article']))),
						hJ: $elm$core$List$head(
							A2(
								op,
								_List_fromArray(
									['superlative']),
								_List_fromArray(
									['indefinite', 'definite', 'without-article'])))
					}));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Wiktionary$Update$loadWiktRec = F2(
	function (model, wiktRec) {
		return _Utils_update(
			model,
			{
				dq: A3(
					$elm$core$Dict$insert,
					wiktRec.gt,
					{
						fO: $author$project$Models$Wikt$getWiktDep(wiktRec),
						hh: wiktRec,
						eM: A2(
							$elm$core$Maybe$map,
							function (_v0) {
								return 0;
							},
							wiktRec.fb)
					},
					model.dq)
			});
	});
var $author$project$App$Wiktionary$Messages$PostedReview = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$App$Wiktionary$Commands$postReview = F2(
	function (_v0, wiktId) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + ('/wikt/' + ($elm$core$String$fromInt(wiktId) + '/review'))))));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$emptyBody,
				a4: $elm$http$Http$expectWhatever(
					$author$project$App$Wiktionary$Messages$PostedReview(wiktId)),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'POST',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $author$project$Models$Entity$Wikt = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$Wikt$dependenciesFromNoun = function (_v0) {
	var gender = _v0.dP;
	var plural = _v0.g7;
	var pluralsToDeps = $elm$core$List$map(
		function (p) {
			return $author$project$Models$Entity$Plural(p);
		});
	var gendersToDeps = $elm$core$List$map(
		function (g) {
			return $author$project$Models$Entity$Gender(g);
		});
	return $elm$core$List$concat(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2($elm$core$Maybe$map, gendersToDeps, gender),
					A2($elm$core$Maybe$map, pluralsToDeps, plural)
				])));
};
var $author$project$Models$Wikt$dependenciesFromVerb = function (_v0) {
	var present3S = _v0.g9;
	var preterite = _v0.ha;
	var perfect3S = _v0.cB;
	var preteriteToDep = A3(
		$author$project$Models$Entity$Conjugation,
		A2($author$project$Models$Conjugation$Indicative, 1, 0),
		2,
		0);
	var present3SToDep = A3(
		$author$project$Models$Entity$Conjugation,
		A2($author$project$Models$Conjugation$Indicative, 0, 0),
		2,
		0);
	var perfect3SToDep = A3(
		$author$project$Models$Entity$Conjugation,
		A2($author$project$Models$Conjugation$Indicative, 0, 1),
		2,
		0);
	return $elm$core$List$concat(
		_List_fromArray(
			[
				A2($elm$core$List$map, present3SToDep, present3S),
				A2($elm$core$List$map, preteriteToDep, preterite),
				A2($elm$core$List$map, perfect3SToDep, perfect3S)
			]));
};
var $author$project$Models$Wikt$entityTypeFromPoS = function (pos) {
	switch (pos) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 2:
			return $elm$core$Maybe$Just(2);
		case 3:
			return $elm$core$Maybe$Just(3);
		case 7:
			return $elm$core$Maybe$Just(4);
		case 14:
			return $elm$core$Maybe$Just(5);
		case 12:
			return $elm$core$Maybe$Just(6);
		case 20:
			return $elm$core$Maybe$Just(7);
		case 11:
			return $elm$core$Maybe$Just(8);
		case 15:
			return $elm$core$Maybe$Just(9);
		case 5:
			return $elm$core$Maybe$Just(10);
		case 19:
			return $elm$core$Maybe$Just(11);
		case 21:
			return $elm$core$Maybe$Just(12);
		case 8:
			return $elm$core$Maybe$Just(13);
		case 13:
			return $elm$core$Maybe$Just(14);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Wikt$makeEntityPayload = function (_v0) {
	var rec = _v0.hh;
	var dep = _v0.fO;
	var deps = A2(
		$elm$core$List$cons,
		$author$project$Models$Entity$Dummy,
		function () {
			_v2$2:
			while (true) {
				if (!dep.$) {
					switch (dep.a.$) {
						case 0:
							var struct = dep.a.a;
							return $author$project$Models$Wikt$dependenciesFromNoun(struct);
						case 1:
							var struct = dep.a.a;
							return $author$project$Models$Wikt$dependenciesFromVerb(struct);
						default:
							break _v2$2;
					}
				} else {
					break _v2$2;
				}
			}
			return _List_Nil;
		}());
	var _v1 = $author$project$Models$Wikt$entityTypeFromPoS(rec.cE);
	if (!_v1.$) {
		var et = _v1.a;
		return $elm$core$Maybe$Just(
			{
				a0: A2(
					$elm$core$List$map,
					function (d) {
						return _Utils_Tuple2(
							d,
							$elm$core$Maybe$Just(
								$author$project$Models$Entity$Wikt(rec.gt)));
					},
					deps),
				aa: rec.bC,
				bX: et,
				hD: $author$project$Models$Entity$Wikt(rec.gt)
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Wiktionary$Messages$PostedEntity = function (a) {
	return {$: 5, a: a};
};
var $author$project$Encoders$genderEncoder = function (g) {
	switch (g) {
		case 0:
			return $elm$json$Json$Encode$string('Feminine');
		case 1:
			return $elm$json$Json$Encode$string('Masculine');
		default:
			return $elm$json$Json$Encode$string('Neuter');
	}
};
var $author$project$Encoders$aspectEncoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('Simple');
		case 1:
			return $elm$json$Json$Encode$string('Perfect');
		default:
			return $elm$json$Json$Encode$string('Progressive');
	}
};
var $author$project$Encoders$tenseEncoder = function (t) {
	switch (t) {
		case 0:
			return $elm$json$Json$Encode$string('Present');
		case 1:
			return $elm$json$Json$Encode$string('Past');
		default:
			return $elm$json$Json$Encode$string('Future');
	}
};
var $author$project$Encoders$mtaEncoder = function (mta) {
	switch (mta.$) {
		case 0:
			var t = mta.a;
			var a = mta.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Indicative',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Encoders$tenseEncoder(t),
									$author$project$Encoders$aspectEncoder(a)
								])))
					]));
		case 1:
			var t = mta.a;
			var a = mta.b;
			var mi = mta.c;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Subjunctive',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Encoders$tenseEncoder(t),
									$author$project$Encoders$aspectEncoder(a),
									A2($author$project$Encoders$maybeEncoder, $elm$json$Json$Encode$int, mi)
								])))
					]));
		default:
			return $elm$json$Json$Encode$string('Imperative');
	}
};
var $author$project$Encoders$verbCountEncoder = function (verbCount) {
	if (!verbCount) {
		return $elm$json$Json$Encode$string('s');
	} else {
		return $elm$json$Json$Encode$string('p');
	}
};
var $author$project$Encoders$verbPersonEncoder = function (verbPerson) {
	switch (verbPerson) {
		case 0:
			return $elm$json$Json$Encode$string('1');
		case 1:
			return $elm$json$Json$Encode$string('2');
		default:
			return $elm$json$Json$Encode$string('3');
	}
};
var $author$project$Encoders$dependencyEncoder = function (d) {
	var twoStringList = F2(
		function (s1, s2) {
			return A2(
				$elm$json$Json$Encode$list,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$json$Json$Encode$string(s1),
						$elm$json$Json$Encode$string(s2)
					]));
		});
	switch (d.$) {
		case 0:
			var g = d.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Gender',
						$author$project$Encoders$genderEncoder(g))
					]));
		case 1:
			var s = d.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Plural',
						$elm$json$Json$Encode$string(s))
					]));
		case 4:
			var s = d.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Definition',
						$elm$json$Json$Encode$string(s))
					]));
		case 5:
			var s1 = d.a;
			var s2 = d.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Example',
						A2(twoStringList, s1, s2))
					]));
		case 2:
			var s1 = d.a;
			var s2 = d.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Variant',
						A2(twoStringList, s1, s2))
					]));
		case 3:
			var m = d.a;
			var p = d.b;
			var c = d.c;
			var s = d.d;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Conjugation',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Encoders$mtaEncoder(m),
									$author$project$Encoders$verbPersonEncoder(p),
									$author$project$Encoders$verbCountEncoder(c),
									$elm$json$Json$Encode$string(s)
								])))
					]));
		default:
			return $elm$json$Json$Encode$string('Dummy');
	}
};
var $author$project$Encoders$entityTypeEncoder = function (et) {
	switch (et) {
		case 0:
			return $elm$json$Json$Encode$string('Noun');
		case 1:
			return $elm$json$Json$Encode$string('Infinitive');
		case 2:
			return $elm$json$Json$Encode$string('Adjective');
		case 3:
			return $elm$json$Json$Encode$string('Adverb');
		case 4:
			return $elm$json$Json$Encode$string('Pronoun');
		case 5:
			return $elm$json$Json$Encode$string('Conjunction');
		case 6:
			return $elm$json$Json$Encode$string('Preposition');
		case 7:
			return $elm$json$Json$Encode$string('Postposition');
		case 8:
			return $elm$json$Json$Encode$string('Proverb');
		case 9:
			return $elm$json$Json$Encode$string('PrepPhrase');
		case 10:
			return $elm$json$Json$Encode$string('Phrase');
		case 11:
			return $elm$json$Json$Encode$string('Article');
		case 12:
			return $elm$json$Json$Encode$string('Particle');
		case 13:
			return $elm$json$Json$Encode$string('Interjection');
		default:
			return $elm$json$Json$Encode$string('Determiner');
	}
};
var $author$project$Encoders$sourceEncoder = function (src) {
	switch (src.$) {
		case 0:
			var id = src.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Ding',
						$elm$json$Json$Encode$int(id))
					]));
		case 1:
			var id = src.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Wikt',
						$elm$json$Json$Encode$int(id))
					]));
		default:
			return $elm$json$Json$Encode$string('User');
	}
};
var $author$project$Encoders$entityPayloadEncoder = function (_v0) {
	var entity = _v0.aa;
	var entityType = _v0.bX;
	var dependencies = _v0.a0;
	var source = _v0.hD;
	var depTupDecoder = function (_v1) {
		var dep = _v1.a;
		var maybeSource = _v1.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$author$project$Encoders$dependencyEncoder(dep),
					A2($author$project$Encoders$maybeEncoder, $author$project$Encoders$sourceEncoder, maybeSource)
				]));
	};
	var depTupsEncoder = $elm$json$Json$Encode$list(depTupDecoder);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'entity',
				$elm$json$Json$Encode$string(entity)),
				_Utils_Tuple2(
				'entityType',
				$author$project$Encoders$entityTypeEncoder(entityType)),
				_Utils_Tuple2(
				'dependencies',
				depTupsEncoder(dependencies)),
				_Utils_Tuple2(
				'source',
				$author$project$Encoders$sourceEncoder(source))
			]));
};
var $author$project$Models$Entity$Ding = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Entity$User = {$: 2};
var $author$project$Decoders$sourceDecoder = function () {
	var partial = function (string) {
		if (string === 'User') {
			return $elm$json$Json$Decode$succeed($author$project$Models$Entity$User);
		} else {
			return $elm$json$Json$Decode$fail('invalid source variant');
		}
	};
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Models$Entity$Ding,
				A2($elm$json$Json$Decode$field, 'Ding', $elm$json$Json$Decode$int)),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Models$Entity$Wikt,
				A2($elm$json$Json$Decode$field, 'Wikt', $elm$json$Json$Decode$int)),
				A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string)
			]));
}();
var $author$project$App$Wiktionary$Commands$postEntity = F2(
	function (_v0, payload) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/entity'))));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$jsonBody(
					$author$project$Encoders$entityPayloadEncoder(payload)),
				a4: A2($elm$http$Http$expectJson, $author$project$App$Wiktionary$Messages$PostedEntity, $author$project$Decoders$sourceDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'POST',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $author$project$App$Wiktionary$Update$preparePayloadAndPostEntity = F2(
	function (_v0, id) {
		var access = _v0.de;
		var core = _v0.dq;
		var payload = A2(
			$elm$core$Maybe$andThen,
			$author$project$Models$Wikt$makeEntityPayload,
			A2($elm$core$Dict$get, id, core));
		var _v1 = _Utils_Tuple2(payload, access);
		if ((!_v1.a.$) && (!_v1.b.$)) {
			var p = _v1.a.a;
			var a = _v1.b.a;
			return A2($author$project$App$Wiktionary$Commands$postEntity, a, p);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$App$Wiktionary$Update$isShowMore = F2(
	function (sM, wid) {
		return _Utils_eq(
			sM,
			$elm$core$Maybe$Just(wid));
	});
var $author$project$App$Wiktionary$Update$processClickedShowMore = F2(
	function (model, wid) {
		return _Utils_update(
			model,
			{
				eI: A2($author$project$App$Wiktionary$Update$isShowMore, model.eI, wid) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(wid)
			});
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$App$Wiktionary$Update$processClickedTag = F2(
	function (model, tag) {
		return _Utils_update(
			model,
			{
				eG: A2($elm$core$Set$member, tag, model.eG) ? A2($elm$core$Set$remove, tag, model.eG) : A2($elm$core$Set$insert, tag, model.eG)
			});
	});
var $author$project$App$Wiktionary$Update$setStatus = F3(
	function (model, status, id) {
		var core = model.dq;
		var op = function (c) {
			return _Utils_update(
				model,
				{
					dq: A3(
						$elm$core$Dict$insert,
						id,
						_Utils_update(
							c,
							{
								eM: $elm$core$Maybe$Just(status)
							}),
						core)
				});
		};
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				op,
				A2($elm$core$Dict$get, id, core)));
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $author$project$Util$mergeDictsPerf = F2(
	function (dictCurrent, dictAdd) {
		return A6(
			$elm$core$Dict$merge,
			F2(
				function (key, a) {
					return A2($elm$core$Dict$insert, key, a);
				}),
			F3(
				function (key, _v0, b) {
					return A2($elm$core$Dict$insert, key, b);
				}),
			F2(
				function (key, b) {
					return A2($elm$core$Dict$insert, key, b);
				}),
			dictCurrent,
			dictAdd,
			$elm$core$Dict$empty);
	});
var $author$project$App$Wiktionary$Update$updateWordIds = F2(
	function (model, wordIds) {
		return _Utils_update(
			model,
			{
				e4: A2($author$project$Util$mergeDictsPerf, model.e4, wordIds)
			});
	});
var $author$project$App$Wiktionary$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var wid = msg.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$App$Wiktionary$Update$preparePayloadAndPostEntity, model, wid));
			case 2:
				var wid = msg.a;
				return _Utils_Tuple2(
					A2($author$project$App$Wiktionary$Update$processClickedShowMore, model, wid),
					$elm$core$Platform$Cmd$none);
			case 3:
				var t = msg.a;
				return _Utils_Tuple2(
					A2($author$project$App$Wiktionary$Update$processClickedTag, model, t),
					$elm$core$Platform$Cmd$none);
			case 4:
				var word = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dL: A2(
								$elm$core$Maybe$andThen,
								$elm$core$List$head,
								A2($elm$core$Dict$get, word, model.e4))
						}),
					A2($author$project$App$Wiktionary$Update$getWiktRecsForWord, model, word));
			case 6:
				if (!msg.a.$) {
					var wiktRec = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$App$Wiktionary$Update$loadWiktRec, model, wiktRec),
						A2($author$project$App$Wiktionary$Update$getNewWordIds, model, wiktRec));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (!msg.a.$) {
					var wordIds = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$App$Wiktionary$Update$updateWordIds, model, wordIds),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if ((!msg.a.$) && (msg.a.a.$ === 1)) {
					var id = msg.a.a.a;
					return _Utils_Tuple2(
						A3($author$project$App$Wiktionary$Update$setStatus, model, 0, id),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var wid = msg.a;
				var cmd = function (a) {
					return A2($author$project$App$Wiktionary$Commands$postReview, a, wid);
				};
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2($elm$core$Maybe$map, cmd, model.de)));
			default:
				if (!msg.b.$) {
					var id = msg.a;
					return _Utils_Tuple2(
						A3($author$project$App$Wiktionary$Update$setStatus, model, 1, id),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Models$Effort$setUserAnswer = F3(
	function (userAnswers, maybeInd, answer) {
		var _v0 = _Utils_Tuple2(userAnswers, maybeInd);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var dico = _v0.a.a;
						var id = _v0.b.a;
						return $elm$core$Maybe$Just(
							$author$project$Models$Effort$OneToOne(
								A3($elm$core$Dict$insert, id, answer, dico)));
					} else {
						break _v0$3;
					}
				case 1:
					return $elm$core$Maybe$Just(
						$author$project$Models$Effort$OneToMany(answer));
				default:
					if (!_v0.b.$) {
						var dico = _v0.a.a;
						var ind = _v0.b.a;
						return $elm$core$Maybe$Just(
							$author$project$Models$Effort$ManyToMany(
								A3($elm$core$Dict$insert, ind, answer, dico)));
					} else {
						break _v0$3;
					}
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $author$project$App$Effort$Update$updateAnswer = F3(
	function (model, maybeId, answer) {
		var effortData = model.dz;
		if (!effortData.$) {
			var data = effortData.a;
			var effortType = data.bU;
			if (effortType.$ === 1) {
				return model;
			} else {
				var userAs = effortType.a;
				var _v2 = A3($author$project$Models$Effort$setUserAnswer, userAs, maybeId, answer);
				if (!_v2.$) {
					var newAnswers = _v2.a;
					return _Utils_update(
						model,
						{
							dz: $elm$core$Maybe$Just(
								_Utils_update(
									data,
									{
										bU: $author$project$Models$Effort$Quiz(newAnswers)
									}))
						});
				} else {
					return model;
				}
			}
		} else {
			return model;
		}
	});
var $author$project$App$Effort$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var wiktMsg = msg.a;
				var _v1 = A2($author$project$App$Wiktionary$Update$update, wiktMsg, model.e2);
				var wiktModel = _v1.a;
				var wiktCmd = _v1.b;
				var newModel = _Utils_update(
					model,
					{e2: wiktModel});
				return _Utils_Tuple2(
					$author$project$App$Effort$Update$setEffortData(newModel),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Effort$Messages$WiktMsg, wiktCmd));
			case 1:
				if (!msg.a.$) {
					var preEfforts = msg.a.a;
					var ids = $elm$core$Set$toList(
						$elm$core$Set$fromList(
							A2($elm$core$List$filterMap, $author$project$Models$EntityDependencyEffort$extractWiktId, preEfforts)));
					var getWikt = function (a) {
						return $elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$map,
								$author$project$App$Effort$Commands$getWiktRec(a),
								ids));
					};
					return _Utils_Tuple2(
						A2($author$project$App$Effort$Update$setModel, model, preEfforts),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2($elm$core$Maybe$map, getWikt, model.de)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var maybeInd = msg.a;
				var answer = msg.b;
				return _Utils_Tuple2(
					A3($author$project$App$Effort$Update$updateAnswer, model, maybeInd, answer),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					$author$project$App$Effort$Update$checkAnswer(model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var mappedAnswers = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eM: $elm$core$Maybe$Just(0)
						}),
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							function (a) {
								return A2($author$project$App$Effort$Commands$postEfforts, a, mappedAnswers);
							},
							model.de)));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						$author$project$App$Effort$Update$removeFromPreEfforts(model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Entities$Commands$getWiktRec = F2(
	function (a, id) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Entities$Messages$WiktComponentMsg,
			A2($author$project$App$Wiktionary$Commands$getWiktRec, a, id));
	});
var $author$project$App$Entities$Update$getReviewedIds = function (_v0) {
	var core = _v0.dq;
	var filterMapFnc = function (_v3) {
		var id = _v3.a;
		var status = _v3.b.eM;
		if ((!status.$) && (status.a === 1)) {
			var _v2 = status.a;
			return $elm$core$Maybe$Just(id);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return $elm$core$Set$fromList(
		A2(
			$elm$core$List$filterMap,
			filterMapFnc,
			$elm$core$Dict$toList(core)));
};
var $elm$core$Dict$partition = F2(
	function (isGood, dict) {
		var add = F3(
			function (key, value, _v0) {
				var t1 = _v0.a;
				var t2 = _v0.b;
				return A2(isGood, key, value) ? _Utils_Tuple2(
					A3($elm$core$Dict$insert, key, value, t1),
					t2) : _Utils_Tuple2(
					t1,
					A3($elm$core$Dict$insert, key, value, t2));
			});
		return A3(
			$elm$core$Dict$foldl,
			add,
			_Utils_Tuple2($elm$core$Dict$empty, $elm$core$Dict$empty),
			dict);
	});
var $author$project$App$Entities$Update$updateIds = function (model) {
	var reviewedIds = $author$project$App$Entities$Update$getReviewedIds(model);
	var filterFnc = function (id) {
		return !A2($elm$core$Set$member, id, reviewedIds);
	};
	return _Utils_update(
		model,
		{
			dq: A2(
				$elm$core$Dict$partition,
				F2(
					function (k, _v0) {
						return filterFnc(k);
					}),
				model.dq).a,
			b3: A2($elm$core$List$filter, filterFnc, model.b3)
		});
};
var $author$project$App$Entities$Update$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var wMsg = msg.a;
			var _v1 = A2($author$project$App$Wiktionary$Update$update, wMsg, model);
			var model2 = _v1.a;
			var wCmd = _v1.b;
			return _Utils_Tuple2(
				model2,
				A2($elm$core$Platform$Cmd$map, $author$project$App$Entities$Messages$WiktComponentMsg, wCmd));
		} else {
			var model2 = $author$project$App$Entities$Update$updateIds(model);
			var filterFnc = function (id) {
				return !A2(
					$elm$core$Set$member,
					id,
					$elm$core$Set$fromList(
						$elm$core$Dict$keys(model.dq)));
			};
			var getWikt = function (a) {
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						$author$project$App$Entities$Commands$getWiktRec(a),
						A2(
							$elm$core$List$filter,
							filterFnc,
							A2($elm$core$List$take, 10, model2.b3))));
			};
			return _Utils_Tuple2(
				model2,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$Maybe$map, getWikt, model.de)));
		}
	});
var $author$project$App$Metrics$Update$update = F2(
	function (msg, model) {
		if (!msg.$) {
			if (!msg.a.$) {
				var metricsCombined = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d7: $elm$core$Maybe$Just(metricsCombined)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var hovering = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{dX: hovering}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Search$Messages$SearchBarMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Search$Messages$WiktionaryMsg = function (a) {
	return {$: 0, a: a};
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$App$Search$SearchBar$Model$setStatusToIdle = function (model) {
	return _Utils_update(
		model,
		{eM: $author$project$App$Search$SearchBar$Model$Idle});
};
var $author$project$Models$Search$Conj = 2;
var $author$project$Models$Search$Ding = 0;
var $author$project$Models$Search$UEnt = 3;
var $author$project$Models$Search$Wikt = 1;
var $author$project$Models$Search$resultTypeOrder = function (rT) {
	switch (rT) {
		case 3:
			return 0;
		case 1:
			return 1;
		case 0:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Models$Search$searchResultHas = F2(
	function (_v0, sRT) {
		var ding = _v0.fT;
		var conj = _v0.fA;
		var uent = _v0.h2;
		var wikt = _v0.e2;
		switch (sRT) {
			case 0:
				return ($elm$core$List$length(ding) > 0) ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Nothing;
			case 1:
				return ($elm$core$List$length(wikt) > 0) ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing;
			case 2:
				return ($elm$core$List$length(conj) > 0) ? $elm$core$Maybe$Just(2) : $elm$core$Maybe$Nothing;
			default:
				if (!uent.$) {
					var ents = uent.a;
					return ($elm$core$List$length(ents) > 0) ? $elm$core$Maybe$Just(3) : $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	});
var $author$project$Models$Search$toSearchResultStatus = function (sR) {
	return A2(
		$elm$core$List$sortBy,
		$author$project$Models$Search$resultTypeOrder,
		A2(
			$elm$core$List$filterMap,
			$author$project$Models$Search$searchResultHas(sR),
			_List_fromArray(
				[0, 1, 2, 3])));
};
var $author$project$App$Search$Update$aux = F2(
	function (model, result) {
		var resultTypes = $author$project$Models$Search$toSearchResultStatus(result);
		var firstRT = function () {
			if (resultTypes.b) {
				var first = resultTypes.a;
				return $elm$core$Maybe$Just(first);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var active = function () {
			var _v1 = model.e9;
			if (!_v1.$) {
				var rT = _v1.a;
				return A2($elm$core$List$member, rT, resultTypes) ? $elm$core$Maybe$Just(rT) : firstRT;
			} else {
				return firstRT;
			}
		}();
		return _Utils_update(
			model,
			{
				e9: active,
				ex: result,
				eB: $author$project$App$Search$SearchBar$Model$setStatusToIdle(model.eB),
				eM: function () {
					if (!resultTypes.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var something = resultTypes;
						return $elm$core$Maybe$Just(something);
					}
				}(),
				e3: A3($author$project$App$Wiktionary$Model$init, 0, model.de, result.e2)
			});
	});
var $author$project$App$Search$Commands$getWiktRec = F2(
	function (a, id) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Search$Messages$WiktionaryMsg,
			A2($author$project$App$Wiktionary$Commands$getWiktRec, a, id));
	});
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$App$Search$SearchBar$Model$LoadingSearch = {$: 1};
var $author$project$App$Search$Messages$GotSearchResult = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$Search$SearchResult = F4(
	function (ding, wikt, conj, uent) {
		return {fA: conj, fT: ding, h2: uent, e2: wikt};
	});
var $author$project$Models$EntityDependencyEffort$EntityWithEffortedDependencies = F2(
	function (entity, effortedDependencies) {
		return {fZ: effortedDependencies, aa: entity};
	});
var $author$project$Decoders$entityWithEffortedDependenciesDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'effortedDependencies',
	$elm$json$Json$Decode$list($author$project$Decoders$effortedDependencyDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'entity',
		$author$project$Decoders$userEntityDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$EntityDependencyEffort$EntityWithEffortedDependencies)));
var $author$project$Decoders$entitiesWithEffortedDependenciesDecoder = $elm$json$Json$Decode$list($author$project$Decoders$entityWithEffortedDependenciesDecoder);
var $author$project$Models$Search$SearchIndex = F2(
	function (lineNum, pairs) {
		return {bc: lineNum, g5: pairs};
	});
var $author$project$Decoders$dingIndPairDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$int));
var $author$project$Decoders$searchIndexDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'pairs',
	$elm$json$Json$Decode$list($author$project$Decoders$dingIndPairDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'lineNum',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Models$Search$SearchIndex)));
var $author$project$Decoders$searchResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'uent',
	$elm$json$Json$Decode$maybe($author$project$Decoders$entitiesWithEffortedDependenciesDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'conj',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'wikt',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ding',
				$elm$json$Json$Decode$list($author$project$Decoders$searchIndexDecoder),
				$elm$json$Json$Decode$succeed($author$project$Models$Search$SearchResult)))));
var $author$project$App$Search$Commands$search = F2(
	function (_v0, term) {
		var token = _v0.h0;
		var userId = _v0.h6;
		var languageId = _v0.gF;
		var url = $author$project$Api$urlPrefix + ('/user/' + (userId + ('/language/' + ($elm$core$String$fromInt(languageId) + '/search'))));
		var query = '?searchTerm=' + term;
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$emptyBody,
				a4: A2($elm$http$Http$expectJson, $author$project$App$Search$Messages$GotSearchResult, $author$project$Decoders$searchResultDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'POST',
				bw: $elm$core$Maybe$Just($author$project$Api$timeout),
				by: $elm$core$Maybe$Nothing,
				bB: _Utils_ap(url, query)
			});
	});
var $author$project$App$Search$SearchBar$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var searchTerm = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cP: searchTerm}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				var trimmedTerm = $elm$core$String$trim(model.cP);
				return ($elm$core$String$length(trimmedTerm) > 0) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{
							cc: $elm$core$Maybe$Just(model.cP),
							eM: $author$project$App$Search$SearchBar$Model$LoadingSearch
						}),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (a) {
							return A2($author$project$App$Search$Commands$search, a, trimmedTerm);
						},
						model.de)) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cP: ''}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$App$Search$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var wiktionaryMsg = msg.a;
				var _v1 = A2($author$project$App$Wiktionary$Update$update, wiktionaryMsg, model.e3);
				var wiktionaryModel = _v1.a;
				var wiktionaryCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e3: wiktionaryModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Search$Messages$WiktionaryMsg, wiktionaryCmd));
			case 1:
				var searchBarMsg = msg.a;
				var _v2 = A2($author$project$App$Search$SearchBar$Update$update, searchBarMsg, model.eB);
				var searchBarModel = _v2.a;
				var searchBarCmd = _v2.b;
				var parentCmd = _v2.c;
				var cmds = _List_fromArray(
					[
						function () {
						if (!parentCmd.$) {
							var cmd = parentCmd.a;
							return cmd;
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}(),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Search$Messages$SearchBarMsg, searchBarCmd)
					]);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eB: searchBarModel}),
					$elm$core$Platform$Cmd$batch(cmds));
			case 2:
				var resultType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e9: $elm$core$Maybe$Just(resultType)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var result = msg.a.a;
					var getWikt = function (a) {
						return $elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$map,
								$author$project$App$Search$Commands$getWiktRec(a),
								A2(
									$elm$core$List$take,
									20,
									$elm$core$List$sort(result.e2))));
					};
					return _Utils_Tuple2(
						A2($author$project$App$Search$Update$aux, model, result),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2($elm$core$Maybe$map, getWikt, model.de)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Models$Local$Data = F2(
	function (userId, token) {
		return {h0: token, h6: userId};
	});
var $author$project$App$Messages$GotAccessData = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$User$Messages$LogInMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$User$Model$LoggedIn = {$: 0};
var $author$project$App$User$Update$doLoad = _Platform_outgoingPort(
	'doLoad',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$User$Messages$GotUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$User$UserAndLangId = F2(
	function (user, languageId) {
		return {gF: languageId, eX: user};
	});
var $author$project$Models$User$User = F4(
	function (id, email, firstName, lastName) {
		return {dA: email, gd: firstName, gt: id, gH: lastName};
	});
var $author$project$Decoders$userDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastName',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'email',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Models$User$User)))));
var $author$project$Decoders$userAndLangIdDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'languageId',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'user',
		$author$project$Decoders$userDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$User$UserAndLangId)));
var $author$project$App$User$Commands$getUser = F2(
	function (userId, token) {
		var url = $author$project$Api$urlPrefix + ('/user/' + userId);
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				aZ: $elm$http$Http$emptyBody,
				a4: A2($elm$http$Http$expectJson, $author$project$App$User$Messages$GotUser, $author$project$Decoders$userAndLangIdDecoder),
				a8: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				bg: 'GET',
				bw: $elm$core$Maybe$Nothing,
				by: $elm$core$Maybe$Nothing,
				bB: url
			});
	});
var $author$project$Decoders$localDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'userId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Models$Local$Data)));
var $author$project$Encoders$localDataEncoder = function (_v0) {
	var token = _v0.h0;
	var userId = _v0.h6;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'userId',
				$elm$json$Json$Encode$string(userId)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(token))
			]));
};
var $author$project$App$User$Update$makeAccessData = function (_v0) {
	var token = _v0.h0;
	var userId = _v0.h6;
	var languageId = _v0.gF;
	var _v1 = _Utils_Tuple3(token, userId, languageId);
	if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
		var t = _v1.a.a;
		var uid = _v1.b.a;
		var lid = _v1.c.a;
		return $elm$core$Maybe$Just(
			{gF: lid, h0: t, h6: uid});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$User$Update$save = _Platform_outgoingPort('save', $elm$json$Json$Encode$string);
var $author$project$Models$User$PasscodePayload = function (value) {
	return {h8: value};
};
var $author$project$App$User$Messages$Save = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$User$LogIn$Model$SendPasscode = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$User$LogIn$Model$ValidatePasscode = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$User$LogIn$Messages$GotUserId = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{aZ: $elm$http$Http$emptyBody, a4: r.a4, a8: _List_Nil, bg: 'GET', bw: $elm$core$Maybe$Nothing, by: $elm$core$Maybe$Nothing, bB: r.bB});
};
var $author$project$Decoders$userIdDecoder = $elm$json$Json$Decode$string;
var $author$project$App$User$LogIn$Commands$getUserId = function (email) {
	var url = $author$project$Api$urlPrefix + '/user/id';
	var query = '?email=' + email;
	return $elm$http$Http$get(
		{
			a4: A2(
				$elm$http$Http$expectJson,
				$author$project$App$User$LogIn$Messages$GotUserId(email),
				$author$project$Decoders$userIdDecoder),
			bB: _Utils_ap(url, query)
		});
};
var $author$project$App$User$LogIn$Messages$PostedPasscodeReq = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{aZ: r.aZ, a4: r.a4, a8: _List_Nil, bg: 'POST', bw: $elm$core$Maybe$Nothing, by: $elm$core$Maybe$Nothing, bB: r.bB});
};
var $author$project$App$User$LogIn$Commands$postPasscodeReq = function (uid) {
	var url = $author$project$Api$urlPrefix + ('/user/' + (uid + '/login_passcode'));
	return $elm$http$Http$post(
		{
			aZ: $elm$http$Http$emptyBody,
			a4: $elm$http$Http$expectWhatever(
				$author$project$App$User$LogIn$Messages$PostedPasscodeReq(uid)),
			bB: url
		});
};
var $author$project$App$User$LogIn$Messages$PostedUser = function (a) {
	return {$: 2, a: a};
};
var $author$project$Encoders$userPayloadEncoder = function (_v0) {
	var email = _v0.dA;
	var firstName = _v0.gd;
	var lastName = _v0.gH;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(email)),
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(firstName)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$string(lastName))
			]));
};
var $author$project$App$User$LogIn$Commands$postUser = function (payload) {
	var url = $author$project$Api$urlPrefix + '/user';
	return $elm$http$Http$post(
		{
			aZ: $elm$http$Http$jsonBody(
				$author$project$Encoders$userPayloadEncoder(payload)),
			a4: A2($elm$http$Http$expectJson, $author$project$App$User$LogIn$Messages$PostedUser, $author$project$Decoders$userAndLangIdDecoder),
			bB: url
		});
};
var $author$project$App$User$LogIn$Messages$ValidatedPasscode = function (a) {
	return {$: 5, a: a};
};
var $author$project$Encoders$passcodePayloadEncoder = function (_v0) {
	var value = _v0.h8;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(value))
			]));
};
var $author$project$Models$User$UserToken = F2(
	function (user, token) {
		return {h0: token, eX: user};
	});
var $author$project$Decoders$userTokenDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'user',
		$author$project$Decoders$userDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$User$UserToken)));
var $author$project$App$User$LogIn$Commands$validatePasscode = F2(
	function (uid, payload) {
		var url = $author$project$Api$urlPrefix + ('/user/' + (uid + '/token'));
		return $elm$http$Http$post(
			{
				aZ: $elm$http$Http$jsonBody(
					$author$project$Encoders$passcodePayloadEncoder(payload)),
				a4: A2($elm$http$Http$expectJson, $author$project$App$User$LogIn$Messages$ValidatedPasscode, $author$project$Decoders$userTokenDecoder),
				bB: url
			});
	});
var $author$project$App$User$LogIn$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				switch (msg.a) {
					case 0:
						var _v1 = msg.a;
						var val = msg.b;
						var _v2 = model.dM;
						switch (_v2.$) {
							case 0:
								var f = _v2.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											dM: $author$project$App$User$LogIn$Model$SignUp(
												_Utils_update(
													f,
													{dA: val}))
										}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							case 1:
								var f = _v2.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											dM: $author$project$App$User$LogIn$Model$GetUserId(
												_Utils_update(
													f,
													{dA: val}))
										}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							default:
								return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						}
					case 1:
						var _v3 = msg.a;
						var val = msg.b;
						var _v4 = model.dM;
						if (_v4.$ === 3) {
							var f = _v4.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										dM: $author$project$App$User$LogIn$Model$ValidatePasscode(
											_Utils_update(
												f,
												{cz: val}))
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						}
					case 2:
						var _v5 = msg.a;
						var val = msg.b;
						var _v6 = model.dM;
						if (!_v6.$) {
							var f = _v6.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										dM: $author$project$App$User$LogIn$Model$SignUp(
											_Utils_update(
												f,
												{gd: val}))
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						}
					default:
						var _v7 = msg.a;
						var val = msg.b;
						var _v8 = model.dM;
						if (!_v8.$) {
							var f = _v8.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										dM: $author$project$App$User$LogIn$Model$SignUp(
											_Utils_update(
												f,
												{gH: val}))
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						}
				}
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eU: true}),
					function () {
						var _v9 = model.dM;
						switch (_v9.$) {
							case 0:
								var f = _v9.a;
								return $author$project$App$User$LogIn$Commands$postUser(f);
							case 1:
								var email = _v9.a.dA;
								return $author$project$App$User$LogIn$Commands$getUserId(email);
							case 3:
								var userId = _v9.a.h6;
								var passcode = _v9.a.cz;
								return A2(
									$author$project$App$User$LogIn$Commands$validatePasscode,
									userId,
									$author$project$Models$User$PasscodePayload(passcode));
							default:
								return $elm$core$Platform$Cmd$none;
						}
					}(),
					$elm$core$Maybe$Nothing);
			case 2:
				if (!msg.a.$) {
					var user = msg.a.a.eX;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dM: $author$project$App$User$LogIn$Model$SendPasscode(
									{dA: user.dA, h6: user.gt})
							}),
						$author$project$App$User$LogIn$Commands$postPasscodeReq(user.gt),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{eU: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 3:
				if (!msg.b.$) {
					var email = msg.a;
					var uid = msg.b.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dM: $author$project$App$User$LogIn$Model$SendPasscode(
									{dA: email, h6: uid})
							}),
						$author$project$App$User$LogIn$Commands$postPasscodeReq(uid),
						$elm$core$Maybe$Nothing);
				} else {
					var email = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dM: $author$project$App$User$LogIn$Model$SignUp(
									{dA: email, gd: '', gH: ''}),
								eU: false
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 4:
				if (!msg.b.$) {
					var uid = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dM: $author$project$App$User$LogIn$Model$ValidatePasscode(
									{cz: '', h6: uid}),
								eU: false
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{eU: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			default:
				if (!msg.a.$) {
					var userToken = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								eU: false,
								eY: $elm$core$Maybe$Just(userToken)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$App$User$Messages$Save(userToken)));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								eM: $elm$core$Maybe$Just(e),
								eU: false
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
		}
	});
var $author$project$App$User$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var logInMsg = msg.a;
				var _v1 = A2($author$project$App$User$LogIn$Update$update, logInMsg, model.d5);
				var logInModel = _v1.a;
				var logInCmd = _v1.b;
				var userMsg = _v1.c;
				var model1 = _Utils_update(
					model,
					{d5: logInModel});
				var update1 = function (pMsg) {
					return A2($author$project$App$User$Update$update, pMsg, model1);
				};
				var _v2 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple3(model1, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing),
					A2($elm$core$Maybe$map, update1, userMsg));
				var model2 = _v2.a;
				var userCmd = _v2.b;
				var cmds = _List_fromArray(
					[
						userCmd,
						A2($elm$core$Platform$Cmd$map, $author$project$App$User$Messages$LogInMsg, logInCmd)
					]);
				return _Utils_Tuple3(
					model2,
					$elm$core$Platform$Cmd$batch(cmds),
					$elm$core$Maybe$Nothing);
			case 2:
				var user = msg.a.eX;
				var token = msg.a.h0;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							d5: $author$project$App$User$LogIn$Model$init(false),
							eM: $author$project$App$User$Model$LoggedIn,
							h0: $elm$core$Maybe$Just(token),
							eX: $elm$core$Maybe$Just(user),
							h6: $elm$core$Maybe$Just(user.gt)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$User$Update$save(
								A2(
									$elm$json$Json$Encode$encode,
									0,
									$author$project$Encoders$localDataEncoder(
										A2($author$project$Models$Local$Data, user.gt, token)))),
								A2($author$project$App$User$Commands$getUser, user.gt, token)
							])),
					$elm$core$Maybe$Nothing);
			case 4:
				return _Utils_Tuple3(
					model,
					$author$project$App$User$Update$doLoad(0),
					$elm$core$Maybe$Nothing);
			case 3:
				var value = msg.a;
				var _v3 = A2($elm$json$Json$Decode$decodeString, $author$project$Decoders$localDataDecoder, value);
				if (!_v3.$) {
					var userId = _v3.a.h6;
					var token = _v3.a.h0;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								h0: $elm$core$Maybe$Just(token),
								h6: $elm$core$Maybe$Just(userId)
							}),
						A2($author$project$App$User$Commands$getUser, userId, token),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 1:
				if (!msg.a.$) {
					var user = msg.a.a.eX;
					var languageId = msg.a.a.gF;
					var model1 = _Utils_update(
						model,
						{
							gF: $elm$core$Maybe$Just(languageId),
							eM: $author$project$App$User$Model$LoggedIn,
							eX: $elm$core$Maybe$Just(user),
							h6: $elm$core$Maybe$Just(user.gt)
						});
					return _Utils_Tuple3(
						model1,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$App$Messages$GotAccessData,
							$author$project$App$User$Update$makeAccessData(model1)));
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple3($author$project$App$User$Model$init, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$App$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var appBarMsg = msg.a;
				var _v1 = A2($author$project$App$AppBar$Update$update, appBarMsg, model.di);
				var appBarModel = _v1.a;
				var appBarCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{di: appBarModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$AppBarMsg, appBarCmd));
			case 2:
				var effortMsg = msg.a;
				var _v2 = A2($author$project$App$Effort$Update$update, effortMsg, model.dy);
				var effortModel = _v2.a;
				var effortCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dy: effortModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$EffortMsg, effortCmd));
			case 3:
				var entitiesMsg = msg.a;
				var _v3 = A2($author$project$App$Entities$Update$update, entitiesMsg, model.dC);
				var entitiesModel = _v3.a;
				var entitiesCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dC: entitiesModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$EntitiesMsg, entitiesCmd));
			case 4:
				var metricsMsg = msg.a;
				var _v4 = A2($author$project$App$Metrics$Update$update, metricsMsg, model.d7);
				var metricsModel = _v4.a;
				var metricsCmd = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d7: metricsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$MetricsMsg, metricsCmd));
			case 5:
				var searchMsg = msg.a;
				var _v5 = A2($author$project$App$Search$Update$update, searchMsg, model.eA);
				var searchModel = _v5.a;
				var searchCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eA: searchModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$SearchMsg, searchCmd));
			case 6:
				var userMsg = msg.a;
				var _v6 = A2($author$project$App$User$Update$update, userMsg, model.eX);
				var userModel = _v6.a;
				var userCmd = _v6.b;
				var appMsg = _v6.c;
				var model1 = _Utils_update(
					model,
					{eX: userModel});
				var update1 = function (pMsg) {
					return A2($author$project$App$Update$update, pMsg, model1);
				};
				var _v7 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model1, $elm$core$Platform$Cmd$none),
					A2($elm$core$Maybe$map, update1, appMsg));
				var model2 = _v7.a;
				var appCmd = _v7.b;
				var cmds = _List_fromArray(
					[
						appCmd,
						A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$UserMsg, userCmd)
					]);
				return _Utils_Tuple2(
					model2,
					$elm$core$Platform$Cmd$batch(cmds));
			case 7:
				var access = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							de: $elm$core$Maybe$Just(access),
							dy: $author$project$App$Effort$Model$init(
								$elm$core$Maybe$Just(access)),
							eA: $author$project$App$Search$Model$init(
								$elm$core$Maybe$Just(access))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$Commands$getPreEfforts(access),
								$author$project$App$Commands$getEntities(access)
							])));
			case 8:
				if (!msg.a.$) {
					var ids = msg.a.a;
					var getWikt = function (a) {
						return $elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$map,
								$author$project$App$Commands$getWiktRec(a),
								A2($elm$core$List$take, 10, ids)));
					};
					var entities = model.dC;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dC: A2($author$project$App$Entities$Model$init, model.de, ids)
							}),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2($elm$core$Maybe$map, getWikt, model.de)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					var counts = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ds: $elm$core$Maybe$Just(counts)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Update$init = function () {
	var msg = $author$project$App$Messages$UserMsg($author$project$App$User$Messages$DoLoad);
	var model = $author$project$App$Model$init;
	return A2($author$project$App$Update$update, msg, model);
}();
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$App$User$Messages$Load = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$User$Update$load = _Platform_incomingPort('load', $elm$json$Json$Decode$string);
var $author$project$App$User$Subscriptions$subscriptions = function (model) {
	return $author$project$App$User$Update$load($author$project$App$User$Messages$Load);
};
var $author$project$App$Subscriptions$subscriptions = function (_v0) {
	var user = _v0.eX;
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$App$Messages$UserMsg,
		$author$project$App$User$Subscriptions$subscriptions(user));
};
var $author$project$App$User$Model$isLoggedIn = function (_v0) {
	var status = _v0.eM;
	return _Utils_eq(status, $author$project$App$User$Model$LoggedIn);
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $aforemny$material_components_web_elm$Material$LayoutGrid$cell = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid__cell'),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$LayoutGrid$inner = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid__inner'),
				attributes),
			nodes);
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid = F2(
	function (attributes, nodes) {
		return A3(
			$elm$html$Html$node,
			'mdc-layout-grid',
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid'),
				A2(
					$elm$core$List$cons,
					A2($elm$html$Html$Attributes$style, 'display', 'block'),
					attributes)),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$LayoutGrid$span = function (n) {
	return $elm$html$Html$Attributes$class(
		'mdc-layout-grid__cell--span-' + $elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$LayoutGrid$span12 = $aforemny$material_components_web_elm$Material$LayoutGrid$span(12);
var $aforemny$material_components_web_elm$Material$Card$Block = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$block = $elm$core$Basics$identity;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $aforemny$material_components_web_elm$Material$Card$actionsElt = function (content) {
	var _v0 = content.e8;
	if (!_v0.$) {
		var buttons = _v0.a.fs;
		var icons = _v0.a.gs;
		var fullBleed = _v0.a.b0;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class('mdc-card__actions')),
							fullBleed ? $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class('mdc-card__actions--full-bleed')) : $elm$core$Maybe$Nothing
						])),
				$elm$core$List$concat(
					_List_fromArray(
						[
							(!$elm$core$List$isEmpty(buttons)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-card__action-buttons')
									]),
								A2(
									$elm$core$List$map,
									function (_v1) {
										var button_ = _v1;
										return button_;
									},
									buttons))
							]) : _List_Nil,
							(!$elm$core$List$isEmpty(icons)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-card__action-icons')
									]),
								A2(
									$elm$core$List$map,
									function (_v2) {
										var icon_ = _v2;
										return icon_;
									},
									icons))
							]) : _List_Nil
						])))
			]);
	} else {
		return _List_Nil;
	}
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $aforemny$material_components_web_elm$Material$Card$clickHandler = function (_v0) {
	var onClick = _v0.cs;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $aforemny$material_components_web_elm$Material$Card$hrefAttr = function (_v0) {
	var href = _v0.aM;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$Card$primaryActionCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-card__primary-action'));
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $aforemny$material_components_web_elm$Material$Card$tabIndexProp = function (tabIndex) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(tabIndex)));
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $aforemny$material_components_web_elm$Material$Card$targetAttr = function (_v0) {
	var target = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target);
};
var $aforemny$material_components_web_elm$Material$Card$primaryAction = F2(
	function (config_, blocks) {
		var href = config_.aM;
		return _List_fromArray(
			[
				A2(
				(!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? $elm$html$Html$a : $elm$html$Html$div,
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Card$primaryActionCs,
							$aforemny$material_components_web_elm$Material$Card$tabIndexProp(0),
							$aforemny$material_components_web_elm$Material$Card$clickHandler(config_),
							$aforemny$material_components_web_elm$Material$Card$hrefAttr(config_),
							$aforemny$material_components_web_elm$Material$Card$targetAttr(config_)
						])),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var html = _v0;
						return html;
					},
					blocks))
			]);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $aforemny$material_components_web_elm$Material$Card$blocksElt = F2(
	function (config_, _v0) {
		var onClick = config_.cs;
		var href = config_.aM;
		var blocks = _v0.fn;
		return A2(
			$elm$core$List$map,
			function (_v1) {
				var html = _v1;
				return html;
			},
			(((!_Utils_eq(onClick, $elm$core$Maybe$Nothing)) || (!_Utils_eq(href, $elm$core$Maybe$Nothing))) ? $aforemny$material_components_web_elm$Material$Card$primaryAction(config_) : $elm$core$Basics$identity)(
				A2($elm$core$List$cons, blocks.a, blocks.b)));
	});
var $aforemny$material_components_web_elm$Material$Card$outlinedCs = function (_v0) {
	var outlined = _v0.cx;
	return outlined ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-card--outlined')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Card$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-card'));
var $aforemny$material_components_web_elm$Material$Card$card = F2(
	function (config_, content) {
		var additionalAttributes = config_.bF;
		return A3(
			$elm$html$Html$node,
			'mdc-card',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Card$rootCs,
							$aforemny$material_components_web_elm$Material$Card$outlinedCs(config_)
						])),
				additionalAttributes),
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2($aforemny$material_components_web_elm$Material$Card$blocksElt, config_, content),
						$aforemny$material_components_web_elm$Material$Card$actionsElt(content)
					])));
	});
var $aforemny$material_components_web_elm$Material$Card$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$config = {bF: _List_Nil, aM: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, cx: false, c2: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$Card$Actions = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$fullBleedActions = function (button_) {
	return {
		fs: _List_fromArray(
			[button_]),
		b0: true,
		gs: _List_Nil
	};
};
var $aforemny$material_components_web_elm$Material$Card$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bF: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Card$setOutlined = F2(
	function (outlined, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{cx: outlined});
	});
var $author$project$App$User$LogIn$Messages$Submitted = {$: 1};
var $aforemny$material_components_web_elm$Material$Card$Button = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Button$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Button$Text = 0;
var $elm$html$Html$button = _VirtualDom_node('button');
var $aforemny$material_components_web_elm$Material$Button$clickHandler = function (_v0) {
	var onClick = _v0.cs;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$Button$denseCs = function (_v0) {
	var dense = _v0.bQ;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-button--dense')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $aforemny$material_components_web_elm$Material$Button$disabledAttr = function (_v0) {
	var disabled = _v0.aK;
	return $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$disabled(disabled));
};
var $aforemny$material_components_web_elm$Material$Button$hrefAttr = function (_v0) {
	var href = _v0.aM;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $aforemny$material_components_web_elm$Material$Button$labelElt = function (label) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-button__label')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				])));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $aforemny$material_components_web_elm$Material$Button$iconElt = function (_v0) {
	var config_ = _v0;
	return A2(
		$elm$core$Maybe$map,
		$elm$html$Html$map($elm$core$Basics$never),
		function () {
			var _v1 = config_.gr;
			if (!_v1.$) {
				if (!_v1.a.$) {
					var node = _v1.a.a.bj;
					var attributes = _v1.a.a.aY;
					var nodes = _v1.a.a.bk;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$class('mdc-button__icon'),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									attributes)),
							nodes));
				} else {
					var node = _v1.a.a.bj;
					var attributes = _v1.a.a.aY;
					var nodes = _v1.a.a.bk;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('mdc-button__icon'),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									attributes)),
							nodes));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}());
};
var $aforemny$material_components_web_elm$Material$Button$leadingIconElt = function (config_) {
	var trailingIcon = config_.bz;
	return (!trailingIcon) ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Menu$closeHandler = function (_v0) {
	var onClose = _v0.bn;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCMenuSurface:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $aforemny$material_components_web_elm$Material$List$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$config = {bF: _List_Nil, bI: false, bQ: false, bb: true, bq: true, c6: false, ic: false, dc: false};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$avatarListCs = function (_v0) {
	var avatarList = _v0.bI;
	return avatarList ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--avatar-list')) : $elm$core$Maybe$Nothing;
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $aforemny$material_components_web_elm$Material$List$clickHandler = function (listItems) {
	var getOnClick = function (listItem_) {
		switch (listItem_.$) {
			case 0:
				var onClick = listItem_.b.cs;
				return $elm$core$Maybe$Just(onClick);
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var nthOnClick = function (index) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$Basics$identity,
			$elm$core$List$head(
				A2(
					$elm$core$List$drop,
					index,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						A2($elm$core$List$map, getOnClick, listItems)))));
	};
	var mergedClickHandler = A2(
		$elm$json$Json$Decode$andThen,
		function (index) {
			var _v0 = nthOnClick(index);
			if (!_v0.$) {
				var msg_ = _v0.a;
				return $elm$json$Json$Decode$succeed(msg_);
			} else {
				return $elm$json$Json$Decode$fail('');
			}
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['detail', 'index']),
			$elm$json$Json$Decode$int));
	return $elm$core$Maybe$Just(
		A2($elm$html$Html$Events$on, 'MDCList:action', mergedClickHandler));
};
var $aforemny$material_components_web_elm$Material$List$denseCs = function (_v0) {
	var dense = _v0.bQ;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--dense')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$interactiveProp = function (_v0) {
	var interactive = _v0.bb;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'interactive',
			$elm$json$Json$Encode$bool(interactive)));
};
var $aforemny$material_components_web_elm$Material$List$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-deprecated-list'));
var $aforemny$material_components_web_elm$Material$List$selectedIndexProp = function (listItems) {
	var selectedIndex = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, listItem_) {
					switch (listItem_.$) {
						case 0:
							var selection = listItem_.b.hy;
							return (!_Utils_eq(selection, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
						case 1:
							return $elm$core$Maybe$Nothing;
						default:
							return $elm$core$Maybe$Nothing;
					}
				}),
			A2(
				$elm$core$List$filter,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							return true;
						case 1:
							return false;
						default:
							return false;
					}
				},
				listItems)));
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'selectedIndex',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, selectedIndex)));
};
var $aforemny$material_components_web_elm$Material$List$twoLineCs = function (_v0) {
	var twoLine = _v0.c6;
	return twoLine ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--two-line')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$wrapFocusProp = function (_v0) {
	var wrapFocus = _v0.dc;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'wrapFocus',
			$elm$json$Json$Encode$bool(wrapFocus)));
};
var $aforemny$material_components_web_elm$Material$List$list = F3(
	function (config_, firstListItem, remainingListItems) {
		var ripples = config_.bq;
		var interactive = config_.bb;
		var additionalAttributes = config_.bF;
		var listItems = A2($elm$core$List$cons, firstListItem, remainingListItems);
		return A3(
			$elm$html$Html$node,
			'mdc-list',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$rootCs,
							$aforemny$material_components_web_elm$Material$List$denseCs(config_),
							$aforemny$material_components_web_elm$Material$List$avatarListCs(config_),
							$aforemny$material_components_web_elm$Material$List$twoLineCs(config_),
							$aforemny$material_components_web_elm$Material$List$wrapFocusProp(config_),
							$aforemny$material_components_web_elm$Material$List$clickHandler(listItems),
							$aforemny$material_components_web_elm$Material$List$selectedIndexProp(listItems),
							$aforemny$material_components_web_elm$Material$List$interactiveProp(config_)
						])),
				additionalAttributes),
			A2(
				$elm$core$List$map,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							var node = listItem_.a;
							var config__ = listItem_.b;
							return node(
								_Utils_update(
									config__,
									{bq: ripples && interactive}));
						case 1:
							var node = listItem_.a;
							return node;
						default:
							var node = listItem_.a;
							return node;
					}
				},
				listItems));
	});
var $aforemny$material_components_web_elm$Material$Menu$openProp = function (_v0) {
	var open = _v0.cv;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Menu$quickOpenProp = function (_v0) {
	var quickOpen = _v0.cI;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'quickOpen',
			$elm$json$Json$Encode$bool(quickOpen)));
};
var $aforemny$material_components_web_elm$Material$Menu$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-menu mdc-menu-surface'));
var $aforemny$material_components_web_elm$Material$List$setRipples = F2(
	function (ripples, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bq: ripples});
	});
var $aforemny$material_components_web_elm$Material$List$setWrapFocus = F2(
	function (wrapFocus, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dc: wrapFocus});
	});
var $aforemny$material_components_web_elm$Material$Menu$menu = F3(
	function (config_, firstListItem, remainingListItems) {
		var additionalAttributes = config_.bF;
		return A3(
			$elm$html$Html$node,
			'mdc-menu',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Menu$rootCs,
							$aforemny$material_components_web_elm$Material$Menu$openProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$quickOpenProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$closeHandler(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A3(
					$aforemny$material_components_web_elm$Material$List$list,
					A2(
						$aforemny$material_components_web_elm$Material$List$setWrapFocus,
						true,
						A2($aforemny$material_components_web_elm$Material$List$setRipples, false, $aforemny$material_components_web_elm$Material$List$config)),
					firstListItem,
					remainingListItems)
				]));
	});
var $aforemny$material_components_web_elm$Material$Button$rippleElt = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-button__ripple')
			]),
		_List_Nil));
var $aforemny$material_components_web_elm$Material$Button$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-button'));
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $aforemny$material_components_web_elm$Material$Button$tabIndexProp = function (_v0) {
	var disabled = _v0.aK;
	return disabled ? $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(-1))) : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(0)));
};
var $aforemny$material_components_web_elm$Material$Button$targetAttr = function (_v0) {
	var href = _v0.aM;
	var target = _v0.c2;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$touchCs = function (_v0) {
	var touch = _v0.aS;
	return touch ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-button--touch')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$touchElt = function (_v0) {
	var touch = _v0.aS;
	return touch ? $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-button__touch')
				]),
			_List_Nil)) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$trailingIconElt = function (config_) {
	var trailingIcon = config_.bz;
	return trailingIcon ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$variantCs = function (variant) {
	switch (variant) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--raised'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--unelevated'));
		default:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--outlined'));
	}
};
var $aforemny$material_components_web_elm$Material$Button$button = F3(
	function (variant, config_, label) {
		var innerConfig = config_;
		var additionalAttributes = innerConfig.bF;
		var touch = innerConfig.aS;
		var href = innerConfig.aM;
		var disabled = innerConfig.aK;
		var wrapTouch = function (node) {
			return touch ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-touch-target-wrapper')
					]),
				_List_fromArray(
					[node])) : node;
		};
		var wrapMenu = function (node) {
			var _v0 = innerConfig.ce;
			if (_v0.$ === 1) {
				return node;
			} else {
				var _v1 = _v0.a;
				var menuConfig = _v1.a;
				var firstListItem = _v1.b;
				var remainingListItems = _v1.c;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-menu-surface--anchor')
						]),
					_List_fromArray(
						[
							node,
							A3($aforemny$material_components_web_elm$Material$Menu$menu, menuConfig, firstListItem, remainingListItems)
						]));
			}
		};
		return wrapMenu(
			wrapTouch(
				A3(
					$elm$html$Html$node,
					'mdc-button',
					_List_Nil,
					_List_fromArray(
						[
							A2(
							((!_Utils_eq(href, $elm$core$Maybe$Nothing)) && (!disabled)) ? $elm$html$Html$a : $elm$html$Html$button,
							_Utils_ap(
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											$aforemny$material_components_web_elm$Material$Button$rootCs,
											$aforemny$material_components_web_elm$Material$Button$variantCs(variant),
											$aforemny$material_components_web_elm$Material$Button$denseCs(config_),
											$aforemny$material_components_web_elm$Material$Button$touchCs(config_),
											$aforemny$material_components_web_elm$Material$Button$disabledAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$tabIndexProp(config_),
											$aforemny$material_components_web_elm$Material$Button$hrefAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$targetAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$clickHandler(config_)
										])),
								additionalAttributes),
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$Button$rippleElt,
										$aforemny$material_components_web_elm$Material$Button$leadingIconElt(config_),
										$aforemny$material_components_web_elm$Material$Button$labelElt(label),
										$aforemny$material_components_web_elm$Material$Button$trailingIconElt(config_),
										$aforemny$material_components_web_elm$Material$Button$touchElt(config_)
									])))
						]))));
	});
var $aforemny$material_components_web_elm$Material$Button$text = F2(
	function (config_, label) {
		return A3($aforemny$material_components_web_elm$Material$Button$button, 0, config_, label);
	});
var $aforemny$material_components_web_elm$Material$Card$button = F2(
	function (_v0, label) {
		var buttonConfig = _v0;
		return A2(
			$aforemny$material_components_web_elm$Material$Button$text,
			_Utils_update(
				buttonConfig,
				{
					bF: A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-card__action'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class('mdc-card__action--button'),
							buttonConfig.bF))
				}),
			label);
	});
var $aforemny$material_components_web_elm$Material$Button$config = {bF: _List_Nil, bQ: false, aK: false, aM: $elm$core$Maybe$Nothing, gr: $elm$core$Maybe$Nothing, ce: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing, aS: true, bz: false};
var $aforemny$material_components_web_elm$Material$Button$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{aK: disabled});
	});
var $aforemny$material_components_web_elm$Material$Button$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				cs: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$App$User$LogIn$View$viewButton = function (_v0) {
	var form = _v0.dM;
	var toSubmit = _v0.eU;
	var config = A2(
		$aforemny$material_components_web_elm$Material$Button$setDisabled,
		toSubmit,
		A2($aforemny$material_components_web_elm$Material$Button$setOnClick, $author$project$App$User$LogIn$Messages$Submitted, $aforemny$material_components_web_elm$Material$Button$config));
	return A2($aforemny$material_components_web_elm$Material$Card$button, config, 'Next');
};
var $author$project$App$User$LogIn$Messages$Email = 0;
var $author$project$App$User$LogIn$Messages$FirstName = 2;
var $author$project$App$User$LogIn$Messages$LastName = 3;
var $author$project$App$User$LogIn$Messages$Passcode = 1;
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$App$User$LogIn$Messages$UpdateField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$TextField$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TextField$config = {bF: _List_Nil, aK: false, bV: false, a7: false, gE: $elm$core$Maybe$Nothing, d4: $elm$core$Maybe$Nothing, ao: $elm$core$Maybe$Nothing, bf: $elm$core$Maybe$Nothing, aq: $elm$core$Maybe$Nothing, bh: $elm$core$Maybe$Nothing, cq: $elm$core$Maybe$Nothing, cr: $elm$core$Maybe$Nothing, cu: $elm$core$Maybe$Nothing, cA: $elm$core$Maybe$Nothing, bo: $elm$core$Maybe$Nothing, cF: $elm$core$Maybe$Nothing, cK: false, aQ: $elm$core$Maybe$Nothing, c$: $elm$core$Maybe$Nothing, bz: $elm$core$Maybe$Nothing, c7: $elm$core$Maybe$Nothing, c8: true, h8: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$TextField$disabledCs = function (_v0) {
	var disabled = _v0.aK;
	return disabled ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--disabled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$disabledProp = function (_v0) {
	var disabled = _v0.aK;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $aforemny$material_components_web_elm$Material$TextField$endAlignedCs = function (_v0) {
	var endAligned = _v0.bV;
	return endAligned ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--end-aligned')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$filledCs = function (outlined_) {
	return (!outlined_) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--filled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$foucClassNamesProp = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$Attributes$property,
		'foucClassNames',
		A2(
			$elm$json$Json$Encode$list,
			$elm$json$Json$Encode$string,
			_List_fromArray(
				['mdc-text-field--label-floating']))));
var $aforemny$material_components_web_elm$Material$TextField$fullwidthCs = function (_v0) {
	var fullwidth = _v0.a7;
	return fullwidth ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--fullwidth')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$ariaLabelAttr = function (_v0) {
	var fullwidth = _v0.a7;
	var label = _v0.gE;
	return fullwidth ? A2(
		$elm$core$Maybe$map,
		$elm$html$Html$Attributes$attribute('aria-label'),
		label) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $aforemny$material_components_web_elm$Material$TextField$blurHandler = function (_v0) {
	var onBlur = _v0.cq;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, onBlur);
};
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $aforemny$material_components_web_elm$Material$TextField$changeHandler = function (_v0) {
	var onChange = _v0.cr;
	return A2(
		$elm$core$Maybe$map,
		function (f) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, f, $elm$html$Html$Events$targetValue));
		},
		onChange);
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $aforemny$material_components_web_elm$Material$TextField$inputCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field__input'));
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $aforemny$material_components_web_elm$Material$TextField$inputHandler = function (_v0) {
	var onInput = _v0.cu;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, onInput);
};
var $aforemny$material_components_web_elm$Material$TextField$maxLengthAttr = function (_v0) {
	var maxLength = _v0.bf;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('maxLength'),
			$elm$core$String$fromInt),
		maxLength);
};
var $aforemny$material_components_web_elm$Material$TextField$minLengthAttr = function (_v0) {
	var minLength = _v0.bh;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('minLength'),
			$elm$core$String$fromInt),
		minLength);
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $aforemny$material_components_web_elm$Material$TextField$placeholderAttr = function (_v0) {
	var placeholder = _v0.bo;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, placeholder);
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $aforemny$material_components_web_elm$Material$TextField$typeAttr = function (_v0) {
	var type_ = _v0.c7;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$type_, type_);
};
var $aforemny$material_components_web_elm$Material$TextField$inputElt = function (config_) {
	return A2(
		$elm$html$Html$input,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$inputCs,
					$aforemny$material_components_web_elm$Material$TextField$typeAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$ariaLabelAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$placeholderAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$blurHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$changeHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$minLengthAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$maxLengthAttr(config_)
				])),
		_List_Nil);
};
var $aforemny$material_components_web_elm$Material$TextField$labelElt = function (_v0) {
	var label = _v0.gE;
	var value = _v0.h8;
	var fullwidth = _v0.a7;
	var floatingLabelFloatAboveCs = 'mdc-floating-label--float-above';
	var floatingLabelCs = 'mdc-floating-label';
	var _v1 = _Utils_Tuple2(fullwidth, label);
	if ((!_v1.a) && (!_v1.b.$)) {
		var str = _v1.b.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					(A2($elm$core$Maybe$withDefault, '', value) !== '') ? $elm$html$Html$Attributes$class(floatingLabelCs + (' ' + floatingLabelFloatAboveCs)) : $elm$html$Html$Attributes$class(floatingLabelCs),
					A2(
					$elm$html$Html$Attributes$property,
					'foucClassNames',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						_List_fromArray(
							[floatingLabelFloatAboveCs])))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$labelFloatingCs = function (_v0) {
	var label = _v0.gE;
	var value = _v0.h8;
	var fullwidth = _v0.a7;
	return ((!fullwidth) && ((!_Utils_eq(label, $elm$core$Maybe$Nothing)) && (A2($elm$core$Maybe$withDefault, '', value) !== ''))) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--label-floating')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$TextField$iconElt = F2(
	function (modifierCs, icon_) {
		if (icon_.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			if (!icon_.a.$) {
				var node = icon_.a.a.bj;
				var attributes = icon_.a.a.aY;
				var nodes = icon_.a.a.bk;
				var onInteraction = icon_.a.a.eg;
				var disabled = icon_.a.a.aK;
				return A2(
					node,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-text-field__icon'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(modifierCs),
							function () {
								if (!onInteraction.$) {
									var msg = onInteraction.a;
									return (!disabled) ? A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(0),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(msg),
												A2(
													$elm$core$List$cons,
													A2(
														$elm$html$Html$Events$on,
														'keydown',
														A2(
															$elm$json$Json$Decode$andThen,
															function (keyCode) {
																return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
															},
															$elm$html$Html$Events$keyCode)),
													attributes)))) : A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(-1),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											attributes));
								} else {
									return attributes;
								}
							}())),
					nodes);
			} else {
				var node = icon_.a.a.bj;
				var attributes = icon_.a.a.aY;
				var nodes = icon_.a.a.bk;
				var onInteraction = icon_.a.a.eg;
				var disabled = icon_.a.a.aK;
				return A2(
					node,
					A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class('mdc-text-field__icon'),
						A2(
							$elm$core$List$cons,
							$elm$svg$Svg$Attributes$class(modifierCs),
							function () {
								if (!onInteraction.$) {
									var msg = onInteraction.a;
									return (!disabled) ? A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(0),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(msg),
												A2(
													$elm$core$List$cons,
													A2(
														$elm$html$Html$Events$on,
														'keydown',
														A2(
															$elm$json$Json$Decode$andThen,
															function (keyCode) {
																return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
															},
															$elm$html$Html$Events$keyCode)),
													attributes)))) : A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(-1),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											attributes));
								} else {
									return attributes;
								}
							}())),
					nodes);
			}
		}
	});
var $aforemny$material_components_web_elm$Material$TextField$leadingIconElt = function (_v0) {
	var leadingIcon = _v0.d4;
	return A2($aforemny$material_components_web_elm$Material$TextField$iconElt, 'mdc-text-field__icon--leading', leadingIcon);
};
var $aforemny$material_components_web_elm$Material$TextField$lineRippleElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-line-ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$maxLengthProp = function (_v0) {
	var maxLength = _v0.bf;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'maxLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, maxLength))));
};
var $aforemny$material_components_web_elm$Material$TextField$maxProp = function (_v0) {
	var max = _v0.ao;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'max',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, max)))));
};
var $aforemny$material_components_web_elm$Material$TextField$minLengthProp = function (_v0) {
	var minLength = _v0.bh;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'minLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, minLength))));
};
var $aforemny$material_components_web_elm$Material$TextField$minProp = function (_v0) {
	var min = _v0.aq;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'min',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, min)))));
};
var $aforemny$material_components_web_elm$Material$TextField$noLabelCs = function (_v0) {
	var label = _v0.gE;
	return _Utils_eq(label, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--no-label')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineLeadingElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__leading')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineNotchElt = function (config_) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline__notch')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$TextField$labelElt(config_)
			]));
};
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineTrailingElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__trailing')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineElt = function (config_) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineLeadingElt,
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineNotchElt(config_),
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineTrailingElt
			]));
};
var $aforemny$material_components_web_elm$Material$TextField$outlinedCs = function (outlined_) {
	return outlined_ ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--outlined')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$patternProp = function (_v0) {
	var pattern = _v0.cA;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'pattern',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, pattern))));
};
var $aforemny$material_components_web_elm$Material$TextField$prefixCs = $elm$html$Html$Attributes$class('mdc-text-field__affix mdc-text-field__affix--prefix');
var $aforemny$material_components_web_elm$Material$TextField$prefixElt = function (_v0) {
	var prefix = _v0.cF;
	if (!prefix.$) {
		var prefixStr = prefix.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TextField$prefixCs]),
			_List_fromArray(
				[
					$elm$html$Html$text(prefixStr)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$requiredProp = function (_v0) {
	var required = _v0.cK;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'required',
			$elm$json$Json$Encode$bool(required)));
};
var $aforemny$material_components_web_elm$Material$TextField$rippleElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-text-field__ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field'));
var $aforemny$material_components_web_elm$Material$TextField$stepProp = function (_v0) {
	var step = _v0.aQ;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'step',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, step)))));
};
var $aforemny$material_components_web_elm$Material$TextField$suffixCs = $elm$html$Html$Attributes$class('mdc-text-field__affix mdc-text-field__affix--suffix');
var $aforemny$material_components_web_elm$Material$TextField$suffixElt = function (_v0) {
	var suffix = _v0.c$;
	if (!suffix.$) {
		var suffixStr = suffix.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TextField$suffixCs]),
			_List_fromArray(
				[
					$elm$html$Html$text(suffixStr)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$trailingIconElt = function (_v0) {
	var trailingIcon = _v0.bz;
	return A2($aforemny$material_components_web_elm$Material$TextField$iconElt, 'mdc-text-field__icon--trailing', trailingIcon);
};
var $aforemny$material_components_web_elm$Material$TextField$validProp = function (_v0) {
	var valid = _v0.c8;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'valid',
			$elm$json$Json$Encode$bool(valid)));
};
var $aforemny$material_components_web_elm$Material$TextField$valueProp = function (_v0) {
	var value = _v0.h8;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$property('value'),
			$elm$json$Json$Encode$string),
		value);
};
var $aforemny$material_components_web_elm$Material$TextField$withLeadingIconCs = function (_v0) {
	var leadingIcon = _v0.d4;
	return (!_Utils_eq(leadingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-leading-icon')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$withTrailingIconCs = function (_v0) {
	var trailingIcon = _v0.bz;
	return (!_Utils_eq(trailingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-trailing-icon')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$textField = F2(
	function (outlined_, config_) {
		var additionalAttributes = config_.bF;
		return A3(
			$elm$html$Html$node,
			'mdc-text-field',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TextField$rootCs,
							$aforemny$material_components_web_elm$Material$TextField$noLabelCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$filledCs(outlined_),
							$aforemny$material_components_web_elm$Material$TextField$outlinedCs(outlined_),
							$aforemny$material_components_web_elm$Material$TextField$fullwidthCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$labelFloatingCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$foucClassNamesProp,
							$aforemny$material_components_web_elm$Material$TextField$disabledCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$withLeadingIconCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$withTrailingIconCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$endAlignedCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$valueProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$disabledProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$requiredProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$validProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$patternProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$minLengthProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$maxLengthProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$minProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$maxProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$stepProp(config_)
						])),
				additionalAttributes),
			outlined_ ? _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$leadingIconElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$prefixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$suffixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$notchedOutlineElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$trailingIconElt(config_)
				]) : _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$rippleElt,
					$aforemny$material_components_web_elm$Material$TextField$leadingIconElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$prefixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$suffixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$labelElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$lineRippleElt,
					$aforemny$material_components_web_elm$Material$TextField$trailingIconElt(config_)
				]));
	});
var $aforemny$material_components_web_elm$Material$TextField$outlined = function (config_) {
	return A2($aforemny$material_components_web_elm$Material$TextField$textField, true, config_);
};
var $aforemny$material_components_web_elm$Material$TextField$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bF: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TextField$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{aK: disabled});
	});
var $aforemny$material_components_web_elm$Material$TextField$setOnInput = F2(
	function (onInput, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				cu: $elm$core$Maybe$Just(onInput)
			});
	});
var $aforemny$material_components_web_elm$Material$TextField$setPlaceholder = F2(
	function (placeholder, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bo: placeholder});
	});
var $aforemny$material_components_web_elm$Material$TextField$setType = F2(
	function (type_, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{c7: type_});
	});
var $aforemny$material_components_web_elm$Material$TextField$setValue = F2(
	function (value, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{h8: value});
	});
var $author$project$App$User$LogIn$View$viewField = F2(
	function (_v0, _v1) {
		var toSubmit = _v0.eU;
		var field = _v1.a;
		var value = _v1.b;
		var textFieldType = function () {
			if (!field) {
				return 'email';
			} else {
				return 'text';
			}
		}();
		var placeHolder = function () {
			switch (field) {
				case 0:
					return 'Email';
				case 1:
					return 'One-time passcode';
				case 2:
					return 'First name';
				default:
					return 'Last name (Optional)';
			}
		}();
		var config = A2(
			$aforemny$material_components_web_elm$Material$TextField$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$TextField$setType,
				$elm$core$Maybe$Just(textFieldType),
				A2(
					$aforemny$material_components_web_elm$Material$TextField$setValue,
					$elm$core$Maybe$Just(value),
					A2(
						$aforemny$material_components_web_elm$Material$TextField$setPlaceholder,
						$elm$core$Maybe$Just(placeHolder),
						A2(
							$aforemny$material_components_web_elm$Material$TextField$setDisabled,
							toSubmit,
							A2(
								$aforemny$material_components_web_elm$Material$TextField$setOnInput,
								$author$project$App$User$LogIn$Messages$UpdateField(field),
								$aforemny$material_components_web_elm$Material$TextField$config))))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-top', '10px')
				]),
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$outlined(config)
				]));
	});
var $author$project$App$User$LogIn$View$viewForm = function (model) {
	var fieldPairs = function () {
		var _v0 = model.dM;
		switch (_v0.$) {
			case 0:
				var email = _v0.a.dA;
				var firstName = _v0.a.gd;
				var lastName = _v0.a.gH;
				return _List_fromArray(
					[
						_Utils_Tuple2(0, email),
						_Utils_Tuple2(2, firstName),
						_Utils_Tuple2(3, lastName)
					]);
			case 1:
				var email = _v0.a.dA;
				return _List_fromArray(
					[
						_Utils_Tuple2(0, email)
					]);
			case 2:
				var email = _v0.a.dA;
				return _List_fromArray(
					[
						_Utils_Tuple2(0, email)
					]);
			default:
				var passcode = _v0.a.cz;
				return _List_fromArray(
					[
						_Utils_Tuple2(1, passcode)
					]);
		}
	}();
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Events$onSubmit($author$project$App$User$LogIn$Messages$Submitted)
			]),
		A2(
			$elm$core$List$map,
			$author$project$App$User$LogIn$View$viewField(model),
			fieldPairs));
};
var $aforemny$material_components_web_elm$Material$Typography$headline6 = $elm$html$Html$Attributes$class('mdc-typography--headline6');
var $author$project$App$User$LogIn$View$viewTitle = function (_v0) {
	var form = _v0.dM;
	var title = function () {
		if (!form.$) {
			return 'Sign up';
		} else {
			return 'Log in';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$headline6,
				A2($elm$html$Html$Attributes$style, 'font-weight', '300'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '20px')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$App$User$LogIn$View$viewCard = function (model) {
	var config = A2(
		$aforemny$material_components_web_elm$Material$Card$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '15px 15px 5px 15px')
			]),
		A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		config,
		{
			e8: $elm$core$Maybe$Just(
				$aforemny$material_components_web_elm$Material$Card$fullBleedActions(
					$author$project$App$User$LogIn$View$viewButton(model))),
			fn: _Utils_Tuple2(
				$aforemny$material_components_web_elm$Material$Card$block(
					$author$project$App$User$LogIn$View$viewTitle(model)),
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Card$block(
						$author$project$App$User$LogIn$View$viewForm(model))
					]))
		});
};
var $author$project$App$User$LogIn$View$view = function (model) {
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'max-width', '480px'),
				A2($elm$html$Html$Attributes$style, 'padding-top', '200px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								$author$project$App$User$LogIn$View$viewCard(model)
							]))
					]))
			]));
};
var $author$project$App$User$View$view = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$App$User$Messages$LogInMsg,
		$author$project$App$User$LogIn$View$view(model.d5));
};
var $author$project$App$AppBar$Model$Entities = 3;
var $author$project$App$AppBar$Model$Metrics = 1;
var $aforemny$material_components_web_elm$Material$TopAppBar$fixedAdjust = $elm$html$Html$Attributes$class('mdc-top-app-bar--fixed-adjust');
var $author$project$App$AppBar$Messages$ChoseNavItem = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TopAppBar$alignEnd = $elm$html$Html$Attributes$class('mdc-top-app-bar__section--align-end');
var $aforemny$material_components_web_elm$Material$TopAppBar$alignStart = $elm$html$Html$Attributes$class('mdc-top-app-bar__section--align-start');
var $aforemny$material_components_web_elm$Material$IconButton$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$IconButton$config = {bF: _List_Nil, aK: false, aM: $elm$core$Maybe$Nothing, gE: $elm$core$Maybe$Nothing, ce: $elm$core$Maybe$Nothing, cs: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$TopAppBar$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TopAppBar$config = {bF: _List_Nil, bQ: false, bZ: false};
var $aforemny$material_components_web_elm$Material$IconButton$Internal$Icon = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$IconButton$customIcon = F3(
	function (node, attributes, nodes) {
		return $aforemny$material_components_web_elm$Material$IconButton$Internal$Icon(
			{aY: attributes, bj: node, bk: nodes});
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $aforemny$material_components_web_elm$Material$IconButton$icon = function (iconName) {
	return A3(
		$aforemny$material_components_web_elm$Material$IconButton$customIcon,
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(iconName)
			]));
};
var $aforemny$material_components_web_elm$Material$IconButton$clickHandler = function (_v0) {
	var onClick = _v0.cs;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$IconButton$disabledAttr = function (_v0) {
	var disabled = _v0.aK;
	return $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$disabled(disabled));
};
var $aforemny$material_components_web_elm$Material$IconButton$hrefAttr = function (_v0) {
	var href = _v0.aM;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$IconButton$iconButtonCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-icon-button'));
var $aforemny$material_components_web_elm$Material$Menu$surfaceAnchor = $elm$html$Html$Attributes$class('mdc-menu-surface--anchor');
var $aforemny$material_components_web_elm$Material$IconButton$targetAttr = function (_v0) {
	var href = _v0.aM;
	var target = _v0.c2;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$IconButton$iconButton = F2(
	function (config_, icon_) {
		var innerConfig = config_;
		var additionalAttributes = innerConfig.bF;
		var href = innerConfig.aM;
		var disabled = innerConfig.aK;
		var wrapMenu = function (node) {
			var _v1 = innerConfig.ce;
			if (_v1.$ === 1) {
				return node;
			} else {
				var _v2 = _v1.a;
				var menuConfig = _v2.a;
				var firstListItem = _v2.b;
				var remainingListItems = _v2.c;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$Menu$surfaceAnchor]),
					_List_fromArray(
						[
							node,
							A3($aforemny$material_components_web_elm$Material$Menu$menu, menuConfig, firstListItem, remainingListItems)
						]));
			}
		};
		return wrapMenu(
			A3(
				$elm$html$Html$node,
				'mdc-icon-button',
				_List_Nil,
				_List_fromArray(
					[
						A2(
						((!_Utils_eq(href, $elm$core$Maybe$Nothing)) && (!disabled)) ? $elm$html$Html$a : $elm$html$Html$button,
						_Utils_ap(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$IconButton$iconButtonCs,
										$aforemny$material_components_web_elm$Material$IconButton$hrefAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$targetAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$disabledAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$clickHandler(config_)
									])),
							additionalAttributes),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$elm$core$Basics$never,
								function () {
									if (!icon_.$) {
										var node = icon_.a.bj;
										var attributes = icon_.a.aY;
										var nodes = icon_.a.bk;
										return A2(
											node,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Attributes$class('mdc-icon-button__icon'),
												attributes),
											nodes);
									} else {
										var node = icon_.a.bj;
										var attributes = icon_.a.aY;
										var nodes = icon_.a.bk;
										return A2(
											node,
											A2(
												$elm$core$List$cons,
												$elm$svg$Svg$Attributes$class('mdc-icon-button__icon'),
												attributes),
											nodes);
									}
								}())
							]))
					])));
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$Regular = 0;
var $aforemny$material_components_web_elm$Material$TopAppBar$denseCs = function (_v0) {
	var dense = _v0.bQ;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-top-app-bar--dense')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TopAppBar$fixedCs = function (_v0) {
	var fixed = _v0.bZ;
	return fixed ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-top-app-bar--fixed')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TopAppBar$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-top-app-bar'));
var $aforemny$material_components_web_elm$Material$TopAppBar$variantCs = function (variant) {
	switch (variant) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--short'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--short mdc-top-app-bar--short-collapsed'));
		default:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--prominent'));
	}
};
var $aforemny$material_components_web_elm$Material$TopAppBar$genericTopAppBar = F3(
	function (variant, config_, nodes) {
		var additionalAttributes = config_.bF;
		return A3(
			$elm$html$Html$node,
			'mdc-top-app-bar',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TopAppBar$rootCs,
							$aforemny$material_components_web_elm$Material$TopAppBar$variantCs(variant),
							$aforemny$material_components_web_elm$Material$TopAppBar$denseCs(config_),
							$aforemny$material_components_web_elm$Material$TopAppBar$fixedCs(config_)
						])),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$regular = F2(
	function (config_, nodes) {
		return A3($aforemny$material_components_web_elm$Material$TopAppBar$genericTopAppBar, 0, config_, nodes);
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $aforemny$material_components_web_elm$Material$TopAppBar$row = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$section,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-top-app-bar__row')
					]),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$section = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$section,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-top-app-bar__section')
					]),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$setDense = F2(
	function (dense, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bQ: dense});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$setFixed = F2(
	function (fixed, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bZ: fixed});
	});
var $aforemny$material_components_web_elm$Material$IconButton$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				cs: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$title = $elm$html$Html$Attributes$class('mdc-top-app-bar__title');
var $author$project$App$AppBar$View$view = F2(
	function (activeNavs, model) {
		var makeButtonIcon = function (nav) {
			return $aforemny$material_components_web_elm$Material$IconButton$icon(
				function () {
					switch (nav) {
						case 0:
							return 'search';
						case 1:
							return 'bubble_chart';
						case 2:
							return 'fitness_center';
						default:
							return 'list';
					}
				}());
		};
		var makeButtonConfig = function (nav) {
			return A2($aforemny$material_components_web_elm$Material$IconButton$setOnClick, nav, $aforemny$material_components_web_elm$Material$IconButton$config);
		};
		var makeButton = function (nav) {
			return A2(
				$aforemny$material_components_web_elm$Material$IconButton$iconButton,
				makeButtonConfig(nav),
				makeButtonIcon(nav));
		};
		var navItems = ($elm$core$List$length(activeNavs) > 1) ? A2($elm$core$List$map, makeButton, activeNavs) : _List_Nil;
		var barConfig = A2(
			$aforemny$material_components_web_elm$Material$TopAppBar$setFixed,
			true,
			A2($aforemny$material_components_web_elm$Material$TopAppBar$setDense, true, $aforemny$material_components_web_elm$Material$TopAppBar$config));
		return A2(
			$aforemny$material_components_web_elm$Material$TopAppBar$regular,
			barConfig,
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$TopAppBar$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$aforemny$material_components_web_elm$Material$TopAppBar$section,
							_List_fromArray(
								[$aforemny$material_components_web_elm$Material$TopAppBar$alignStart]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[$aforemny$material_components_web_elm$Material$TopAppBar$title]),
									_List_fromArray(
										[
											$elm$html$Html$text('nimoza')
										]))
								])),
							A2(
							$aforemny$material_components_web_elm$Material$TopAppBar$section,
							_List_fromArray(
								[$aforemny$material_components_web_elm$Material$TopAppBar$alignEnd]),
							navItems)
						]))
				]));
	});
var $aforemny$material_components_web_elm$Material$Card$actions = function (_v0) {
	var buttons = _v0.fs;
	var icons = _v0.gs;
	return {fs: buttons, b0: false, gs: icons};
};
var $author$project$App$Effort$View$centered = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'margin', 'auto'),
		A2($elm$html$Html$Attributes$style, 'text-align', 'center')
	]);
var $author$project$App$Effort$Messages$CheckAnswer = {$: 3};
var $author$project$App$Effort$Messages$PostEffort = function (a) {
	return {$: 4, a: a};
};
var $author$project$Models$Effort$correctAnswersToMapped = F2(
	function (dico, answers) {
		var mapFn = function (dId) {
			var _v0 = A2($elm$core$Dict$get, dId, dico);
			if (!_v0.$) {
				var eId = _v0.a;
				return A2($author$project$Models$Effort$WithEffortId, eId, $author$project$Models$Effort$Review);
			} else {
				return A2($author$project$Models$Effort$WithDependentId, dId, $author$project$Models$Effort$Review);
			}
		};
		return A2(
			$elm$core$List$map,
			mapFn,
			$elm$core$Dict$keys(answers));
	});
var $author$project$App$Effort$View$doTheThing = function (_v0) {
	var state = _v0.eL;
	var correctAnswers = _v0.fC;
	var effortIds = _v0.fY;
	switch (state.$) {
		case 0:
			return $author$project$App$Effort$Messages$PostEffort(
				A2($author$project$Models$Effort$correctAnswersToMapped, effortIds, correctAnswers));
		case 3:
			var d = state.b;
			return $author$project$App$Effort$Messages$PostEffort(d);
		default:
			return $author$project$App$Effort$Messages$CheckAnswer;
	}
};
var $elm$html$Html$Attributes$hidden = $elm$html$Html$Attributes$boolProperty('hidden');
var $author$project$App$Effort$View$howManyFields = function (_v0) {
	var effortType = _v0.bU;
	if (effortType.$ === 1) {
		return 0;
	} else {
		var userAs = effortType.a;
		switch (userAs.$) {
			case 0:
				var dico = userAs.a;
				return $elm$core$Dict$size(dico);
			case 1:
				return 1;
			default:
				var dico = userAs.a;
				return $elm$core$Dict$size(dico);
		}
	}
};
var $author$project$Models$Effort$isThereSomeAnswer = function (_v0) {
	var effortType = _v0.bU;
	var cond = function (a) {
		return $elm$core$String$length(a) > 1;
	};
	if (!effortType.$) {
		var userAs = effortType.a;
		switch (userAs.$) {
			case 0:
				var dico = userAs.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$List$all,
						cond,
						$elm$core$Dict$values(dico)));
			case 1:
				var string = userAs.a;
				return $elm$core$Maybe$Just(
					cond(string));
			default:
				var dico = userAs.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$List$all,
						cond,
						$elm$core$Dict$values(dico)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Effort$View$isDisabled = F2(
	function (status, eD) {
		return A2(
			$elm$core$List$any,
			$elm$core$Maybe$withDefault(false),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$map,
					function (s) {
						return !s;
					},
					status),
					A2(
					$elm$core$Maybe$map,
					$elm$core$Basics$not,
					$author$project$Models$Effort$isThereSomeAnswer(eD))
				]));
	});
var $aforemny$material_components_web_elm$Material$Typography$body1 = $elm$html$Html$Attributes$class('mdc-typography--body1');
var $author$project$App$Effort$View$questionLine = function (pairs) {
	var formatFn = function (_v0) {
		var str = _v0.a;
		var special = _v0.b;
		return special ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'font-style', 'italic')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				])) : $elm$html$Html$text(str);
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$aforemny$material_components_web_elm$Material$Typography$body1]),
		A2($elm$core$List$map, formatFn, pairs));
};
var $aforemny$material_components_web_elm$Material$Card$Icon = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$icon = F2(
	function (_v0, icon_) {
		var iconButtonConfig = _v0;
		return A2(
			$aforemny$material_components_web_elm$Material$IconButton$iconButton,
			_Utils_update(
				iconButtonConfig,
				{
					bF: A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-card__action'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class('mdc-card__action--icon'),
							iconButtonConfig.bF))
				}),
			icon_);
	});
var $aforemny$material_components_web_elm$Material$IconButton$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{aK: disabled});
	});
var $author$project$App$Effort$View$viewButton = F2(
	function (status, eD) {
		var icon = _Utils_eq(eD.eL, $author$project$Models$Effort$AwaitingAnswers) ? 'question_mark' : 'navigate_next';
		var config = A2(
			$aforemny$material_components_web_elm$Material$IconButton$setDisabled,
			A2($author$project$App$Effort$View$isDisabled, status, eD),
			A2(
				$aforemny$material_components_web_elm$Material$IconButton$setOnClick,
				$author$project$App$Effort$View$doTheThing(eD),
				$aforemny$material_components_web_elm$Material$IconButton$config));
		return A2(
			$aforemny$material_components_web_elm$Material$Card$icon,
			config,
			$aforemny$material_components_web_elm$Material$IconButton$icon(icon));
	});
var $author$project$App$Effort$View$answerLine = $elm$html$Html$span(
	_List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$body1,
			A2($elm$html$Html$Attributes$style, 'margin-top', '20px'),
			A2($elm$html$Html$Attributes$style, 'display', 'flex'),
			A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
			A2($elm$html$Html$Attributes$style, 'justify-content', 'center')
		]));
var $author$project$App$Effort$View$answerLines = F2(
	function (eD, fn) {
		var effortType = eD.bU;
		var correctAnswers = eD.fC;
		var dependencyType = eD.ad;
		var sortIds = function (ids) {
			var _v5 = eD.fP;
			if (!_v5.$) {
				var orders = _v5.a;
				var getOrder = function (id) {
					var _v6 = A2($elm$core$Dict$get, id, orders);
					if (!_v6.$) {
						var o = _v6.a;
						return o;
					} else {
						return 0;
					}
				};
				return A2($elm$core$List$sortBy, getOrder, ids);
			} else {
				return ids;
			}
		};
		var makeLine = function (id) {
			return $author$project$App$Effort$View$answerLine(
				A2(fn, eD, id));
		};
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			function () {
				if (effortType.$ === 1) {
					var _v1 = _Utils_Tuple2(
						dependencyType,
						$elm$core$Dict$keys(correctAnswers));
					if ((_v1.a.$ === 4) && _v1.b.b) {
						var _v2 = _v1.a;
						var _v3 = _v1.b;
						var first = _v3.a;
						return _List_fromArray(
							[
								makeLine(first)
							]);
					} else {
						return A2(
							$elm$core$List$map,
							makeLine,
							$elm$core$Dict$keys(correctAnswers));
					}
				} else {
					var userAs = effortType.a;
					switch (userAs.$) {
						case 0:
							var dico = userAs.a;
							return A2(
								$elm$core$List$map,
								makeLine,
								sortIds(
									$elm$core$Dict$keys(dico)));
						case 1:
							return _List_fromArray(
								[
									makeLine(0)
								]);
						default:
							var dico = userAs.a;
							return A2(
								$elm$core$List$map,
								makeLine,
								$elm$core$Dict$keys(dico));
					}
				}
			}());
	});
var $author$project$App$Effort$Messages$SetUserAnswer = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Effort$View$answerCheckToColor = function (maybeAC) {
	if (!maybeAC.$) {
		if (maybeAC.a) {
			return '#68B984';
		} else {
			return '#EB6440';
		}
	} else {
		return '#B2B2B2';
	}
};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $elm$core$List$unzip = function (pairs) {
	var step = F2(
		function (_v0, _v1) {
			var x = _v0.a;
			var y = _v0.b;
			var xs = _v1.a;
			var ys = _v1.b;
			return _Utils_Tuple2(
				A2($elm$core$List$cons, x, xs),
				A2($elm$core$List$cons, y, ys));
		});
	return A3(
		$elm$core$List$foldr,
		step,
		_Utils_Tuple2(_List_Nil, _List_Nil),
		pairs);
};
var $author$project$Models$Effort$checkedAnswerToCorrectToShow = F2(
	function (correctAs, checkedAs) {
		var recurse = function (_v0) {
			recurse:
			while (true) {
				var pAP = _v0.a;
				var pL = _v0.b;
				var aL = _v0.c;
				var _v1 = _Utils_Tuple2(pL, aL);
				if (_v1.a.b && _v1.b.b) {
					var _v2 = _v1.a;
					var firstP = _v2.a;
					var remainingP = _v2.b;
					var _v3 = _v1.b;
					var firstA = _v3.a;
					var remainingA = _v3.b;
					var $temp$_v0 = _Utils_Tuple3(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(firstP, firstA),
							pAP),
						remainingP,
						remainingA);
					_v0 = $temp$_v0;
					continue recurse;
				} else {
					return _Utils_Tuple3(pAP, pL, aL);
				}
			}
		};
		var positions = A2(
			$elm$core$List$range,
			1,
			$elm$core$List$length(
				$elm$core$Dict$toList(correctAs)));
		var ids = $elm$core$Dict$keys(correctAs);
		var filledCorrectly = A2(
			$elm$core$List$filterMap,
			function (_v6) {
				var id = _v6.a;
				var cA = _v6.b;
				if ((cA.$ === 1) && (!cA.a.$)) {
					var mi = cA.a.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(mi, id));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			$elm$core$Dict$toList(checkedAs));
		var _v4 = $elm$core$List$unzip(filledCorrectly);
		var donePositions = _v4.a;
		var doneIds = _v4.b;
		var _v5 = recurse(
			_Utils_Tuple3(
				filledCorrectly,
				A2(
					$elm$core$List$filter,
					function (p) {
						return !A2(
							$elm$core$Set$member,
							p,
							$elm$core$Set$fromList(donePositions));
					},
					positions),
				A2(
					$elm$core$List$filter,
					function (id) {
						return !A2(
							$elm$core$Set$member,
							id,
							$elm$core$Set$fromList(doneIds));
					},
					ids)));
		var answer = _v5.a;
		return $elm$core$Dict$fromList(answer);
	});
var $author$project$Models$Effort$getAnswer = F2(
	function (_v0, maybeInd) {
		var effortType = _v0.bU;
		var correctAnswers = _v0.fC;
		var state = _v0.eL;
		var _v1 = _Utils_Tuple2(effortType, state);
		_v1$2:
		while (true) {
			if (_v1.a.$ === 1) {
				if (!_v1.b.$) {
					var _v2 = _v1.a;
					var _v3 = _v1.b;
					if (!maybeInd.$) {
						var ind = maybeInd.a;
						return A2($elm$core$Dict$get, ind, correctAnswers);
					} else {
						var _v5 = $elm$core$Dict$values(correctAnswers);
						if (_v5.b && (!_v5.b.b)) {
							var theOnly = _v5.a;
							return $elm$core$Maybe$Just(theOnly);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}
				} else {
					break _v1$2;
				}
			} else {
				if (_v1.b.$ === 3) {
					var userAs = _v1.a.a;
					var _v6 = _v1.b;
					var checkedAs = _v6.a;
					var _v7 = _Utils_Tuple2(userAs, maybeInd);
					_v7$3:
					while (true) {
						switch (_v7.a.$) {
							case 0:
								if (!_v7.b.$) {
									var ind = _v7.b.a;
									return A2($elm$core$Dict$get, ind, correctAnswers);
								} else {
									break _v7$3;
								}
							case 1:
								var _v8 = $elm$core$Dict$values(correctAnswers);
								if (_v8.b) {
									var val = _v8.a;
									return $elm$core$Maybe$Just(val);
								} else {
									return $elm$core$Maybe$Nothing;
								}
							default:
								if (!_v7.b.$) {
									var ind = _v7.b.a;
									var mapping = A2($author$project$Models$Effort$checkedAnswerToCorrectToShow, correctAnswers, checkedAs);
									var _v9 = A2($elm$core$Dict$get, ind, mapping);
									if (!_v9.$) {
										var depId = _v9.a;
										return A2($elm$core$Dict$get, depId, correctAnswers);
									} else {
										return $elm$core$Maybe$Nothing;
									}
								} else {
									break _v7$3;
								}
						}
					}
					return $elm$core$Maybe$Nothing;
				} else {
					break _v1$2;
				}
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $author$project$Models$Effort$getUserAnswer = F2(
	function (uA, maybeInd) {
		var _v0 = _Utils_Tuple2(uA, maybeInd);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var dico = _v0.a.a;
						var ind = _v0.b.a;
						return A2($elm$core$Dict$get, ind, dico);
					} else {
						break _v0$3;
					}
				case 1:
					var s = _v0.a.a;
					return $elm$core$Maybe$Just(s);
				default:
					if (!_v0.b.$) {
						var dico = _v0.a.a;
						var ind = _v0.b.a;
						return A2($elm$core$Dict$get, ind, dico);
					} else {
						break _v0$3;
					}
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Models$Effort$isAnswerCorrect = F2(
	function (checkedAnswers, maybeInd) {
		var mapFn = function (checked) {
			if (checked.$ === 1) {
				var i = checked.a;
				return _Utils_Tuple2(i, true);
			} else {
				var i = checked.a;
				return _Utils_Tuple2(i, false);
			}
		};
		var filterMapFn = function (pair) {
			return _Utils_eq(pair.a, maybeInd) ? $elm$core$Maybe$Just(pair.b) : $elm$core$Maybe$Nothing;
		};
		var matching = A2(
			$elm$core$List$filterMap,
			filterMapFn,
			A2(
				$elm$core$List$map,
				mapFn,
				$elm$core$Dict$values(checkedAnswers)));
		if (matching.b && (!matching.b.b)) {
			var first = matching.a;
			return first;
		} else {
			return false;
		}
	});
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$App$Effort$View$answerInput = F2(
	function (eD, maybeInd) {
		var width = function (widthInPix) {
			return A2(
				$elm$html$Html$Attributes$style,
				'min-width',
				$elm$core$String$fromInt(widthInPix) + 'px');
		};
		var correctAnswer = $author$project$Util$maybeStrToStr(
			A2($author$project$Models$Effort$getAnswer, eD, maybeInd));
		var correctAnswerLen = $elm$core$String$length(correctAnswer);
		var colorBottomBorder = function (aC) {
			return A2(
				$elm$html$Html$Attributes$style,
				'border-bottom',
				'2px solid ' + $author$project$App$Effort$View$answerCheckToColor(aC));
		};
		var articleColor = function (art) {
			switch (art) {
				case 'die':
					return 'rgb(254, 244, 168)';
				case 'der':
					return 'rgb(235, 193, 84)';
				case 'das':
					return 'rgb(175, 212, 171)';
				default:
					return '#F0EEED';
			}
		};
		var _v0 = eD;
		var state = _v0.eL;
		var effortType = _v0.bU;
		var correctAnswers = _v0.fC;
		var dependencyType = _v0.ad;
		var entity = _v0.aa;
		var answerTypeDependent = function () {
			var attrs = _List_fromArray(
				[
					$elm$html$Html$Attributes$id('quizInput0'),
					$elm$html$Html$Attributes$autofocus(true)
				]);
			if (effortType.$ === 1) {
				return _List_Nil;
			} else {
				var userAs = effortType.a;
				switch (userAs.$) {
					case 0:
						var dico = userAs.a;
						var _v14 = $elm$core$Dict$keys(dico);
						if (_v14.b) {
							var first = _v14.a;
							return _Utils_eq(
								maybeInd,
								$elm$core$Maybe$Just(first)) ? attrs : _List_Nil;
						} else {
							return _List_Nil;
						}
					case 1:
						return attrs;
					default:
						return _Utils_eq(
							maybeInd,
							$elm$core$Maybe$Just(1)) ? attrs : _List_Nil;
				}
			}
		}();
		var depEndTypeDependent = function () {
			var _v7 = _Utils_Tuple2(dependencyType, entity.bX);
			_v7$3:
			while (true) {
				switch (_v7.a.$) {
					case 0:
						var _v8 = _v7.a;
						return _List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-transform', 'lowercase')
							]);
					case 1:
						var _v9 = _v7.a;
						return _List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-transform', 'capitalize')
							]);
					case 4:
						if (!_v7.b) {
							var _v10 = _v7.a;
							var _v11 = _v7.b;
							return _List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'text-transform', 'capitalize')
								]);
						} else {
							break _v7$3;
						}
					default:
						break _v7$3;
				}
			}
			return _List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'text-transform', 'lowercase')
				]);
		}();
		var _v1 = function () {
			var _v2 = _Utils_Tuple2(state, effortType);
			_v2$4:
			while (true) {
				switch (_v2.a.$) {
					case 0:
						if (_v2.b.$ === 1) {
							var _v3 = _v2.a;
							var _v4 = _v2.b;
							return _Utils_Tuple2(
								correctAnswer,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$Attributes$style,
										'background-color',
										articleColor(correctAnswer))
									]));
						} else {
							break _v2$4;
						}
					case 1:
						if (!_v2.b.$) {
							var _v5 = _v2.a;
							var userAs = _v2.b.a;
							return _Utils_Tuple2(
								$author$project$Util$maybeStrToStr(
									A2($author$project$Models$Effort$getUserAnswer, userAs, maybeInd)),
								_List_fromArray(
									[
										colorBottomBorder($elm$core$Maybe$Nothing),
										A2($elm$html$Html$Attributes$style, 'background-color', '#F0EEED')
									]));
						} else {
							break _v2$4;
						}
					case 2:
						if (!_v2.b.$) {
							var d = _v2.a.a;
							var userAs = _v2.b.a;
							return _Utils_Tuple2(
								$author$project$Util$maybeStrToStr(
									A2($author$project$Models$Effort$getUserAnswer, userAs, maybeInd)),
								_List_fromArray(
									[
										colorBottomBorder(
										$elm$core$Maybe$Just(
											A2($author$project$Models$Effort$isAnswerCorrect, d, maybeInd))),
										A2($elm$html$Html$Attributes$style, 'background-color', '#F0EEED')
									]));
						} else {
							break _v2$4;
						}
					default:
						var _v6 = _v2.a;
						var checked = _v6.a;
						return _Utils_Tuple2(
							correctAnswer,
							_List_fromArray(
								[
									colorBottomBorder(
									$elm$core$Maybe$Just(true)),
									A2($elm$html$Html$Attributes$style, 'background-color', '#F0EEED')
								]));
				}
			}
			return _Utils_Tuple2('', _List_Nil);
		}();
		var val = _v1.a;
		var stateDependent = _v1.b;
		var baseAttributes = _List_fromArray(
			[
				$elm$html$Html$Events$onInput(
				$author$project$App$Effort$Messages$SetUserAnswer(maybeInd)),
				$elm$html$Html$Attributes$value(val),
				$elm$html$Html$Attributes$autofocus(true),
				$elm$html$Html$Attributes$autocomplete(false),
				$elm$html$Html$Attributes$spellcheck(false),
				A2($elm$html$Html$Attributes$style, 'autocorrect', 'off'),
				A2($elm$html$Html$Attributes$style, 'autocapitalize', 'none'),
				$elm$html$Html$Attributes$readonly(
				!_Utils_eq(state, $author$project$Models$Effort$AwaitingAnswers)),
				A2($elm$html$Html$Attributes$style, 'border', 'none'),
				width(correctAnswerLen * 4),
				A2($elm$html$Html$Attributes$style, 'outline', 'none'),
				A2($elm$html$Html$Attributes$style, 'color', 'black'),
				A2($elm$html$Html$Attributes$style, 'padding', '2px'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '5px'),
				A2($elm$html$Html$Attributes$style, 'margin-left', '5px'),
				$aforemny$material_components_web_elm$Material$Typography$body1
			]);
		var config = $elm$core$List$concat(
			_List_fromArray(
				[baseAttributes, stateDependent, depEndTypeDependent, answerTypeDependent]));
		return A2($elm$html$Html$input, config, _List_Nil);
	});
var $author$project$Models$Conjugation$formatVerbPersonCount = F2(
	function (p, c) {
		var _v0 = _Utils_Tuple2(p, c);
		if (!_v0.b) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return '1s';
				case 1:
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return '2s';
				default:
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					return '3s';
			}
		} else {
			switch (_v0.a) {
				case 0:
					var _v7 = _v0.a;
					var _v8 = _v0.b;
					return '1p';
				case 1:
					var _v9 = _v0.a;
					var _v10 = _v0.b;
					return '2p';
				default:
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					return '3p';
			}
		}
	});
var $author$project$Models$Conjugation$personCountToNomPron = function (personCount) {
	var mapDict = $elm$core$Dict$fromList(
		_List_fromArray(
			[
				_Utils_Tuple2('1s', 'ich'),
				_Utils_Tuple2('2s', 'du'),
				_Utils_Tuple2('3s', 'er/es/sie'),
				_Utils_Tuple2('1p', 'wir'),
				_Utils_Tuple2('2p', 'ihr'),
				_Utils_Tuple2('3p', 'sie/Sie')
			]));
	var maybePron = A2($elm$core$Dict$get, personCount, mapDict);
	if (!maybePron.$) {
		var pron = maybePron.a;
		return pron;
	} else {
		return '';
	}
};
var $author$project$App$Effort$View$conjugationAnswerLineComps = F2(
	function (eD, dId) {
		var dependencies = eD.a0;
		var input = A2(
			$author$project$App$Effort$View$answerInput,
			eD,
			$elm$core$Maybe$Just(dId));
		var filterMapFn = function (d) {
			return _Utils_eq(d.gt, dId) ? $elm$core$Maybe$Just(d.fQ) : $elm$core$Maybe$Nothing;
		};
		var before = F2(
			function (person, count) {
				return $elm$html$Html$text(
					$author$project$Models$Conjugation$personCountToNomPron(
						A2($author$project$Models$Conjugation$formatVerbPersonCount, person, count)));
			});
		var _v0 = A2($elm$core$List$filterMap, filterMapFn, dependencies);
		if ((_v0.b && (_v0.a.$ === 3)) && (!_v0.b.b)) {
			var _v1 = _v0.a;
			var vp = _v1.b;
			var vc = _v1.c;
			return _List_fromArray(
				[
					A2(before, vp, vc),
					input
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Models$Conjugation$formatTenseAspect = F2(
	function (t, a) {
		var _v0 = _Utils_Tuple2(t, a);
		switch (_v0.a) {
			case 0:
				switch (_v0.b) {
					case 0:
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						return 'simple present';
					case 1:
						var _v3 = _v0.a;
						var _v4 = _v0.b;
						return 'present perfect';
					default:
						var _v5 = _v0.a;
						var _v6 = _v0.b;
						return 'present progressive';
				}
			case 1:
				switch (_v0.b) {
					case 0:
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						return 'simple past';
					case 1:
						var _v9 = _v0.a;
						var _v10 = _v0.b;
						return 'past perfect';
					default:
						var _v11 = _v0.a;
						var _v12 = _v0.b;
						return 'past progressive';
				}
			default:
				switch (_v0.b) {
					case 0:
						var _v13 = _v0.a;
						var _v14 = _v0.b;
						return 'simple future';
					case 1:
						var _v15 = _v0.a;
						var _v16 = _v0.b;
						return 'future perfect';
					default:
						var _v17 = _v0.a;
						var _v18 = _v0.b;
						return 'future progressive';
				}
		}
	});
var $author$project$Models$Conjugation$formatMTA = function (m) {
	var miFormatter = function (mi) {
		_v1$2:
		while (true) {
			if (!mi.$) {
				switch (mi.a) {
					case 1:
						return '(I)';
					case 2:
						return '(II)';
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return '';
	};
	switch (m.$) {
		case 0:
			var t = m.a;
			var a = m.b;
			return A2($author$project$Models$Conjugation$formatTenseAspect, t, a);
		case 1:
			var t = m.a;
			var a = m.b;
			var mi = m.c;
			return A2($author$project$Models$Conjugation$formatTenseAspect, t, a) + (' -- ' + ('subjunctive ' + miFormatter(mi)));
		default:
			return 'imperative';
	}
};
var $author$project$App$Effort$View$viewConjugationQ = function (eD) {
	var entity = eD.aa;
	var dependencies = eD.a0;
	var dependencyType = eD.ad;
	var state = eD.eL;
	var question = function (mta) {
		return _List_fromArray(
			[
				_Utils_Tuple2(entity.aa, true),
				_Utils_Tuple2(' (', false),
				_Utils_Tuple2(
				$author$project$Models$Conjugation$formatMTA(mta),
				true),
				_Utils_Tuple2(')', false)
			]);
	};
	return A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		function () {
			if (dependencyType.$ === 3) {
				var mta = dependencyType.a;
				return _List_fromArray(
					[
						$author$project$App$Effort$View$questionLine(
						question(mta)),
						A2($author$project$App$Effort$View$answerLines, eD, $author$project$App$Effort$View$conjugationAnswerLineComps)
					]);
			} else {
				return _List_Nil;
			}
		}());
};
var $author$project$App$Effort$View$definitionAnswerLineComps = F2(
	function (eD, dId) {
		var input = A2(
			$author$project$App$Effort$View$answerInput,
			eD,
			$elm$core$Maybe$Just(dId));
		return _List_fromArray(
			[input]);
	});
var $author$project$Models$Entity$formatEntityType = function (et) {
	switch (et) {
		case 0:
			return 'noun';
		case 1:
			return 'verb';
		case 2:
			return 'adjective';
		case 3:
			return 'adverb';
		case 4:
			return 'pronoun';
		case 5:
			return 'conjunction';
		case 6:
			return 'preposition';
		case 7:
			return 'postposition';
		case 8:
			return 'proverb';
		case 9:
			return 'prep-phrase';
		case 10:
			return 'phrase';
		case 11:
			return 'article';
		case 12:
			return 'particle';
		case 13:
			return 'interjection';
		default:
			return 'determiner';
	}
};
var $author$project$Models$Entity$getDefinition = function (uED) {
	var _v0 = uED.fQ;
	if (_v0.$ === 4) {
		var d = _v0.a;
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Effort$View$viewDefinitionQ = function (eD) {
	var entity = eD.aa;
	var dependencies = eD.a0;
	var dependencyType = eD.ad;
	var state = eD.eL;
	var defsJoinedString = A2(
		$elm$core$String$join,
		' / ',
		A2($elm$core$List$filterMap, $author$project$Models$Entity$getDefinition, dependencies));
	var question = _Utils_eq(state, $author$project$Models$Effort$AwaitingReview) ? _List_fromArray(
		[
			_Utils_Tuple2(defsJoinedString, true)
		]) : _List_fromArray(
		[
			_Utils_Tuple2('Means ', false),
			_Utils_Tuple2(defsJoinedString, true),
			_Utils_Tuple2(' (', false),
			_Utils_Tuple2(
			$author$project$Models$Entity$formatEntityType(entity.bX),
			false),
			_Utils_Tuple2(')', false)
		]);
	return A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		function () {
			if (dependencyType.$ === 4) {
				return _List_fromArray(
					[
						$author$project$App$Effort$View$questionLine(question),
						A2($author$project$App$Effort$View$answerLines, eD, $author$project$App$Effort$View$definitionAnswerLineComps)
					]);
			} else {
				return _List_Nil;
			}
		}());
};
var $elm$core$Char$toUpper = _Char_toUpper;
var $author$project$Util$capitalize = function (s) {
	var _v0 = $elm$core$String$toList(s);
	if (_v0.b) {
		var firstC = _v0.a;
		var otherCs = _v0.b;
		return $elm$core$String$fromList(
			A2(
				$elm$core$List$cons,
				$elm$core$Char$toUpper(firstC),
				otherCs));
	} else {
		return '';
	}
};
var $author$project$Models$Entity$indefArticleForEntityType = function (et) {
	return ((et === 2) || ((et === 3) || (et === 11))) ? 'an' : 'a';
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $aforemny$material_components_web_elm$Material$Typography$body2 = $elm$html$Html$Attributes$class('mdc-typography--body2');
var $author$project$App$Wiktionary$View$viewGloss = function (_v0) {
	var qualifier = _v0.he;
	var glosses = _v0.gj;
	var rawGlosses = _v0.hg;
	var qualifierPart = function () {
		if (!qualifier.$) {
			var q = qualifier.a;
			return $elm$core$Maybe$Just(
				A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Typography$body2,
							A2($elm$html$Html$Attributes$style, 'margin-right', '3px'),
							A2($elm$html$Html$Attributes$style, 'font-weight', '300')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(q + ': ')
						])));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var makeBase = function (gs) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$Typography$body2]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($elm$core$String$join, '; ', gs))
				]));
	};
	var _v1 = _Utils_Tuple2(glosses, rawGlosses);
	if (!_v1.b.$) {
		var rg = _v1.b.a;
		return $elm$core$Maybe$Just(
			makeBase(rg));
	} else {
		if (!_v1.a.$) {
			var g = _v1.a.a;
			return $elm$core$Maybe$Just(
				A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								qualifierPart,
								$elm$core$Maybe$Just(
								makeBase(g))
							]))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}
};
var $author$project$App$Effort$View$viewDummyDefQ = function (eD) {
	var entity = eD.aa;
	var dependencies = eD.a0;
	var dependencyType = eD.ad;
	var state = eD.eL;
	var wiktRec = eD.ig;
	var viewSense = function (s) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'list-style-type', 'circle')
				]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$author$project$App$Wiktionary$View$viewGloss(s)
					])));
	};
	var viewSenses = function (rec) {
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-left', '20px'),
					A2($elm$html$Html$Attributes$style, 'text-align', 'start')
				]),
			A2($elm$core$List$map, viewSense, rec.hA));
	};
	var eT = entity.bX;
	var eTArticle = $author$project$Models$Entity$indefArticleForEntityType(eT);
	var question = _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Util$capitalize(eTArticle),
			false),
			_Utils_Tuple2(' ', false),
			_Utils_Tuple2(
			$author$project$Models$Entity$formatEntityType(eT),
			false),
			_Utils_Tuple2(' that means', false)
		]);
	return A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		function () {
			var _v0 = _Utils_Tuple2(dependencyType, wiktRec);
			if ((_v0.a.$ === 6) && (!_v0.b.$)) {
				var _v1 = _v0.a;
				var rec = _v0.b.a;
				return _List_fromArray(
					[
						$author$project$App$Effort$View$questionLine(question),
						A2(
						$elm$html$Html$map,
						$author$project$App$Effort$Messages$WiktMsg,
						viewSenses(rec)),
						A2($author$project$App$Effort$View$answerLines, eD, $author$project$App$Effort$View$definitionAnswerLineComps)
					]);
			} else {
				return _List_Nil;
			}
		}());
};
var $author$project$App$Effort$View$genderAnswerLineComps = F2(
	function (eD, dId) {
		var entity = eD.aa;
		var input = A2(
			$author$project$App$Effort$View$answerInput,
			eD,
			$elm$core$Maybe$Just(dId));
		var after = $elm$html$Html$text(
			$author$project$Util$capitalize(entity.aa));
		return _List_fromArray(
			[input, after]);
	});
var $author$project$App$Effort$View$viewGenderQ = function (eD) {
	var dependencyType = eD.ad;
	var state = eD.eL;
	var question = _List_fromArray(
		[
			_Utils_Tuple2('Definite article', false)
		]);
	return A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		function () {
			if (!dependencyType.$) {
				return _List_fromArray(
					[
						$author$project$App$Effort$View$questionLine(question),
						A2($author$project$App$Effort$View$answerLines, eD, $author$project$App$Effort$View$genderAnswerLineComps)
					]);
			} else {
				return _List_Nil;
			}
		}());
};
var $author$project$App$Effort$View$pluralAnswerLineComps = F2(
	function (eD, dId) {
		var input = A2(
			$author$project$App$Effort$View$answerInput,
			eD,
			$elm$core$Maybe$Just(dId));
		var before = $elm$html$Html$text('die');
		return _List_fromArray(
			[before, input]);
	});
var $author$project$App$Effort$View$viewPluralQ = function (eD) {
	var entity = eD.aa;
	var dependencyType = eD.ad;
	var state = eD.eL;
	var question = _List_fromArray(
		[
			_Utils_Tuple2('Pl.', true),
			_Utils_Tuple2(' of ', false),
			_Utils_Tuple2(
			$author$project$Util$capitalize(entity.aa),
			true)
		]);
	return A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		function () {
			if (dependencyType.$ === 1) {
				return _List_fromArray(
					[
						$author$project$App$Effort$View$questionLine(question),
						A2($author$project$App$Effort$View$answerLines, eD, $author$project$App$Effort$View$pluralAnswerLineComps)
					]);
			} else {
				return _List_Nil;
			}
		}());
};
var $author$project$App$Effort$View$viewQ = function (eD) {
	var dependencyType = eD.ad;
	switch (dependencyType.$) {
		case 0:
			return $author$project$App$Effort$View$viewGenderQ(eD);
		case 1:
			return $author$project$App$Effort$View$viewPluralQ(eD);
		case 4:
			return $author$project$App$Effort$View$viewDefinitionQ(eD);
		case 3:
			return $author$project$App$Effort$View$viewConjugationQ(eD);
		case 6:
			return $author$project$App$Effort$View$viewDummyDefQ(eD);
		default:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$App$Effort$View$viewCard = function (_v0) {
	var status = _v0.eM;
	var effortData = _v0.dz;
	var makeActions = function (i) {
		return $aforemny$material_components_web_elm$Material$Card$actions(
			{
				fs: _List_Nil,
				gs: _List_fromArray(
					[i])
			});
	};
	var hiddenButton = function (eD) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$App$Effort$View$doTheThing(eD)),
					$elm$html$Html$Attributes$hidden(true),
					$elm$html$Html$Attributes$disabled(
					A2($author$project$App$Effort$View$isDisabled, status, eD))
				]),
			_List_Nil);
	};
	var formConfig = function (eD) {
		return (!A2($author$project$App$Effort$View$isDisabled, status, eD)) ? _List_fromArray(
			[
				$elm$html$Html$Events$onSubmit(
				$author$project$App$Effort$View$doTheThing(eD))
			]) : _List_Nil;
	};
	var formmedContent = function (eD) {
		var elCond = ($author$project$App$Effort$View$howManyFields(eD) > 1) ? _List_fromArray(
			[
				hiddenButton(eD)
			]) : _List_Nil;
		var elBase = _List_fromArray(
			[
				$author$project$App$Effort$View$viewQ(eD)
			]);
		var conf = formConfig(eD);
		return A2(
			$elm$html$Html$form,
			conf,
			$elm$core$List$concat(
				_List_fromArray(
					[elBase, elCond])));
	};
	var defaultContent = A2(
		$elm$html$Html$div,
		$author$project$App$Effort$View$centered,
		_List_fromArray(
			[
				$author$project$App$Effort$View$questionLine(
				_List_fromArray(
					[
						_Utils_Tuple2('That\'s all for now -- ', false),
						_Utils_Tuple2('come back later for more!', false)
					]))
			]));
	var config = A2(
		$aforemny$material_components_web_elm$Material$Card$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding-top', '25px'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '20px')
			]),
		A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		config,
		{
			e8: A2(
				$elm$core$Maybe$map,
				makeActions,
				A2(
					$elm$core$Maybe$map,
					$author$project$App$Effort$View$viewButton(status),
					effortData)),
			fn: _Utils_Tuple2(
				$aforemny$material_components_web_elm$Material$Card$block(
					A2(
						$elm$core$Maybe$withDefault,
						defaultContent,
						A2($elm$core$Maybe$map, formmedContent, effortData))),
				_List_Nil)
		});
};
var $aforemny$material_components_web_elm$Material$Typography$overline = $elm$html$Html$Attributes$class('mdc-typography--overline');
var $author$project$App$Effort$View$viewTitle = function (_v0) {
	var effortData = _v0.dz;
	var title = function (s) {
		return _Utils_eq(s, $author$project$Models$Effort$AwaitingReview) ? 'Review' : 'Quiz';
	};
	var spanConfig = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'top', '-16px'),
			A2($elm$html$Html$Attributes$style, 'background', 'white'),
			A2($elm$html$Html$Attributes$style, 'z-index', '1')
		]);
	var makeSpan = function (_v1) {
		var state = _v1.eL;
		return A2(
			$elm$html$Html$span,
			spanConfig,
			_List_fromArray(
				[
					$elm$html$Html$text(
					title(state))
				]));
	};
	var divConfig = _List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$overline,
			A2($elm$html$Html$Attributes$style, 'font-weight', '100'),
			A2($elm$html$Html$Attributes$style, 'position', 'relative'),
			A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
			A2($elm$html$Html$Attributes$style, 'display', 'flex'),
			A2($elm$html$Html$Attributes$style, 'justify-content', 'center')
		]);
	return A2(
		$elm$html$Html$div,
		divConfig,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2($elm$core$Maybe$map, makeSpan, effortData)
				])));
};
var $author$project$App$Effort$View$view = function (model) {
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'max-width', '480px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$App$Effort$View$viewTitle(model),
										$author$project$App$Effort$View$viewCard(model)
									]))
							]))
					]))
			]));
};
var $author$project$App$Entities$View$getCountAll = function (_v0) {
	var ids = _v0.b3;
	return $elm$core$List$length(ids);
};
var $author$project$App$Entities$View$getCountAvailable = function (_v0) {
	var core = _v0.dq;
	return $elm$core$Dict$size(core);
};
var $author$project$App$Wiktionary$View$makePosedPairs = F2(
	function (cntxt, vs) {
		var makePairForSearch = F3(
			function (indPosDict, ind, rds) {
				var getPos = function (i) {
					return A2($elm$core$Dict$get, i, indPosDict);
				};
				var thisPos = getPos(ind);
				var withPosCond = (ind === 1) || (!_Utils_eq(
					thisPos,
					getPos(ind - 1)));
				return withPosCond ? _Utils_Tuple2(thisPos, rds) : _Utils_Tuple2($elm$core$Maybe$Nothing, rds);
			});
		if (!cntxt) {
			var inds = A2(
				$elm$core$List$range,
				1,
				$elm$core$List$length(vs));
			var indPoss = $elm$core$Dict$fromList(
				A3(
					$elm$core$List$map2,
					$elm$core$Tuple$pair,
					inds,
					A2(
						$elm$core$List$map,
						function (_v1) {
							var rec = _v1.hh;
							return rec.cE;
						},
						vs)));
			return A3(
				$elm$core$List$map2,
				makePairForSearch(indPoss),
				inds,
				vs);
		} else {
			return A2(
				$elm$core$List$map,
				function (rds) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(rds.hh.cE),
						rds);
				},
				vs);
		}
	});
var $author$project$App$Wiktionary$View$hideButton = function (_v0) {
	var pos = _v0.cE;
	return _Utils_eq(
		$author$project$Models$Wikt$entityTypeFromPoS(pos),
		$elm$core$Maybe$Nothing);
};
var $aforemny$material_components_web_elm$Material$Typography$caption = $elm$html$Html$Attributes$class('mdc-typography--caption');
var $author$project$Util$brackets = F2(
	function (c, s) {
		switch (c) {
			case '(':
				return '(' + (s + ')');
			case '{':
				return '{' + (s + '}');
			case '[':
				return '[' + (s + ']');
			case '<':
				return '<' + (s + '>');
			default:
				return s;
		}
	});
var $author$project$App$Wiktionary$View$viewExamples = function (examples) {
	var makeRef = function (ref) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'color', '#a0a0a0'),
					A2($elm$html$Html$Attributes$style, 'margin-left', '3px')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Util$brackets, '(', ref))
				]));
	};
	var makeLangHeader = function (lang) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'font-weight', '400'),
					A2($elm$html$Html$Attributes$style, 'margin-right', '2px'),
					$aforemny$material_components_web_elm$Material$Typography$caption
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(lang + ')')
				]));
	};
	var makeMain = function (_v1) {
		var roman = _v1.ht;
		var etext = _v1.f4;
		var ref = _v1.hj;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'font-weight', '300')
					]),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just(
							makeLangHeader('de')),
							$elm$core$Maybe$Just(
							$elm$html$Html$text(
								A2($elm$core$Maybe$withDefault, etext, roman))),
							A2($elm$core$Maybe$map, makeRef, ref)
						]))));
	};
	var makeEnglish = function (example) {
		var _v0 = example.f1;
		if (!_v0.$) {
			var eng = _v0.a;
			return $elm$core$Maybe$Just(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', '100')
						]),
					_List_fromArray(
						[
							makeLangHeader('en'),
							$elm$html$Html$text(eng)
						])));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var makeExample = function (example) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'list-style-type', '-'),
					$aforemny$material_components_web_elm$Material$Typography$body2
				]),
			A2(
				$elm$core$List$filterMap,
				function (fnc) {
					return fnc(example);
				},
				_List_fromArray(
					[makeMain, makeEnglish])));
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'padding-left', '25px')
					]),
				A2($elm$core$List$map, makeExample, examples))
			]));
};
var $author$project$App$Wiktionary$Messages$ClickedRelationshipWord = function (a) {
	return {$: 4, a: a};
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$App$Wiktionary$View$viewRelationships = F3(
	function (_v0, title, rels) {
		var wordIds = _v0.e4;
		var core = _v0.dq;
		var context = _v0.dp;
		var viewRel = function (_v4) {
			var word = _v4.bC;
			var clickableConf = function () {
				var _v2 = _Utils_Tuple2(
					A2($elm$core$Dict$get, word, wordIds),
					context);
				if ((!_v2.a.$) && (!_v2.b)) {
					var ids = _v2.a.a;
					var _v3 = _v2.b;
					var idInCore = function (id) {
						return A2(
							$elm$core$List$member,
							id,
							$elm$core$Dict$keys(core));
					};
					var defaultStyle = A2($elm$html$Html$Attributes$style, 'text-decoration', 'underline');
					return A2(
						$elm$core$List$cons,
						defaultStyle,
						A2($elm$core$List$all, idInCore, ids) ? _List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-decoration-style', 'dotted')
							]) : _List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$App$Wiktionary$Messages$ClickedRelationshipWord(word))
							]));
				} else {
					return _List_Nil;
				}
			}();
			var basicConf = _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$body2,
					A2($elm$html$Html$Attributes$style, 'word-wrap', 'normal'),
					A2($elm$html$Html$Attributes$style, 'white-space', 'normal')
				]);
			var combinedConf = $elm$core$List$concat(
				_List_fromArray(
					[basicConf, clickableConf]));
			return A2(
				$elm$html$Html$span,
				combinedConf,
				_List_fromArray(
					[
						$elm$html$Html$text(word)
					]));
		};
		var separator = A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(', ')
				]));
		var filterFnc = function (_v1) {
			var word = _v1.bC;
			return word !== ';';
		};
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					title,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-left', '10px')
						]),
					A2(
						$elm$core$List$intersperse,
						separator,
						A2(
							$elm$core$List$map,
							viewRel,
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.bC;
								},
								A2($elm$core$List$filter, filterFnc, rels)))))
				]));
	});
var $author$project$App$Wiktionary$View$viewSenseSubTitle = function (title) {
	var spanConfig = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'z-index', '1'),
			A2($elm$html$Html$Attributes$style, 'top', '0'),
			A2($elm$html$Html$Attributes$style, 'left', '-19px'),
			A2($elm$html$Html$Attributes$style, 'margin-top', '-5px'),
			A2($elm$html$Html$Attributes$style, 'display', 'flex'),
			A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
			A2($elm$html$Html$Attributes$style, 'background', 'white'),
			A2($elm$html$Html$Attributes$style, 'display', 'block'),
			A2($elm$html$Html$Attributes$style, 'font-size', '7px')
		]);
	var divConfig = _List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$overline,
			A2($elm$html$Html$Attributes$style, 'font-weight', '100'),
			A2($elm$html$Html$Attributes$style, 'position', 'relative')
		]);
	return A2(
		$elm$html$Html$div,
		divConfig,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				spanConfig,
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]))
			]));
};
var $author$project$App$Wiktionary$View$viewBody = F2(
	function (model, rec) {
		var viewRel = function (t) {
			return A2(
				$author$project$App$Wiktionary$View$viewRelationships,
				model,
				$author$project$App$Wiktionary$View$viewSenseSubTitle(t));
		};
		var makeItemBase = function (elems) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'list-style-type', 'decimal'),
						A2($elm$html$Html$Attributes$style, 'font-size', '10px'),
						$aforemny$material_components_web_elm$Material$Typography$caption
					]),
				_List_fromArray(
					[
						A2($elm$html$Html$div, _List_Nil, elems)
					]));
		};
		var makeItem = function (s) {
			return makeItemBase(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$author$project$App$Wiktionary$View$viewGloss(s),
							A2($elm$core$Maybe$map, $author$project$App$Wiktionary$View$viewExamples, s.f8),
							A2(
							$elm$core$Maybe$map,
							viewRel('Syn'),
							s.eQ),
							A2(
							$elm$core$Maybe$map,
							viewRel('Ant'),
							s.dh)
						])));
		};
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-left', '20px')
				]),
			A2($elm$core$List$map, makeItem, rec.hA));
	});
var $author$project$App$Wiktionary$Messages$ClickedShowMore = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Wiktionary$View$viewButton = F2(
	function (showMore, wid) {
		var label = A2($author$project$App$Wiktionary$Update$isShowMore, showMore, wid) ? 'Show less' : 'Show more';
		var doOnClick = $author$project$App$Wiktionary$Messages$ClickedShowMore(wid);
		var config = A2($aforemny$material_components_web_elm$Material$Button$setOnClick, doOnClick, $aforemny$material_components_web_elm$Material$Button$config);
		return A2($aforemny$material_components_web_elm$Material$Card$button, config, label);
	});
var $author$project$App$Wiktionary$Messages$ClickedAddEntity = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Wiktionary$Messages$ClickedMarkAsReviewed = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Wiktionary$View$viewIcon = F3(
	function (cntxt, id, status) {
		var onClick = function () {
			if (!cntxt) {
				return $author$project$App$Wiktionary$Messages$ClickedAddEntity;
			} else {
				return $author$project$App$Wiktionary$Messages$ClickedMarkAsReviewed;
			}
		}();
		var isDisabled = function () {
			var _v1 = _Utils_Tuple2(cntxt, status);
			_v1$2:
			while (true) {
				if (!_v1.a) {
					if ((!_v1.b.$) && (!_v1.b.a)) {
						var _v2 = _v1.a;
						var _v3 = _v1.b.a;
						return true;
					} else {
						break _v1$2;
					}
				} else {
					if ((!_v1.b.$) && (_v1.b.a === 1)) {
						var _v4 = _v1.a;
						var _v5 = _v1.b.a;
						return true;
					} else {
						break _v1$2;
					}
				}
			}
			return false;
		}();
		var icon = $aforemny$material_components_web_elm$Material$IconButton$icon(
			function () {
				if (!cntxt) {
					return _Utils_eq(
						status,
						$elm$core$Maybe$Just(0)) ? 'star' : 'star_border';
				} else {
					return _Utils_eq(
						status,
						$elm$core$Maybe$Just(1)) ? 'check_circle_outline' : 'radio_button_unchecked';
				}
			}());
		var config = A2(
			$aforemny$material_components_web_elm$Material$IconButton$setDisabled,
			isDisabled,
			A2(
				$aforemny$material_components_web_elm$Material$IconButton$setOnClick,
				onClick(id),
				$aforemny$material_components_web_elm$Material$IconButton$config));
		return A2($aforemny$material_components_web_elm$Material$Card$icon, config, icon);
	});
var $elm$core$String$append = _String_append;
var $author$project$App$Wiktionary$View$formatAdjDeps = function (_v0) {
	var comparative = _v0.fy;
	var superlative = _v0.hJ;
	var op = $elm$core$Maybe$map(
		$elm$core$String$append('| '));
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					op(comparative),
					op(superlative)
				])));
};
var $author$project$Models$Wikt$formatGenders = function (genders) {
	var formatGender = function (g) {
		switch (g) {
			case 0:
				return 'f';
			case 1:
				return 'm';
			default:
				return 'n';
		}
	};
	return A2(
		$author$project$Util$brackets,
		'{',
		A2(
			$elm$core$String$join,
			',',
			A2($elm$core$List$map, formatGender, genders)));
};
var $author$project$Models$Wikt$formatPlurals = function (plurals) {
	return A2($elm$core$String$join, ' or ', plurals);
};
var $author$project$App$Wiktionary$View$formatNounDeps = function (_v0) {
	var gender = _v0.dP;
	var plural = _v0.g7;
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2($elm$core$Maybe$map, $author$project$Models$Wikt$formatGenders, gender),
					A2($elm$core$Maybe$map, $author$project$Models$Wikt$formatPlurals, plural)
				])));
};
var $author$project$Models$Wikt$formatVerbCat = function (cat) {
	switch (cat) {
		case 0:
			return 'weak';
		case 1:
			return 'strong';
		default:
			return 'irregular';
	}
};
var $author$project$Models$Wikt$formatVerbCats = function (cats) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $author$project$Models$Wikt$formatVerbCat, cats));
};
var $author$project$App$Wiktionary$View$formatVerbDeps = function (_v0) {
	var category = _v0.fu;
	var present3S = _v0.g9;
	var preterite = _v0.ha;
	var pastParticiple = _v0.g6;
	var auxiliaryVerb = _v0.fi;
	var cond = function (s) {
		return $elm$core$List$length(
			A2($elm$core$String$split, ' ', s)) <= 2;
	};
	var conjParts = A2(
		$elm$core$List$filter,
		cond,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$List$head(present3S),
					$elm$core$List$head(preterite),
					$elm$core$List$head(pastParticiple)
				])));
	var conjStr = ($elm$core$List$length(conjParts) > 0) ? $elm$core$Maybe$Just(
		A2($elm$core$String$join, ', ', conjParts)) : $elm$core$Maybe$Nothing;
	var catStr = A2($elm$core$Maybe$map, $author$project$Models$Wikt$formatVerbCats, category);
	var catConjSep = function () {
		var _v1 = _Utils_Tuple2(catStr, conjStr);
		if ((!_v1.a.$) && (!_v1.b.$)) {
			return $elm$core$Maybe$Just(': ');
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var auxStr = ($elm$core$List$length(auxiliaryVerb) > 0) ? $elm$core$Maybe$Just(
		A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'mit',
					A2($elm$core$String$join, '/', auxiliaryVerb)
				]))) : $elm$core$Maybe$Nothing;
	return A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$map,
					$author$project$Util$brackets('('),
					auxStr),
					$elm$core$Maybe$Just(' '),
					catStr,
					catConjSep,
					conjStr
				])));
};
var $author$project$App$Wiktionary$View$formatHeadline = function (wdep) {
	if (!wdep.$) {
		switch (wdep.a.$) {
			case 0:
				var deps = wdep.a.a;
				return $author$project$App$Wiktionary$View$formatNounDeps(deps);
			case 1:
				var deps = wdep.a.a;
				return $author$project$App$Wiktionary$View$formatVerbDeps(deps);
			default:
				var deps = wdep.a.a;
				return $author$project$App$Wiktionary$View$formatAdjDeps(deps);
		}
	} else {
		return '';
	}
};
var $author$project$Models$Entity$gendersToColor = function (gs) {
	var colorMapping = function (g) {
		switch (g) {
			case 0:
				return 'rgb(254, 244, 168)';
			case 1:
				return 'rgb(235, 193, 84)';
			default:
				return 'rgb(175, 212, 171)';
		}
	};
	if (gs.b && (!gs.b.b)) {
		var g = gs.a;
		return colorMapping(g);
	} else {
		return 'white';
	}
};
var $author$project$App$Wiktionary$View$wordBgColor = function (dep) {
	if ((!dep.$) && (!dep.a.$)) {
		var gender = dep.a.a.dP;
		return A2(
			$elm$core$Maybe$withDefault,
			'transparent',
			A2($elm$core$Maybe$map, $author$project$Models$Entity$gendersToColor, gender));
	} else {
		return 'transparent';
	}
};
var $author$project$App$Wiktionary$View$viewTitle = function (_v0) {
	var rec = _v0.hh;
	var dep = _v0.fO;
	var main = A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'background-color',
				$author$project$App$Wiktionary$View$wordBgColor(dep)),
				A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
				$aforemny$material_components_web_elm$Material$Typography$body1,
				A2($elm$html$Html$Attributes$style, 'margin-right', '5px'),
				A2($elm$html$Html$Attributes$style, 'font-weight', '500')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(rec.bC)
			]));
	var headline = A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$body1,
				A2($elm$html$Html$Attributes$style, 'color', '#a0a0a0'),
				A2($elm$html$Html$Attributes$style, 'font-weight', '400')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$App$Wiktionary$View$formatHeadline(dep))
			]));
	var content = A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'relative'),
				A2($elm$html$Html$Attributes$style, 'z-index', '2')
			]),
		_List_fromArray(
			[main, headline]));
	return content;
};
var $author$project$App$Wiktionary$View$viewCard = F2(
	function (model, rds) {
		var makeTitle = function (t) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Typography$body1,
						A2($elm$html$Html$Attributes$style, 'font-size', '14px'),
						A2($elm$html$Html$Attributes$style, 'margin-right', '5px'),
						A2($elm$html$Html$Attributes$style, 'font-weight', '300')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('[ ' + (t + ' ]'))
					]));
		};
		var viewRel = function (t) {
			return A2(
				$author$project$App$Wiktionary$View$viewRelationships,
				model,
				makeTitle(t));
		};
		var makeAction = function (i) {
			return $aforemny$material_components_web_elm$Material$Card$actions(
				{
					fs: _List_Nil,
					gs: _List_fromArray(
						[i])
				});
		};
		var icon = (!$author$project$App$Wiktionary$View$hideButton(rds.hh)) ? $elm$core$Maybe$Just(
			A3($author$project$App$Wiktionary$View$viewIcon, model.dp, rds.hh.gt, rds.eM)) : $elm$core$Maybe$Nothing;
		var content = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '0px')
				]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$core$Maybe$Just(
						$author$project$App$Wiktionary$View$viewTitle(rds)),
						$elm$core$Maybe$Just(
						A2($author$project$App$Wiktionary$View$viewBody, model, rds.hh)),
						A2(
						$elm$core$Maybe$map,
						viewRel('Synonyms'),
						rds.hh.eQ),
						A2(
						$elm$core$Maybe$map,
						viewRel('Antonyms'),
						rds.hh.dh),
						A2(
						$elm$core$Maybe$map,
						viewRel('Related'),
						rds.hh.hl),
						A2(
						$elm$core$Maybe$map,
						viewRel('Derived'),
						rds.hh.fS)
					])));
		var config = A2(
			$aforemny$material_components_web_elm$Material$Card$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '5px'),
					A2($elm$html$Html$Attributes$style, 'padding-right', '10px'),
					$elm$html$Html$Attributes$id(
					$elm$core$String$fromInt(rds.hh.gt))
				]),
			A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
		var button = A2($author$project$App$Wiktionary$View$viewButton, model.eI, rds.hh.gt);
		return A2(
			$aforemny$material_components_web_elm$Material$Card$card,
			config,
			{
				e8: A2($elm$core$Maybe$map, makeAction, icon),
				fn: _Utils_Tuple2(
					$aforemny$material_components_web_elm$Material$Card$block(content),
					_List_Nil)
			});
	});
var $author$project$Models$Wikt$formatPoS = function (pos) {
	switch (pos) {
		case 0:
			return 'noun';
		case 1:
			return 'verb';
		case 2:
			return 'adj';
		case 3:
			return 'adv';
		case 4:
			return 'name';
		case 5:
			return 'phrase';
		case 6:
			return 'num';
		case 7:
			return 'pron';
		case 8:
			return 'intj';
		case 9:
			return 'suffix';
		case 10:
			return 'prefix';
		case 11:
			return 'proverb';
		case 12:
			return 'prep';
		case 20:
			return 'postp';
		case 13:
			return 'det';
		case 14:
			return 'conj';
		case 15:
			return 'prep-phrase';
		case 16:
			return 'contradiction';
		case 17:
			return 'character';
		case 18:
			return 'symbol';
		case 19:
			return 'art';
		case 21:
			return 'particle';
		case 22:
			return 'interfix';
		case 23:
			return 'circumfix';
		case 24:
			return 'circumpos';
		case 25:
			return 'punct';
		default:
			return 'infix';
	}
};
var $author$project$App$Wiktionary$View$viewPos = F2(
	function (c, p) {
		var spanConfig = function () {
			if (!c) {
				return _List_Nil;
			} else {
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'z-index', '1'),
						A2($elm$html$Html$Attributes$style, 'top', '0'),
						A2($elm$html$Html$Attributes$style, 'right', '-15px'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '10px'),
						A2($elm$html$Html$Attributes$style, 'writing-mode', 'vertical-lr'),
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
						A2($elm$html$Html$Attributes$style, 'background', 'white'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						A2($elm$html$Html$Attributes$style, 'font-size', '8px')
					]);
			}
		}();
		var divConfig = _List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$overline,
				A2($elm$html$Html$Attributes$style, 'font-weight', '100'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]);
		return A2(
			$elm$html$Html$div,
			divConfig,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					spanConfig,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Models$Wikt$formatPoS(p))
						]))
				]));
	});
var $author$project$App$Wiktionary$View$view = function (model) {
	var context = model.dp;
	var core = model.dq;
	var selectedTags = model.eG;
	var viewMaybePoSAndCard = function (_v3) {
		var maybePos = _v3.a;
		var rds = _v3.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-bottom', '10px')
				]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						$author$project$App$Wiktionary$View$viewPos(context),
						maybePos),
						$elm$core$Maybe$Just(
						A2($author$project$App$Wiktionary$View$viewCard, model, rds))
					])));
	};
	var sortFn = function (_v2) {
		var rec = _v2.hh;
		var _v0 = _Utils_Tuple2(context, rec.fb);
		if ((_v0.a === 1) && (!_v0.b.$)) {
			var _v1 = _v0.a;
			var t = _v0.b.a;
			return t;
		} else {
			return rec.gt;
		}
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			viewMaybePoSAndCard,
			A2(
				$author$project$App$Wiktionary$View$makePosedPairs,
				context,
				A2(
					$elm$core$List$sortBy,
					sortFn,
					$elm$core$Dict$values(core)))));
};
var $author$project$App$Entities$Messages$Reload = {$: 1};
var $author$project$App$Entities$View$viewRefresh = function (model) {
	var icon = $aforemny$material_components_web_elm$Material$IconButton$icon('refresh');
	var disabled = !$elm$core$Set$size(
		$author$project$App$Entities$Update$getReviewedIds(model));
	var config = A2(
		$aforemny$material_components_web_elm$Material$IconButton$setDisabled,
		disabled,
		A2($aforemny$material_components_web_elm$Material$IconButton$setOnClick, $author$project$App$Entities$Messages$Reload, $aforemny$material_components_web_elm$Material$IconButton$config));
	return A2($aforemny$material_components_web_elm$Material$IconButton$iconButton, config, icon);
};
var $author$project$App$Entities$View$viewBottomControl = function (model) {
	var content = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', 'auto'),
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(
							$author$project$App$Entities$View$getCountAvailable(model))),
						$elm$html$Html$text(' / '),
						$elm$html$Html$text(
						$elm$core$String$fromInt(
							$author$project$App$Entities$View$getCountAll(model)))
					])),
				$author$project$App$Entities$View$viewRefresh(model)
			]));
	var config = A2(
		$aforemny$material_components_web_elm$Material$Card$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '5px'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '15px'),
				A2($elm$html$Html$Attributes$style, 'border-color', 'transparent'),
				A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
				$aforemny$material_components_web_elm$Material$Typography$caption
			]),
		A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		config,
		{
			e8: $elm$core$Maybe$Nothing,
			fn: _Utils_Tuple2(
				$aforemny$material_components_web_elm$Material$Card$block(content),
				_List_Nil)
		});
};
var $author$project$App$Entities$View$view = function (model) {
	var makeLayout = function (inners) {
		return A2(
			$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'max-width', '480px'),
					A2($elm$html$Html$Attributes$style, 'padding-bottom', '70px')
				]),
			inners);
	};
	var makeInner = function (c) {
		return A2(
			$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
					_List_fromArray(
						[c]))
				]));
	};
	var contentMain = makeInner(
		A2(
			$elm$html$Html$map,
			$author$project$App$Entities$Messages$WiktComponentMsg,
			$author$project$App$Wiktionary$View$view(model)));
	var content = A2(
		$elm$core$List$cons,
		contentMain,
		(_Utils_cmp(
			$author$project$App$Entities$View$getCountAll(model),
			$author$project$App$Entities$View$getCountAvailable(model)) > 0) ? _List_fromArray(
			[
				$author$project$App$Entities$View$viewBottomControl(model)
			]) : _List_Nil);
	return makeLayout(content);
};
var $terezka$elm_charts$Chart$Attributes$border = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{C: v});
	});
var $terezka$elm_charts$Internal$Coordinates$center = function (pos) {
	return {ii: pos.ij + ((pos.ik - pos.ij) / 2), e5: pos.im + ((pos.$9 - pos.im) / 2)};
};
var $terezka$elm_charts$Internal$Coordinates$pointToPosition = function (point) {
	return {ij: point.ii, ik: point.ii, im: point.e5, $9: point.e5};
};
var $terezka$elm_charts$Chart$Attributes$center = function (config) {
	return _Utils_update(
		config,
		{
			ge: $elm$core$Maybe$Just(
				A2($elm$core$Basics$composeR, $terezka$elm_charts$Internal$Coordinates$center, $terezka$elm_charts$Internal$Coordinates$pointToPosition))
		});
};
var $terezka$elm_charts$Internal$Svg$Event = F2(
	function (name, handler) {
		return {dT: handler, gR: name};
	});
var $terezka$elm_charts$Internal$Helpers$apply = F2(
	function (funcs, _default) {
		var apply_ = F2(
			function (f, a) {
				return f(a);
			});
		return A3($elm$core$List$foldl, apply_, _default, funcs);
	});
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $debois$elm_dom$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $debois$elm_dom$DOM$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $debois$elm_dom$DOM$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$debois$elm_dom$DOM$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($debois$elm_dom$DOM$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeftP, scrollTopP, offsetLeftP, offsetTopP) {
						return _Utils_Tuple2((x + offsetLeftP) - scrollLeftP, (y + offsetTopP) - scrollTopP);
					}),
				$debois$elm_dom$DOM$scrollLeft,
				$debois$elm_dom$DOM$scrollTop,
				$debois$elm_dom$DOM$offsetLeft,
				$debois$elm_dom$DOM$offsetTop));
	});
var $debois$elm_dom$DOM$boundingClientRect = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {gl: height, gK: x, h1: y, e1: width};
		}),
	A2($debois$elm_dom$DOM$position, 0, 0),
	$debois$elm_dom$DOM$offsetWidth,
	$debois$elm_dom$DOM$offsetHeight);
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $debois$elm_dom$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
function $terezka$elm_charts$Internal$Svg$cyclic$decodePosition() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$debois$elm_dom$DOM$boundingClientRect,
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $debois$elm_dom$DOM$parentElement(
						$terezka$elm_charts$Internal$Svg$cyclic$decodePosition());
				})
			]));
}
var $terezka$elm_charts$Internal$Svg$decodePosition = $terezka$elm_charts$Internal$Svg$cyclic$decodePosition();
$terezka$elm_charts$Internal$Svg$cyclic$decodePosition = function () {
	return $terezka$elm_charts$Internal$Svg$decodePosition;
};
var $terezka$elm_charts$Internal$Coordinates$innerLength = function (axis) {
	return A2($elm$core$Basics$max, 1, (axis.q - axis.gO) - axis.gN);
};
var $terezka$elm_charts$Internal$Coordinates$innerWidth = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.ii);
};
var $terezka$elm_charts$Internal$Coordinates$range = function (axis) {
	var diff = axis.ao - axis.aq;
	return (diff > 0) ? diff : 1;
};
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.ii)) / $terezka$elm_charts$Internal$Coordinates$innerWidth(plane);
	});
var $terezka$elm_charts$Internal$Coordinates$toCartesianX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, value - plane.ii.gO) + plane.ii.aq;
	});
var $terezka$elm_charts$Internal$Coordinates$innerHeight = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.e5);
};
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.e5)) / $terezka$elm_charts$Internal$Coordinates$innerHeight(plane);
	});
var $terezka$elm_charts$Internal$Coordinates$toCartesianY = F2(
	function (plane, value) {
		return ($terezka$elm_charts$Internal$Coordinates$range(plane.e5) - A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, value - plane.e5.gO)) + plane.e5.aq;
	});
var $terezka$elm_charts$Internal$Svg$fromSvg = F2(
	function (plane, point) {
		return {
			ii: A2($terezka$elm_charts$Internal$Coordinates$toCartesianX, plane, point.ii),
			e5: A2($terezka$elm_charts$Internal$Coordinates$toCartesianY, plane, point.e5)
		};
	});
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $terezka$elm_charts$Internal$Svg$decoder = F2(
	function (plane, toMsg) {
		var handle = F3(
			function (mouseX, mouseY, box) {
				var yPrev = plane.e5;
				var xPrev = plane.ii;
				var widthPercent = box.e1 / plane.ii.q;
				var heightPercent = box.gl / plane.e5.q;
				var newPlane = _Utils_update(
					plane,
					{
						ii: _Utils_update(
							xPrev,
							{q: box.e1, gN: plane.ii.gN * widthPercent, gO: plane.ii.gO * widthPercent}),
						e5: _Utils_update(
							yPrev,
							{q: box.gl, gN: plane.e5.gN * heightPercent, gO: plane.e5.gO * heightPercent})
					});
				var searched = A2(
					$terezka$elm_charts$Internal$Svg$fromSvg,
					newPlane,
					{ii: mouseX - box.gK, e5: mouseY - box.h1});
				return A2(toMsg, newPlane, searched);
			});
		return A4(
			$elm$json$Json$Decode$map3,
			handle,
			A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float),
			$debois$elm_dom$DOM$target($terezka$elm_charts$Internal$Svg$decodePosition));
	});
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Events$on = $elm$html$Html$Events$on;
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $terezka$elm_charts$Internal$Coordinates$toId = function (plane) {
	var numToStr = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromFloat,
		A2($elm$core$String$replace, '.', '-'));
	return A2(
		$elm$core$String$join,
		'_',
		_List_fromArray(
			[
				'elm-charts__id',
				numToStr(plane.ii.q),
				numToStr(plane.ii.aq),
				numToStr(plane.ii.ao),
				numToStr(plane.ii.gO),
				numToStr(plane.ii.gN),
				numToStr(plane.e5.q),
				numToStr(plane.e5.aq),
				numToStr(plane.e5.ao),
				numToStr(plane.e5.gO),
				numToStr(plane.e5.gN)
			]));
};
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $terezka$elm_charts$Internal$Svg$container = F5(
	function (plane, config, below, chartEls, above) {
		var toEvent = function (event) {
			return A2(
				$elm$svg$Svg$Events$on,
				event.gR,
				A2($terezka$elm_charts$Internal$Svg$decoder, plane, event.dT));
		};
		var svgAttrsSize = config.bp ? _List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox(
				'0 0 ' + ($elm$core$String$fromFloat(plane.ii.q) + (' ' + $elm$core$String$fromFloat(plane.e5.q)))),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]) : _List_fromArray(
			[
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(plane.ii.q)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(plane.e5.q)),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]);
		var htmlAttrsSize = config.bp ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%')
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'width',
				$elm$core$String$fromFloat(plane.ii.q) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'height',
				$elm$core$String$fromFloat(plane.e5.q) + 'px')
			]);
		var htmlAttrsDef = _List_fromArray(
			[
				$elm$html$Html$Attributes$class('elm-charts__container-inner')
			]);
		var htmlAttrs = _Utils_ap(
			config.a9,
			_Utils_ap(htmlAttrsDef, htmlAttrsSize));
		var chartPosition = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(plane.ii.gO)),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(plane.e5.gO)),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerWidth(plane))),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerHeight(plane))),
				$elm$svg$Svg$Attributes$fill('transparent')
			]);
		var clipPathDefs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id(
							$terezka$elm_charts$Internal$Coordinates$toId(plane))
						]),
					_List_fromArray(
						[
							A2($elm$svg$Svg$rect, chartPosition, _List_Nil)
						]))
				]));
		var catcher = A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				chartPosition,
				A2($elm$core$List$map, toEvent, config.f6)),
			_List_Nil);
		var chart = A2(
			$elm$svg$Svg$svg,
			_Utils_ap(svgAttrsSize, config.g),
			_Utils_ap(
				_List_fromArray(
					[clipPathDefs]),
				_Utils_ap(
					chartEls,
					_List_fromArray(
						[catcher]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('elm-charts__container'),
					A2($elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					htmlAttrs,
					_Utils_ap(
						below,
						_Utils_ap(
							_List_fromArray(
								[chart]),
							above)))
				]));
	});
var $terezka$elm_charts$Internal$Coordinates$Position = F4(
	function (x1, x2, y1, y2) {
		return {ij: x1, ik: x2, im: y1, $9: y2};
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $terezka$elm_charts$Internal$Coordinates$foldPosition = F2(
	function (func, data) {
		var fold = F2(
			function (datum, posM) {
				if (!posM.$) {
					var pos = posM.a;
					return $elm$core$Maybe$Just(
						{
							ij: A2(
								$elm$core$Basics$min,
								func(datum).ij,
								pos.ij),
							ik: A2(
								$elm$core$Basics$max,
								func(datum).ik,
								pos.ik),
							im: A2(
								$elm$core$Basics$min,
								func(datum).im,
								pos.im),
							$9: A2(
								$elm$core$Basics$max,
								func(datum).$9,
								pos.$9)
						});
				} else {
					return $elm$core$Maybe$Just(
						func(datum));
				}
			});
		return A2(
			$elm$core$Maybe$withDefault,
			A4($terezka$elm_charts$Internal$Coordinates$Position, 0, 0, 0, 0),
			A3($elm$core$List$foldl, fold, $elm$core$Maybe$Nothing, data));
	});
var $terezka$elm_charts$Chart$Attributes$lowest = F3(
	function (v, edit, b) {
		return _Utils_update(
			b,
			{
				aq: A3(edit, v, b.aq, b.fK)
			});
	});
var $terezka$elm_charts$Chart$Attributes$orLower = F3(
	function (least, real, _v0) {
		return (_Utils_cmp(real, least) > 0) ? least : real;
	});
var $terezka$elm_charts$Chart$definePlane = F2(
	function (config, elements) {
		var width = A2($elm$core$Basics$max, 1, (config.e1 - config.Y.gK) - config.Y.hq);
		var toLimit = F5(
			function (length, marginMin, marginMax, min, max) {
				return {fJ: max, fK: min, q: length, gN: marginMax, gO: marginMin, ao: max, aq: min};
			});
		var height = A2($elm$core$Basics$max, 1, (config.gl - config.Y.fo) - config.Y.h1);
		var fixSingles = function (bs) {
			return _Utils_eq(bs.aq, bs.ao) ? _Utils_update(
				bs,
				{ao: bs.aq + 10}) : bs;
		};
		var collectLimits = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 2:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 3:
						return acc;
					case 4:
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, collectLimits, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		var limits_ = function (pos) {
			return function (_v3) {
				var x = _v3.ii;
				var y = _v3.e5;
				return {
					ii: fixSingles(x),
					e5: fixSingles(y)
				};
			}(
				{
					ii: A5(toLimit, width, config.an.gK, config.an.hq, pos.ij, pos.ik),
					e5: A5(toLimit, height, config.an.h1, config.an.fo, pos.im, pos.$9)
				});
		}(
			A2(
				$terezka$elm_charts$Internal$Coordinates$foldPosition,
				$elm$core$Basics$identity,
				A3($elm$core$List$foldl, collectLimits, _List_Nil, elements)));
		var calcRange = function () {
			var _v2 = config.cJ;
			if (!_v2.b) {
				return limits_.ii;
			} else {
				var some = _v2;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (f, b) {
							return f(b);
						}),
					limits_.ii,
					some);
			}
		}();
		var calcDomain = function () {
			var _v1 = config.bT;
			if (!_v1.b) {
				return A3($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$orLower, limits_.e5);
			} else {
				var some = _v1;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (f, b) {
							return f(b);
						}),
					limits_.e5,
					some);
			}
		}();
		var unpadded = {ii: calcRange, e5: calcDomain};
		var scalePadX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX(unpadded);
		var xMax = calcRange.ao + scalePadX(config.Y.hq);
		var xMin = calcRange.aq - scalePadX(config.Y.gK);
		var scalePadY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY(unpadded);
		var yMax = calcDomain.ao + scalePadY(config.Y.h1);
		var yMin = calcDomain.aq - scalePadY(config.Y.fo);
		return {
			ii: _Utils_update(
				calcRange,
				{
					q: config.e1,
					ao: A2($elm$core$Basics$max, xMin, xMax),
					aq: A2($elm$core$Basics$min, xMin, xMax)
				}),
			e5: _Utils_update(
				calcDomain,
				{
					q: config.gl,
					ao: A2($elm$core$Basics$max, yMin, yMax),
					aq: A2($elm$core$Basics$min, yMin, yMax)
				})
		};
	});
var $terezka$elm_charts$Chart$getItems = F2(
	function (plane, elements) {
		var toItems = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 2:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 3:
						var item = el.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[item]));
					case 4:
						var func = el.a;
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toItems, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3($elm$core$List$foldl, toItems, _List_Nil, elements);
	});
var $terezka$elm_charts$Chart$getLegends = function (elements) {
	var toLegends = F2(
		function (el, acc) {
			switch (el.$) {
				case 0:
					return acc;
				case 1:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 2:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 3:
					return acc;
				case 4:
					return acc;
				case 5:
					return acc;
				case 6:
					return acc;
				case 7:
					return acc;
				case 8:
					return acc;
				case 9:
					return acc;
				case 10:
					return acc;
				case 11:
					var subs = el.a;
					return A3($elm$core$List$foldl, toLegends, acc, subs);
				case 12:
					return acc;
				default:
					return acc;
			}
		});
	return A3($elm$core$List$foldl, toLegends, _List_Nil, elements);
};
var $terezka$elm_charts$Chart$TickValues = F4(
	function (xAxis, yAxis, xs, ys) {
		return {aW: xAxis, H: xs, aX: yAxis, P: ys};
	});
var $terezka$elm_charts$Chart$getTickValues = F3(
	function (plane, items, elements) {
		var toValues = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						return acc;
					case 2:
						var func = el.d;
						return A2(func, plane, acc);
					case 3:
						var func = el.b;
						return acc;
					case 4:
						var func = el.a;
						return A2(func, plane, acc);
					case 5:
						var func = el.a;
						return A2(func, plane, acc);
					case 6:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 7:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 8:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldl,
							toValues,
							acc,
							A2(func, plane, items));
					case 9:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toValues, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3(
			$elm$core$List$foldl,
			toValues,
			A4($terezka$elm_charts$Chart$TickValues, _List_Nil, _List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$GridElement = function (a) {
	return {$: 9, a: a};
};
var $terezka$elm_charts$Internal$Svg$Circle = 0;
var $terezka$elm_charts$Chart$Attributes$circle = function (config) {
	return _Utils_update(
		config,
		{
			aB: $elm$core$Maybe$Just(0)
		});
};
var $terezka$elm_charts$Chart$Attributes$color = F2(
	function (v, config) {
		return (v === '') ? config : _Utils_update(
			config,
			{fx: v});
	});
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $terezka$elm_charts$Internal$Helpers$darkGray = 'rgb(200 200 200)';
var $terezka$elm_charts$Chart$Attributes$dashed = F2(
	function (value, config) {
		return _Utils_update(
			config,
			{a$: value});
	});
var $terezka$elm_charts$Internal$Helpers$pink = '#ea60df';
var $terezka$elm_charts$Internal$Svg$defaultDot = {C: '', E: 0, fx: $terezka$elm_charts$Internal$Helpers$pink, p: false, gn: 0, go: '', gp: 5, W: 1, aB: $elm$core$Maybe$Nothing, O: 6};
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fillOpacity = _VirtualDom_attribute('fill-opacity');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $terezka$elm_charts$Internal$Svg$isWithinPlane = F3(
	function (plane, x, y) {
		return _Utils_eq(
			A3($elm$core$Basics$clamp, plane.ii.aq, plane.ii.ao, x),
			x) && _Utils_eq(
			A3($elm$core$Basics$clamp, plane.e5.aq, plane.e5.ao, y),
			y);
	});
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $terezka$elm_charts$Internal$Svg$plusPath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(area_ / 4) + off;
		var r6 = side / 2;
		var r3 = side;
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_ - r6) + (' ' + $elm$core$String$fromFloat(((y_ - r3) - r6) + off))),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat(r3),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat(r3),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(-r3),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(-r3),
					'v' + $elm$core$String$fromFloat(r3 - off)
				]));
	});
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeOpacity = _VirtualDom_attribute('stroke-opacity');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $terezka$elm_charts$Internal$Coordinates$scaleSVGX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerWidth(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.ii);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGX, plane, value - plane.ii.aq) + plane.ii.gO;
	});
var $terezka$elm_charts$Internal$Coordinates$scaleSVGY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerHeight(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.e5);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGY = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGY, plane, plane.e5.ao - value) + plane.e5.gO;
	});
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$tan = _Basics_tan;
var $terezka$elm_charts$Internal$Svg$trianglePath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(
			(area_ * 4) / $elm$core$Basics$sqrt(3)) + (off * $elm$core$Basics$sqrt(3));
		var height = ($elm$core$Basics$sqrt(3) * side) / 2;
		var fromMiddle = height - (($elm$core$Basics$tan(
			$elm$core$Basics$degrees(30)) * side) / 2);
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_) + (' ' + $elm$core$String$fromFloat(y_ - fromMiddle))),
					'l' + ($elm$core$String$fromFloat((-side) / 2) + (' ' + $elm$core$String$fromFloat(height))),
					'h' + $elm$core$String$fromFloat(side),
					'z'
				]));
	});
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $terezka$elm_charts$Internal$Svg$withinChartArea = function (plane) {
	return $elm$svg$Svg$Attributes$clipPath(
		'url(#' + ($terezka$elm_charts$Internal$Coordinates$toId(plane) + ')'));
};
var $terezka$elm_charts$Internal$Svg$dot = F5(
	function (plane, toX, toY, config, datum_) {
		var yOrg = toY(datum_);
		var y_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, yOrg);
		var xOrg = toX(datum_);
		var x_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, xOrg);
		var styleAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(
				(config.C === '') ? config.fx : config.C),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.E)),
				$elm$svg$Svg$Attributes$fillOpacity(
				$elm$core$String$fromFloat(config.W)),
				$elm$svg$Svg$Attributes$fill(config.fx),
				$elm$svg$Svg$Attributes$class('elm-charts__dot'),
				config.p ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
			]);
		var showDot = A3($terezka$elm_charts$Internal$Svg$isWithinPlane, plane, xOrg, yOrg) || config.p;
		var highlightColor = (config.go === '') ? config.fx : config.go;
		var highlightAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(highlightColor),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.gp)),
				$elm$svg$Svg$Attributes$strokeOpacity(
				$elm$core$String$fromFloat(config.gn)),
				$elm$svg$Svg$Attributes$fill('transparent'),
				$elm$svg$Svg$Attributes$class('elm-charts__dot-highlight')
			]);
		var view = F3(
			function (toEl, highlightOff, toAttrs) {
				return (config.gn > 0) ? A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__dot-container')
						]),
					_List_fromArray(
						[
							A2(
							toEl,
							_Utils_ap(
								toAttrs(highlightOff),
								highlightAttrs),
							_List_Nil),
							A2(
							toEl,
							_Utils_ap(
								toAttrs(0),
								styleAttrs),
							_List_Nil)
						])) : A2(
					toEl,
					_Utils_ap(
						toAttrs(0),
						styleAttrs),
					_List_Nil);
			});
		var area_ = (2 * $elm$core$Basics$pi) * config.O;
		if (!showDot) {
			return $elm$svg$Svg$text('');
		} else {
			var _v0 = config.aB;
			if (_v0.$ === 1) {
				return $elm$svg$Svg$text('');
			} else {
				switch (_v0.a) {
					case 0:
						var _v1 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$circle,
							config.gp / 2,
							function (off) {
								var radius = $elm$core$Basics$sqrt(area_ / $elm$core$Basics$pi);
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$cx(
										$elm$core$String$fromFloat(x_)),
										$elm$svg$Svg$Attributes$cy(
										$elm$core$String$fromFloat(y_)),
										$elm$svg$Svg$Attributes$r(
										$elm$core$String$fromFloat(radius + off))
									]);
							});
					case 1:
						var _v2 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gp,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$trianglePath, area_, off, x_, y_))
									]);
							});
					case 2:
						var _v3 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.gp,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff))
									]);
							});
					case 3:
						var _v4 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.gp,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					case 4:
						var _v5 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gp,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					default:
						var _v6 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gp,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_))
									]);
							});
				}
			}
		}
	});
var $terezka$elm_charts$Chart$Svg$dot = F4(
	function (plane, toX, toY, edits) {
		return A4(
			$terezka$elm_charts$Internal$Svg$dot,
			plane,
			toX,
			toY,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultDot));
	});
var $terezka$elm_charts$Internal$Helpers$gray = '#EFF2FA';
var $terezka$elm_charts$Internal$Svg$defaultLine = {g: _List_Nil, fp: false, fx: 'rgb(210, 210, 210)', a$: _List_Nil, i: false, p: false, W: 1, hP: -90, hQ: 0, e1: 1, ij: $elm$core$Maybe$Nothing, ik: $elm$core$Maybe$Nothing, il: $elm$core$Maybe$Nothing, k: 0, im: $elm$core$Maybe$Nothing, $9: $elm$core$Maybe$Nothing, io: $elm$core$Maybe$Nothing, l: 0};
var $terezka$elm_charts$Internal$Commands$Line = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Commands$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$cos = _Basics_cos;
var $terezka$elm_charts$Internal$Commands$joinCommands = function (commands) {
	return A2($elm$core$String$join, ' ', commands);
};
var $terezka$elm_charts$Internal$Commands$stringBoolInt = function (bool) {
	return bool ? '1' : '0';
};
var $terezka$elm_charts$Internal$Commands$stringPoint = function (_v0) {
	var x = _v0.a;
	var y = _v0.b;
	return $elm$core$String$fromFloat(x) + (' ' + $elm$core$String$fromFloat(y));
};
var $terezka$elm_charts$Internal$Commands$stringPoints = function (points) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $terezka$elm_charts$Internal$Commands$stringPoint, points));
};
var $terezka$elm_charts$Internal$Commands$stringCommand = function (command) {
	switch (command.$) {
		case 0:
			var x = command.a;
			var y = command.b;
			return 'M' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 1:
			var x = command.a;
			var y = command.b;
			return 'L' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 2:
			var cx1 = command.a;
			var cy1 = command.b;
			var cx2 = command.c;
			var cy2 = command.d;
			var x = command.e;
			var y = command.f;
			return 'C' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(cx2, cy2),
						_Utils_Tuple2(x, y)
					]));
		case 3:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 4:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 5:
			var x = command.a;
			var y = command.b;
			return 'T' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 6:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var x = command.f;
			var y = command.g;
			return 'A ' + $terezka$elm_charts$Internal$Commands$joinCommands(
				_List_fromArray(
					[
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(rx, ry)),
						$elm$core$String$fromInt(xAxisRotation),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(largeArcFlag),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(sweepFlag),
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(x, y))
					]));
		default:
			return 'Z';
	}
};
var $terezka$elm_charts$Internal$Commands$Arc = F7(
	function (a, b, c, d, e, f, g) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e, f: f, g: g};
	});
var $terezka$elm_charts$Internal$Commands$Close = {$: 7};
var $terezka$elm_charts$Internal$Commands$CubicBeziers = F6(
	function (a, b, c, d, e, f) {
		return {$: 2, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $terezka$elm_charts$Internal$Commands$CubicBeziersShort = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziers = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziersShort = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Commands$translate = F2(
	function (plane, command) {
		switch (command.$) {
			case 0:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Move,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 1:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Line,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 2:
				var cx1 = command.a;
				var cy1 = command.b;
				var cx2 = command.c;
				var cy2 = command.d;
				var x = command.e;
				var y = command.f;
				return A6(
					$terezka$elm_charts$Internal$Commands$CubicBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 3:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$CubicBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 4:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 5:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 6:
				var rx = command.a;
				var ry = command.b;
				var xAxisRotation = command.c;
				var largeArcFlag = command.d;
				var sweepFlag = command.e;
				var x = command.f;
				var y = command.g;
				return A7(
					$terezka$elm_charts$Internal$Commands$Arc,
					rx,
					ry,
					xAxisRotation,
					largeArcFlag,
					sweepFlag,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			default:
				return $terezka$elm_charts$Internal$Commands$Close;
		}
	});
var $terezka$elm_charts$Internal$Commands$description = F2(
	function (plane, commands) {
		return $terezka$elm_charts$Internal$Commands$joinCommands(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$terezka$elm_charts$Internal$Commands$translate(plane),
					$terezka$elm_charts$Internal$Commands$stringCommand),
				commands));
	});
var $terezka$elm_charts$Internal$Svg$lengthInCartesianX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX;
var $terezka$elm_charts$Internal$Svg$lengthInCartesianY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY;
var $elm$core$Basics$sin = _Basics_sin;
var $elm$svg$Svg$Attributes$strokeDasharray = _VirtualDom_attribute('stroke-dasharray');
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $terezka$elm_charts$Internal$Svg$withAttrs = F3(
	function (attrs, toEl, defaultAttrs) {
		return toEl(
			_Utils_ap(
				defaultAttrs,
				A2(
					$elm$core$List$map,
					$elm$html$Html$Attributes$map($elm$core$Basics$never),
					attrs)));
	});
var $terezka$elm_charts$Internal$Svg$line = F2(
	function (plane, config) {
		var angle = $elm$core$Basics$degrees(config.hP);
		var _v0 = function () {
			var _v3 = _Utils_Tuple3(
				_Utils_Tuple2(config.ij, config.ik),
				_Utils_Tuple2(config.im, config.$9),
				_Utils_Tuple2(config.il, config.io));
			if (!_v3.a.a.$) {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v4 = _v3.a;
							var a = _v4.a.a;
							var b = _v4.b.a;
							var _v5 = _v3.b;
							var _v6 = _v5.a;
							var _v7 = _v5.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(plane.e5.aq, plane.e5.aq));
						} else {
							var _v38 = _v3.a;
							var a = _v38.a.a;
							var b = _v38.b.a;
							var _v39 = _v3.b;
							var _v40 = _v39.a;
							var c = _v39.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						}
					} else {
						if (_v3.b.b.$ === 1) {
							var _v41 = _v3.a;
							var a = _v41.a.a;
							var b = _v41.b.a;
							var _v42 = _v3.b;
							var c = _v42.a.a;
							var _v43 = _v42.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						} else {
							return _Utils_Tuple2(
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.ii.aq, config.ij),
									A2($elm$core$Maybe$withDefault, plane.ii.ao, config.ik)),
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.e5.aq, config.im),
									A2($elm$core$Maybe$withDefault, plane.e5.ao, config.$9)));
						}
					}
				} else {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v8 = _v3.a;
							var a = _v8.a.a;
							var _v9 = _v8.b;
							var _v10 = _v3.b;
							var _v11 = _v10.a;
							var _v12 = _v10.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, a),
								_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v51 = _v3.a;
									var a = _v51.a.a;
									var _v52 = _v51.b;
									var _v53 = _v3.b;
									var _v54 = _v53.a;
									var b = _v53.b.a;
									var _v55 = _v3.c;
									var xOff = _v55.a.a;
									var yOff = _v55.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v56 = _v3.a;
									var a = _v56.a.a;
									var _v57 = _v56.b;
									var _v58 = _v3.b;
									var _v59 = _v58.a;
									var b = _v58.b.a;
									var _v60 = _v3.c;
									var xOff = _v60.a.a;
									var _v61 = _v60.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v44 = _v3.a;
									var a = _v44.a.a;
									var _v45 = _v44.b;
									var _v46 = _v3.b;
									var _v47 = _v46.a;
									var b = _v46.b.a;
									var _v48 = _v3.c;
									var _v49 = _v48.a;
									var _v50 = _v48.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ii.ao),
										_Utils_Tuple2(b, b));
								} else {
									var _v62 = _v3.a;
									var a = _v62.a.a;
									var _v63 = _v62.b;
									var _v64 = _v3.b;
									var _v65 = _v64.a;
									var b = _v64.b.a;
									var _v66 = _v3.c;
									var _v67 = _v66.a;
									var yOff = _v66.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v35 = _v3.a;
							var c = _v35.a.a;
							var _v36 = _v35.b;
							var _v37 = _v3.b;
							var a = _v37.a.a;
							var b = _v37.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v75 = _v3.a;
									var a = _v75.a.a;
									var _v76 = _v75.b;
									var _v77 = _v3.b;
									var b = _v77.a.a;
									var _v78 = _v77.b;
									var _v79 = _v3.c;
									var xOff = _v79.a.a;
									var yOff = _v79.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v80 = _v3.a;
									var a = _v80.a.a;
									var _v81 = _v80.b;
									var _v82 = _v3.b;
									var b = _v82.a.a;
									var _v83 = _v82.b;
									var _v84 = _v3.c;
									var xOff = _v84.a.a;
									var _v85 = _v84.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v68 = _v3.a;
									var a = _v68.a.a;
									var _v69 = _v68.b;
									var _v70 = _v3.b;
									var b = _v70.a.a;
									var _v71 = _v70.b;
									var _v72 = _v3.c;
									var _v73 = _v72.a;
									var _v74 = _v72.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ii.ao),
										_Utils_Tuple2(b, b));
								} else {
									var _v86 = _v3.a;
									var a = _v86.a.a;
									var _v87 = _v86.b;
									var _v88 = _v3.b;
									var b = _v88.a.a;
									var _v89 = _v88.b;
									var _v90 = _v3.c;
									var _v91 = _v90.a;
									var yOff = _v90.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				}
			} else {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v13 = _v3.a;
							var _v14 = _v13.a;
							var b = _v13.b.a;
							var _v15 = _v3.b;
							var _v16 = _v15.a;
							var _v17 = _v15.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(b, b),
								_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v99 = _v3.a;
									var _v100 = _v99.a;
									var a = _v99.b.a;
									var _v101 = _v3.b;
									var _v102 = _v101.a;
									var b = _v101.b.a;
									var _v103 = _v3.c;
									var xOff = _v103.a.a;
									var yOff = _v103.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v104 = _v3.a;
									var _v105 = _v104.a;
									var a = _v104.b.a;
									var _v106 = _v3.b;
									var _v107 = _v106.a;
									var b = _v106.b.a;
									var _v108 = _v3.c;
									var xOff = _v108.a.a;
									var _v109 = _v108.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v92 = _v3.a;
									var _v93 = _v92.a;
									var a = _v92.b.a;
									var _v94 = _v3.b;
									var _v95 = _v94.a;
									var b = _v94.b.a;
									var _v96 = _v3.c;
									var _v97 = _v96.a;
									var _v98 = _v96.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ii.ao),
										_Utils_Tuple2(b, b));
								} else {
									var _v110 = _v3.a;
									var _v111 = _v110.a;
									var a = _v110.b.a;
									var _v112 = _v3.b;
									var _v113 = _v112.a;
									var b = _v112.b.a;
									var _v114 = _v3.c;
									var _v115 = _v114.a;
									var yOff = _v114.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v32 = _v3.a;
							var _v33 = _v32.a;
							var c = _v32.b.a;
							var _v34 = _v3.b;
							var a = _v34.a.a;
							var b = _v34.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v123 = _v3.a;
									var _v124 = _v123.a;
									var a = _v123.b.a;
									var _v125 = _v3.b;
									var b = _v125.a.a;
									var _v126 = _v125.b;
									var _v127 = _v3.c;
									var xOff = _v127.a.a;
									var yOff = _v127.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v128 = _v3.a;
									var _v129 = _v128.a;
									var a = _v128.b.a;
									var _v130 = _v3.b;
									var b = _v130.a.a;
									var _v131 = _v130.b;
									var _v132 = _v3.c;
									var xOff = _v132.a.a;
									var _v133 = _v132.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v116 = _v3.a;
									var _v117 = _v116.a;
									var a = _v116.b.a;
									var _v118 = _v3.b;
									var b = _v118.a.a;
									var _v119 = _v118.b;
									var _v120 = _v3.c;
									var _v121 = _v120.a;
									var _v122 = _v120.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ii.ao),
										_Utils_Tuple2(b, b));
								} else {
									var _v134 = _v3.a;
									var _v135 = _v134.a;
									var a = _v134.b.a;
									var _v136 = _v3.b;
									var b = _v136.a.a;
									var _v137 = _v136.b;
									var _v138 = _v3.c;
									var _v139 = _v138.a;
									var yOff = _v138.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				} else {
					if (!_v3.b.a.$) {
						if (!_v3.b.b.$) {
							var _v18 = _v3.a;
							var _v19 = _v18.a;
							var _v20 = _v18.b;
							var _v21 = _v3.b;
							var a = _v21.a.a;
							var b = _v21.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ii.aq, plane.ii.aq),
								_Utils_Tuple2(a, b));
						} else {
							var _v22 = _v3.a;
							var _v23 = _v22.a;
							var _v24 = _v22.b;
							var _v25 = _v3.b;
							var a = _v25.a.a;
							var _v26 = _v25.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
								_Utils_Tuple2(a, a));
						}
					} else {
						if (!_v3.b.b.$) {
							var _v27 = _v3.a;
							var _v28 = _v27.a;
							var _v29 = _v27.b;
							var _v30 = _v3.b;
							var _v31 = _v30.a;
							var b = _v30.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
								_Utils_Tuple2(b, b));
						} else {
							var _v140 = _v3.a;
							var _v141 = _v140.a;
							var _v142 = _v140.b;
							var _v143 = _v3.b;
							var _v144 = _v143.a;
							var _v145 = _v143.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
								_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
						}
					}
				}
			}
		}();
		var _v1 = _v0.a;
		var x1 = _v1.a;
		var x2 = _v1.b;
		var _v2 = _v0.b;
		var y1 = _v2.a;
		var y2 = _v2.b;
		var x1_ = x1 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.k);
		var x2_ = x2 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.k);
		var y1_ = y1 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.l);
		var y2_ = y2 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.l);
		var _v146 = (config.hQ > 0) ? _Utils_Tuple2(
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianX,
				plane,
				$elm$core$Basics$cos(angle) * config.hQ),
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianY,
				plane,
				$elm$core$Basics$sin(angle) * config.hQ)) : _Utils_Tuple2(0, 0);
		var tickOffsetX = _v146.a;
		var tickOffsetY = _v146.b;
		var cmds = config.i ? _Utils_ap(
			(config.hQ > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_ + tickOffsetX, y2_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_, y2_)
				]),
			_Utils_ap(
				config.fp ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y1_),
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]),
				(config.hQ > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_ + tickOffsetX, y1_ + tickOffsetY)
					]) : _List_Nil)) : _Utils_ap(
			(config.hQ > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_ + tickOffsetX, y1_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_, y1_)
				]),
			_Utils_ap(
				config.fp ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y2_),
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]),
				(config.hQ > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_ + tickOffsetX, y2_ + tickOffsetY)
					]) : _List_Nil));
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.g,
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__line'),
					$elm$svg$Svg$Attributes$fill('transparent'),
					$elm$svg$Svg$Attributes$stroke(config.fx),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.e1)),
					$elm$svg$Svg$Attributes$strokeOpacity(
					$elm$core$String$fromFloat(config.W)),
					$elm$svg$Svg$Attributes$strokeDasharray(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, $elm$core$String$fromFloat, config.a$))),
					$elm$svg$Svg$Attributes$d(
					A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
					config.p ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Chart$Svg$line = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$line,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultLine));
	});
var $terezka$elm_charts$Chart$Attributes$size = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{O: v});
	});
var $terezka$elm_charts$Chart$Attributes$width = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{e1: v});
	});
var $terezka$elm_charts$Chart$Attributes$x1 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				ij: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$Attributes$y1 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				im: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$grid = function (edits) {
	var config = A2(
		$terezka$elm_charts$Internal$Helpers$apply,
		edits,
		{fx: '', a$: _List_Nil, aL: false, e1: 0});
	var width = (!config.e1) ? (config.aL ? 0.5 : 1) : config.e1;
	var color = $elm$core$String$isEmpty(config.fx) ? (config.aL ? $terezka$elm_charts$Internal$Helpers$darkGray : $terezka$elm_charts$Internal$Helpers$gray) : config.fx;
	var toDot = F4(
		function (vs, p, x, y) {
			return (A2($elm$core$List$member, x, vs.aW) || A2($elm$core$List$member, y, vs.aX)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A5(
					$terezka$elm_charts$Chart$Svg$dot,
					p,
					function ($) {
						return $.ii;
					},
					function ($) {
						return $.e5;
					},
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$size(width),
							$terezka$elm_charts$Chart$Attributes$circle
						]),
					{ii: x, e5: y}));
		});
	var toXGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.aW) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$x1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.a$)
						])));
		});
	var toYGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.aX) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$y1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.a$)
						])));
		});
	return $terezka$elm_charts$Chart$GridElement(
		F2(
			function (p, vs) {
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__grid')
						]),
					config.aL ? A2(
						$elm$core$List$concatMap,
						function (x) {
							return A2(
								$elm$core$List$filterMap,
								A3(toDot, vs, p, x),
								vs.P);
						},
						vs.H) : _List_fromArray(
						[
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__x-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toXGrid, vs, p),
								vs.H)),
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__y-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toYGrid, vs, p),
								vs.P))
						]));
			}));
};
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $terezka$elm_charts$Chart$viewElements = F6(
	function (config, plane, tickValues, allItems, allLegends, elements) {
		var viewOne = F2(
			function (el, _v0) {
				var before = _v0.a;
				var chart_ = _v0.b;
				var after = _v0.c;
				switch (el.$) {
					case 0:
						return _Utils_Tuple3(before, chart_, after);
					case 1:
						var view = el.d;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 2:
						var view = el.e;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 3:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 4:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 5:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 6:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 7:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 8:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 9:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(view, plane, tickValues),
								chart_),
							after);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							A2(func, plane, allItems));
					case 11:
						var els = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							els);
					case 12:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					default:
						var view = el.a;
						return _Utils_Tuple3(
							($elm$core$List$length(chart_) > 0) ? A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								before) : before,
							chart_,
							($elm$core$List$length(chart_) > 0) ? after : A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								after));
				}
			});
		return A3(
			$elm$core$List$foldr,
			viewOne,
			_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$chart = F2(
	function (edits, unindexedElements) {
		var indexedElements = function () {
			var toIndexedEl = F2(
				function (el, _v4) {
					var acc = _v4.a;
					var index = _v4.b;
					switch (el.$) {
						case 0:
							var toElAndIndex = el.a;
							var _v6 = toElAndIndex(index);
							var newEl = _v6.a;
							var newIndex = _v6.b;
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[newEl])),
								newIndex);
						case 11:
							var els = el.a;
							return A3(
								$elm$core$List$foldl,
								toIndexedEl,
								_Utils_Tuple2(acc, index),
								els);
						default:
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[el])),
								index);
					}
				});
			return A3(
				$elm$core$List$foldl,
				toIndexedEl,
				_Utils_Tuple2(_List_Nil, 0),
				unindexedElements).a;
		}();
		var elements = function () {
			var isGrid = function (el) {
				if (el.$ === 9) {
					return true;
				} else {
					return false;
				}
			};
			return A2($elm$core$List$any, isGrid, indexedElements) ? indexedElements : A2(
				$elm$core$List$cons,
				$terezka$elm_charts$Chart$grid(_List_Nil),
				indexedElements);
		}();
		var legends_ = $terezka$elm_charts$Chart$getLegends(elements);
		var config = A2(
			$terezka$elm_charts$Internal$Helpers$apply,
			edits,
			{
				g: _List_fromArray(
					[
						$elm$svg$Svg$Attributes$style('overflow: visible;')
					]),
				bT: _List_Nil,
				f6: _List_Nil,
				gl: 300,
				a9: _List_Nil,
				an: {fo: 0, gK: 0, hq: 0, h1: 0},
				Y: {fo: 0, gK: 0, hq: 0, h1: 0},
				cJ: _List_Nil,
				bp: true,
				e1: 300
			});
		var plane = A2($terezka$elm_charts$Chart$definePlane, config, elements);
		var items = A2($terezka$elm_charts$Chart$getItems, plane, elements);
		var toEvent = function (_v2) {
			var event_ = _v2;
			var _v1 = event_.fM;
			var decoder = _v1;
			return A2(
				$terezka$elm_charts$Internal$Svg$Event,
				event_.gR,
				decoder(items));
		};
		var tickValues = A3($terezka$elm_charts$Chart$getTickValues, plane, items, elements);
		var _v0 = A6($terezka$elm_charts$Chart$viewElements, config, plane, tickValues, items, legends_, elements);
		var beforeEls = _v0.a;
		var chartEls = _v0.b;
		var afterEls = _v0.c;
		return A5(
			$terezka$elm_charts$Internal$Svg$container,
			plane,
			{
				g: config.g,
				f6: A2($elm$core$List$map, toEvent, config.f6),
				a9: config.a9,
				bp: config.bp
			},
			beforeEls,
			chartEls,
			afterEls);
	});
var $terezka$elm_charts$Internal$Item$Custom = {$: 2};
var $terezka$elm_charts$Chart$CustomElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $terezka$elm_charts$Chart$Indexed = function (a) {
	return {$: 0, a: a};
};
var $terezka$elm_charts$Internal$Item$Rendered = $elm$core$Basics$identity;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $terezka$elm_charts$Internal$Item$toHtml = function (_v0) {
	var item = _v0;
	return item.hU(item.fz);
};
var $terezka$elm_charts$Internal$Item$toSvg = F2(
	function (plane, _v0) {
		var item = _v0;
		return A3(
			item.hZ,
			plane,
			item.fz,
			A2(item.hY, plane, item.fz));
	});
var $terezka$elm_charts$Internal$Item$generalize = F2(
	function (toAny, _v0) {
		var item = _v0;
		return {
			fz: {
				hc: toAny(item.fz.hc),
				hT: $elm$core$Basics$identity,
				bx: item.fz.bx,
				h9: item.fz.h9
			},
			hU: function (c) {
				return $terezka$elm_charts$Internal$Item$toHtml(item);
			},
			hV: function (_v1) {
				return item.hV(item.fz);
			},
			hY: F2(
				function (plane, _v2) {
					return A2(item.hY, plane, item.fz);
				}),
			hZ: F3(
				function (plane, _v3, _v4) {
					return A2($terezka$elm_charts$Internal$Item$toSvg, plane, item);
				})
		};
	});
var $terezka$elm_charts$Internal$Item$getGeneral = function (_v0) {
	var item = _v0;
	return A2($terezka$elm_charts$Internal$Item$generalize, item.fz.hT, item);
};
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $terezka$elm_charts$Internal$Produce$tooltipRow = F3(
	function (color, title, text) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '3px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title + ':')
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'right'),
							A2($elm$html$Html$Attributes$style, 'padding', '0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text)
						]))
				]));
	});
var $terezka$elm_charts$Chart$custom = function (config) {
	return $terezka$elm_charts$Chart$Indexed(
		function (elIndex) {
			var item = {
				fz: {
					hc: 0,
					hT: $elm$core$Basics$always($terezka$elm_charts$Internal$Item$Custom),
					bx: {
						C: config.fx,
						E: 0,
						fx: config.fx,
						fI: 0,
						f$: elIndex,
						dN: config.gg(config.fI),
						gx: 0,
						gR: $elm$core$Maybe$Just(config.gR),
						hd: 0,
						hF: 0
					},
					h9: {fL: config.fI, gD: true, ij: config.g8.ij, ik: config.g8.ik, e5: config.g8.$9}
				},
				hU: function (c) {
					return _List_fromArray(
						[
							A3(
							$terezka$elm_charts$Internal$Produce$tooltipRow,
							c.bx.fx,
							A2($elm$core$Maybe$withDefault, 'Custom', c.bx.gR),
							c.bx.dN)
						]);
				},
				hV: function (_v0) {
					return config.g8;
				},
				hY: F2(
					function (_v1, _v2) {
						return config.g8;
					}),
				hZ: F3(
					function (plane, _v3, position) {
						return config.hm(plane);
					})
			};
			return _Utils_Tuple2(
				A2(
					$terezka$elm_charts$Chart$CustomElement,
					$terezka$elm_charts$Internal$Item$getGeneral(item),
					function (p) {
						return A2(
							$elm$svg$Svg$map,
							$elm$core$Basics$never,
							A2($terezka$elm_charts$Internal$Item$toSvg, p, item));
					}),
				elIndex + 1);
		});
};
var $terezka$elm_charts$Chart$Attributes$domain = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{bT: v});
	});
var $terezka$elm_charts$Chart$SubElements = function (a) {
	return {$: 10, a: a};
};
var $terezka$elm_charts$Chart$each = F2(
	function (items, func) {
		return $terezka$elm_charts$Chart$SubElements(
			F2(
				function (p, _v0) {
					return A2(
						$elm$core$List$concatMap,
						func(p),
						items);
				}));
	});
var $terezka$elm_charts$Chart$Attributes$exactly = F3(
	function (exact, _v0, _v1) {
		return exact;
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Metric$countToPercentileRange = F2(
	function (percentiles, count) {
		var fn = function (_v1) {
			var percent = _v1.a;
			var percentile = _v1.b;
			return (_Utils_cmp(count, percentile) > -1) ? $elm$core$Maybe$Just(percent) : $elm$core$Maybe$Nothing;
		};
		var ps = A2($elm$core$List$filterMap, fn, percentiles);
		var _v0 = $elm$core$List$maximum(ps);
		if (!_v0.$) {
			var p = _v0.a;
			return p;
		} else {
			return 0;
		}
	});
var $author$project$Models$Metric$getPercentileRangeForMetrics = function (_v0) {
	var counts = _v0.ds;
	var percentiles = _v0.ei;
	var fn = function (_v1) {
		var count = _v1.dr;
		var relativeTimeAgo = _v1.er;
		return _Utils_Tuple2(
			relativeTimeAgo,
			A2($author$project$Models$Metric$countToPercentileRange, percentiles, count));
	};
	return A2($elm$core$List$map, fn, counts);
};
var $terezka$elm_charts$Chart$Attributes$height = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{gl: v});
	});
var $terezka$elm_charts$Chart$Attributes$highest = F3(
	function (v, edit, b) {
		return _Utils_update(
			b,
			{
				ao: A3(edit, v, b.ao, b.fJ)
			});
	});
var $terezka$elm_charts$Chart$ListOfElements = function (a) {
	return {$: 11, a: a};
};
var $terezka$elm_charts$Chart$list = $terezka$elm_charts$Chart$ListOfElements;
var $terezka$elm_charts$Chart$Attributes$margin = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{an: v});
	});
var $terezka$elm_charts$Chart$Attributes$offset = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{c: v});
	});
var $terezka$elm_charts$Internal$Svg$TopOrBottom = 5;
var $terezka$elm_charts$Chart$Attributes$onTopOrBottom = function (config) {
	return _Utils_update(
		config,
		{
			fV: $elm$core$Maybe$Just(5)
		});
};
var $author$project$App$Metrics$View$percentileToColor = function (p) {
	return (p >= 90) ? '#62bb6e' : ((p >= 80) ? '#79c679' : ((p >= 70) ? '#8ed082' : ((p >= 60) ? '#a2d88a' : ((p >= 50) ? '#b6e192' : ((p >= 40) ? '#c8e99b' : ((p >= 30) ? '#daf0a4' : ((p >= 20) ? '#e5f5ac' : ((p >= 10) ? '#f1fab5' : '#ffffff'))))))));
};
var $terezka$elm_charts$Chart$Attributes$range = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{cJ: v});
	});
var $terezka$elm_charts$Internal$Svg$defaultRect = {g: _List_Nil, C: 'rgba(210, 210, 210, 1)', E: 1, fx: 'rgba(210, 210, 210, 0.5)', p: false, W: 1, ij: $elm$core$Maybe$Nothing, ik: $elm$core$Maybe$Nothing, im: $elm$core$Maybe$Nothing, $9: $elm$core$Maybe$Nothing};
var $terezka$elm_charts$Internal$Svg$rect = F2(
	function (plane, config) {
		var _v0 = function () {
			var _v3 = _Utils_Tuple2(
				_Utils_Tuple2(config.ij, config.ik),
				_Utils_Tuple2(config.im, config.$9));
			_v3$11:
			while (true) {
				if (!_v3.a.a.$) {
					if (_v3.a.b.$ === 1) {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v8 = _v3.a;
								var a = _v8.a.a;
								var _v9 = _v8.b;
								var _v10 = _v3.b;
								var _v11 = _v10.a;
								var _v12 = _v10.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, a),
									_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
							} else {
								break _v3$11;
							}
						} else {
							if (!_v3.b.b.$) {
								var _v35 = _v3.a;
								var c = _v35.a.a;
								var _v36 = _v35.b;
								var _v37 = _v3.b;
								var a = _v37.a.a;
								var b = _v37.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(c, c),
									_Utils_Tuple2(a, b));
							} else {
								break _v3$11;
							}
						}
					} else {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v4 = _v3.a;
								var a = _v4.a.a;
								var b = _v4.b.a;
								var _v5 = _v3.b;
								var _v6 = _v5.a;
								var _v7 = _v5.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
							} else {
								var _v38 = _v3.a;
								var a = _v38.a.a;
								var b = _v38.b.a;
								var _v39 = _v3.b;
								var _v40 = _v39.a;
								var c = _v39.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(c, c));
							}
						} else {
							if (_v3.b.b.$ === 1) {
								var _v41 = _v3.a;
								var a = _v41.a.a;
								var b = _v41.b.a;
								var _v42 = _v3.b;
								var c = _v42.a.a;
								var _v43 = _v42.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(c, c));
							} else {
								break _v3$11;
							}
						}
					}
				} else {
					if (!_v3.a.b.$) {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v13 = _v3.a;
								var _v14 = _v13.a;
								var b = _v13.b.a;
								var _v15 = _v3.b;
								var _v16 = _v15.a;
								var _v17 = _v15.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(b, b),
									_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
							} else {
								break _v3$11;
							}
						} else {
							if (!_v3.b.b.$) {
								var _v32 = _v3.a;
								var _v33 = _v32.a;
								var c = _v32.b.a;
								var _v34 = _v3.b;
								var a = _v34.a.a;
								var b = _v34.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(c, c),
									_Utils_Tuple2(a, b));
							} else {
								break _v3$11;
							}
						}
					} else {
						if (!_v3.b.a.$) {
							if (!_v3.b.b.$) {
								var _v18 = _v3.a;
								var _v19 = _v18.a;
								var _v20 = _v18.b;
								var _v21 = _v3.b;
								var a = _v21.a.a;
								var b = _v21.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ii.aq, plane.ii.aq),
									_Utils_Tuple2(a, b));
							} else {
								var _v22 = _v3.a;
								var _v23 = _v22.a;
								var _v24 = _v22.b;
								var _v25 = _v3.b;
								var a = _v25.a.a;
								var _v26 = _v25.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
									_Utils_Tuple2(a, a));
							}
						} else {
							if (!_v3.b.b.$) {
								var _v27 = _v3.a;
								var _v28 = _v27.a;
								var _v29 = _v27.b;
								var _v30 = _v3.b;
								var _v31 = _v30.a;
								var b = _v30.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
									_Utils_Tuple2(b, b));
							} else {
								var _v44 = _v3.a;
								var _v45 = _v44.a;
								var _v46 = _v44.b;
								var _v47 = _v3.b;
								var _v48 = _v47.a;
								var _v49 = _v47.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ii.aq, plane.ii.ao),
									_Utils_Tuple2(plane.e5.aq, plane.e5.ao));
							}
						}
					}
				}
			}
			return _Utils_Tuple2(
				_Utils_Tuple2(
					A2($elm$core$Maybe$withDefault, plane.ii.aq, config.ij),
					A2($elm$core$Maybe$withDefault, plane.ii.ao, config.ik)),
				_Utils_Tuple2(
					A2($elm$core$Maybe$withDefault, plane.e5.aq, config.im),
					A2($elm$core$Maybe$withDefault, plane.e5.ao, config.$9)));
		}();
		var _v1 = _v0.a;
		var x1_ = _v1.a;
		var x2_ = _v1.b;
		var _v2 = _v0.b;
		var y1_ = _v2.a;
		var y2_ = _v2.b;
		var cmds = _List_fromArray(
			[
				A2($terezka$elm_charts$Internal$Commands$Move, x1_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x2_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y2_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
			]);
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.g,
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__rect'),
					$elm$svg$Svg$Attributes$fill(config.fx),
					$elm$svg$Svg$Attributes$fillOpacity(
					$elm$core$String$fromFloat(config.W)),
					$elm$svg$Svg$Attributes$stroke(config.C),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.E)),
					$elm$svg$Svg$Attributes$d(
					A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
					config.p ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Chart$Svg$rect = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$rect,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultRect));
	});
var $terezka$elm_charts$Internal$Item$getLimits = function (_v0) {
	var item = _v0;
	return item.hV(item.fz);
};
var $terezka$elm_charts$Internal$Item$getPosition = F2(
	function (plane, _v0) {
		var item = _v0;
		return A2(item.hY, plane, item.fz);
	});
var $terezka$elm_charts$Chart$HtmlElement = function (a) {
	return {$: 13, a: a};
};
var $terezka$elm_charts$Chart$html = function (func) {
	return $terezka$elm_charts$Chart$HtmlElement(
		F2(
			function (p, _v0) {
				return func(p);
			}));
};
var $terezka$elm_charts$Internal$Svg$defaultTooltip = {ax: true, fj: 'white', C: '#D8D8D8', fV: $elm$core$Maybe$Nothing, ge: $elm$core$Maybe$Nothing, gl: 0, c: 8, e1: 0};
var $terezka$elm_charts$Internal$Svg$Bottom = 3;
var $terezka$elm_charts$Internal$Svg$Left = 1;
var $terezka$elm_charts$Internal$Svg$Right = 2;
var $terezka$elm_charts$Internal$Svg$Top = 0;
var $terezka$elm_charts$Internal$Coordinates$bottom = function (pos) {
	return {ii: pos.ij + ((pos.ik - pos.ij) / 2), e5: pos.im};
};
var $terezka$elm_charts$Internal$Coordinates$left = function (pos) {
	return {ii: pos.ij, e5: pos.im + ((pos.$9 - pos.im) / 2)};
};
var $terezka$elm_charts$Internal$Svg$positionHtml = F7(
	function (plane, x, y, xOff, yOff, attrs, content) {
		var yPercentage = ((A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y) - yOff) * 100) / plane.e5.q;
		var xPercentage = ((A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x) + xOff) * 100) / plane.ii.q;
		var posititonStyles = _List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'left',
				$elm$core$String$fromFloat(xPercentage) + '%'),
				A2(
				$elm$html$Html$Attributes$style,
				'top',
				$elm$core$String$fromFloat(yPercentage) + '%'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '-400px'),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute')
			]);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(posititonStyles, attrs),
			content);
	});
var $terezka$elm_charts$Internal$Coordinates$right = function (pos) {
	return {ii: pos.ik, e5: pos.im + ((pos.$9 - pos.im) / 2)};
};
var $terezka$elm_charts$Internal$Svg$tooltipPointerStyle = F4(
	function (direction, className, background, borderColor) {
		var config = function () {
			switch (direction) {
				case 0:
					return {ai: 'right', R: 'top', _: 'left'};
				case 3:
					return {ai: 'right', R: 'bottom', _: 'left'};
				case 1:
					return {ai: 'bottom', R: 'left', _: 'top'};
				case 2:
					return {ai: 'bottom', R: 'right', _: 'top'};
				case 4:
					return {ai: 'bottom', R: 'left', _: 'top'};
				default:
					return {ai: 'right', R: 'top', _: 'left'};
			}
		}();
		return '\n  .' + (className + (':before, .' + (className + (':after {\n    content: "";\n    position: absolute;\n    border-' + (config._ + (': 5px solid transparent;\n    border-' + (config.ai + (': 5px solid transparent;\n    ' + (config.R + (': 100%;\n    ' + (config._ + (': 50%;\n    margin-' + (config._ + (': -5px;\n  }\n\n  .' + (className + (':after {\n    border-' + (config.R + (': 5px solid ' + (background + (';\n    margin-' + (config.R + (': -1px;\n    z-index: 1;\n    height: 0px;\n  }\n\n  .' + (className + (':before {\n    border-' + (config.R + (': 5px solid ' + (borderColor + ';\n    height: 0px;\n  }\n  ')))))))))))))))))))))))))));
	});
var $terezka$elm_charts$Internal$Coordinates$top = function (pos) {
	return {ii: pos.ij + ((pos.ik - pos.ij) / 2), e5: pos.$9};
};
var $terezka$elm_charts$Internal$Svg$tooltip = F5(
	function (plane, pos, config, htmlAttrs, content) {
		var distanceTop = A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, pos.$9);
		var distanceRight = plane.ii.q - A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, pos.ij);
		var distanceLeft = A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, pos.ik);
		var distanceBottom = plane.e5.q - A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, pos.im);
		var direction = function () {
			var _v5 = config.fV;
			if (!_v5.$) {
				switch (_v5.a) {
					case 4:
						var _v6 = _v5.a;
						return (config.e1 > 0) ? ((_Utils_cmp(distanceLeft, config.e1 + config.c) > 0) ? 1 : 2) : ((_Utils_cmp(distanceLeft, distanceRight) > 0) ? 1 : 2);
					case 5:
						var _v7 = _v5.a;
						return (config.gl > 0) ? ((_Utils_cmp(distanceTop, config.gl + config.c) > 0) ? 0 : 3) : ((_Utils_cmp(distanceTop, distanceBottom) > 0) ? 0 : 3);
					default:
						var dir = _v5.a;
						return dir;
				}
			} else {
				var isLargest = function (a) {
					return $elm$core$List$all(
						function (b) {
							return _Utils_cmp(a, b) > -1;
						});
				};
				return A2(
					isLargest,
					distanceTop,
					_List_fromArray(
						[distanceBottom, distanceLeft, distanceRight])) ? 0 : (A2(
					isLargest,
					distanceBottom,
					_List_fromArray(
						[distanceTop, distanceLeft, distanceRight])) ? 3 : (A2(
					isLargest,
					distanceLeft,
					_List_fromArray(
						[distanceTop, distanceBottom, distanceRight])) ? 1 : 2));
			}
		}();
		var focalPoint = function () {
			var _v2 = config.ge;
			if (!_v2.$) {
				var focal = _v2.a;
				switch (direction) {
					case 0:
						return $terezka$elm_charts$Internal$Coordinates$top(
							focal(pos));
					case 3:
						return $terezka$elm_charts$Internal$Coordinates$bottom(
							focal(pos));
					case 1:
						return $terezka$elm_charts$Internal$Coordinates$left(
							focal(pos));
					case 2:
						return $terezka$elm_charts$Internal$Coordinates$right(
							focal(pos));
					case 4:
						return $terezka$elm_charts$Internal$Coordinates$left(
							focal(pos));
					default:
						return $terezka$elm_charts$Internal$Coordinates$right(
							focal(pos));
				}
			} else {
				switch (direction) {
					case 0:
						return $terezka$elm_charts$Internal$Coordinates$top(pos);
					case 3:
						return $terezka$elm_charts$Internal$Coordinates$bottom(pos);
					case 1:
						return $terezka$elm_charts$Internal$Coordinates$left(pos);
					case 2:
						return $terezka$elm_charts$Internal$Coordinates$right(pos);
					case 4:
						return $terezka$elm_charts$Internal$Coordinates$left(pos);
					default:
						return $terezka$elm_charts$Internal$Coordinates$right(pos);
				}
			}
		}();
		var arrowWidth = config.ax ? 4 : 0;
		var _v0 = function () {
			switch (direction) {
				case 0:
					return {ak: 'elm-charts__tooltip-top', at: 'translate(-50%, -100%)', k: 0, l: config.c + arrowWidth};
				case 3:
					return {ak: 'elm-charts__tooltip-bottom', at: 'translate(-50%, 0%)', k: 0, l: (-config.c) - arrowWidth};
				case 1:
					return {ak: 'elm-charts__tooltip-left', at: 'translate(-100%, -50%)', k: (-config.c) - arrowWidth, l: 0};
				case 2:
					return {ak: 'elm-charts__tooltip-right', at: 'translate(0, -50%)', k: config.c + arrowWidth, l: 0};
				case 4:
					return {ak: 'elm-charts__tooltip-leftOrRight', at: 'translate(0, -50%)', k: (-config.c) - arrowWidth, l: 0};
				default:
					return {ak: 'elm-charts__tooltip-topOrBottom', at: 'translate(-50%, -100%)', k: 0, l: config.c + arrowWidth};
			}
		}();
		var xOff = _v0.k;
		var yOff = _v0.l;
		var transformation = _v0.at;
		var className = _v0.ak;
		var children = config.ax ? A2(
			$elm$core$List$cons,
			A3(
				$elm$html$Html$node,
				'style',
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A4($terezka$elm_charts$Internal$Svg$tooltipPointerStyle, direction, className, config.fj, config.C))
					])),
			content) : content;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(className),
					A2($elm$html$Html$Attributes$style, 'transform', transformation),
					A2($elm$html$Html$Attributes$style, 'padding', '5px 8px'),
					A2($elm$html$Html$Attributes$style, 'background', config.fj),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + config.C),
					A2($elm$html$Html$Attributes$style, 'border-radius', '3px'),
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
				]),
			htmlAttrs);
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$never,
			A7($terezka$elm_charts$Internal$Svg$positionHtml, plane, focalPoint.ii, focalPoint.e5, xOff, yOff, attributes, children));
	});
var $terezka$elm_charts$Chart$Svg$tooltip = F3(
	function (plane, pos, edits) {
		return A3(
			$terezka$elm_charts$Internal$Svg$tooltip,
			plane,
			pos,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultTooltip));
	});
var $terezka$elm_charts$Chart$tooltip = F4(
	function (i, edits, attrs_, content) {
		return $terezka$elm_charts$Chart$html(
			function (p) {
				var pos = $terezka$elm_charts$Internal$Item$getLimits(i);
				var content_ = _Utils_eq(content, _List_Nil) ? $terezka$elm_charts$Internal$Item$toHtml(i) : content;
				return A3($terezka$elm_charts$Internal$Svg$isWithinPlane, p, pos.ij, pos.$9) ? A5(
					$terezka$elm_charts$Chart$Svg$tooltip,
					p,
					A2($terezka$elm_charts$Internal$Item$getPosition, p, i),
					edits,
					attrs_,
					content_) : $elm$html$Html$text('');
			});
	});
var $terezka$elm_charts$Chart$Attributes$x2 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				ik: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$Attributes$y2 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				$9: $elm$core$Maybe$Just(v)
			});
	});
var $author$project$App$Metrics$View$viewChart = function (model) {
	var dayPerc = function () {
		var _v2 = model.d7;
		if (!_v2.$) {
			var efforts = _v2.a.f_;
			return $author$project$Models$Metric$getPercentileRangeForMetrics(efforts);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$terezka$elm_charts$Chart$chart,
		_List_fromArray(
			[
				$terezka$elm_charts$Chart$Attributes$height(200),
				$terezka$elm_charts$Chart$Attributes$width(200),
				$terezka$elm_charts$Chart$Attributes$range(
				_List_fromArray(
					[
						A2($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$exactly),
						A2($terezka$elm_charts$Chart$Attributes$highest, 20, $terezka$elm_charts$Chart$Attributes$exactly)
					])),
				$terezka$elm_charts$Chart$Attributes$domain(
				_List_fromArray(
					[
						A2($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$exactly),
						A2($terezka$elm_charts$Chart$Attributes$highest, 20, $terezka$elm_charts$Chart$Attributes$exactly)
					])),
				$terezka$elm_charts$Chart$Attributes$margin(
				{fo: 3, gK: 3, hq: 2, h1: 2})
			]),
		_List_fromArray(
			[
				$terezka$elm_charts$Chart$list(
				function () {
					var heatmapItem = function (_v0) {
						var day = _v0.a;
						var perc = _v0.b;
						var y = 20 - (((day / 10) | 0) * 2);
						var x = 20 - ((day % 10) * 2);
						var color = $author$project$App$Metrics$View$percentileToColor(perc);
						var makeRectangle = function (p) {
							return A2(
								$terezka$elm_charts$Chart$Svg$rect,
								p,
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$x1(x),
										$terezka$elm_charts$Chart$Attributes$x2(x + 2),
										$terezka$elm_charts$Chart$Attributes$y1(y),
										$terezka$elm_charts$Chart$Attributes$y2(y + 2),
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$border('white')
									]));
						};
						return $terezka$elm_charts$Chart$custom(
							{
								fx: color,
								fI: {ii: x, e5: y},
								gg: A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.e5;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$fromFloat,
										function (v) {
											return v + ' C°';
										})),
								gR: 'Efforts',
								g8: {ij: x, ik: x + 2, im: 0, $9: 2},
								hm: makeRectangle
							});
					};
					return A2($elm$core$List$map, heatmapItem, dayPerc);
				}()),
				A2(
				$terezka$elm_charts$Chart$each,
				model.dX,
				F2(
					function (_v1, item) {
						return _List_fromArray(
							[
								A4(
								$terezka$elm_charts$Chart$tooltip,
								item,
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$center,
										$terezka$elm_charts$Chart$Attributes$offset(0),
										$terezka$elm_charts$Chart$Attributes$onTopOrBottom
									]),
								_List_Nil,
								_List_Nil)
							]);
					}))
			]));
};
var $author$project$App$Metrics$View$view = function (model) {
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'max-width', '200px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								$author$project$App$Metrics$View$viewChart(model)
							]))
					]))
			]));
};
var $author$project$App$Search$SearchBar$Messages$SearchForTerm = {$: 1};
var $author$project$App$Search$SearchBar$Messages$SetSearchTerm = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Search$SearchBar$Messages$Clear = {$: 2};
var $aforemny$material_components_web_elm$Material$TextField$Icon$Internal$Icon = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$TextField$Icon$customIcon = F3(
	function (node, attributes, nodes) {
		return $aforemny$material_components_web_elm$Material$TextField$Icon$Internal$Icon(
			{aY: attributes, aK: false, bj: node, bk: nodes, eg: $elm$core$Maybe$Nothing});
	});
var $aforemny$material_components_web_elm$Material$TextField$Icon$icon = function (iconName) {
	return A3(
		$aforemny$material_components_web_elm$Material$TextField$Icon$customIcon,
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(iconName)
			]));
};
var $author$project$App$Search$SearchBar$Model$searchTermIsAlreadySearchedFor = function (_v0) {
	var searchTerm = _v0.cP;
	var lastSearchedFor = _v0.cc;
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (lSF) {
				return _Utils_eq(lSF, searchTerm);
			},
			lastSearchedFor));
};
var $aforemny$material_components_web_elm$Material$TextField$Icon$Internal$SvgIcon = function (a) {
	return {$: 1, a: a};
};
var $aforemny$material_components_web_elm$Material$TextField$Icon$setOnInteraction = F2(
	function (onInteraction, icon_) {
		if (!icon_.$) {
			var data = icon_.a;
			return $aforemny$material_components_web_elm$Material$TextField$Icon$Internal$Icon(
				_Utils_update(
					data,
					{
						eg: $elm$core$Maybe$Just(onInteraction)
					}));
		} else {
			var data = icon_.a;
			return $aforemny$material_components_web_elm$Material$TextField$Icon$Internal$SvgIcon(
				_Utils_update(
					data,
					{
						eg: $elm$core$Maybe$Just(onInteraction)
					}));
		}
	});
var $author$project$App$Search$SearchBar$View$getTrailingIcon = function (model) {
	return $author$project$App$Search$SearchBar$Model$searchTermIsAlreadySearchedFor(model) ? A2(
		$aforemny$material_components_web_elm$Material$TextField$Icon$setOnInteraction,
		$author$project$App$Search$SearchBar$Messages$Clear,
		$aforemny$material_components_web_elm$Material$TextField$Icon$icon('clear')) : A2(
		$aforemny$material_components_web_elm$Material$TextField$Icon$setOnInteraction,
		$author$project$App$Search$SearchBar$Messages$SearchForTerm,
		$aforemny$material_components_web_elm$Material$TextField$Icon$icon('keyboard_return'));
};
var $aforemny$material_components_web_elm$Material$TextField$setTrailingIcon = F2(
	function (trailingIcon, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bz: trailingIcon});
	});
var $author$project$App$Search$SearchBar$View$view = function (model) {
	var searchTerm = model.cP;
	var status = model.eM;
	var trimmed = $elm$core$String$trim(searchTerm);
	var trailingIcon = (!$elm$core$String$length(trimmed)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		$author$project$App$Search$SearchBar$View$getTrailingIcon(model));
	var config = A2(
		$aforemny$material_components_web_elm$Material$TextField$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'autocorrect', 'off'),
				A2($elm$html$Html$Attributes$style, 'autocapitalize', 'none')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$TextField$setType,
			$elm$core$Maybe$Just('text'),
			A2(
				$aforemny$material_components_web_elm$Material$TextField$setTrailingIcon,
				trailingIcon,
				A2(
					$aforemny$material_components_web_elm$Material$TextField$setDisabled,
					_Utils_eq(status, $author$project$App$Search$SearchBar$Model$LoadingSearch),
					A2(
						$aforemny$material_components_web_elm$Material$TextField$setOnInput,
						$author$project$App$Search$SearchBar$Messages$SetSearchTerm,
						A2(
							$aforemny$material_components_web_elm$Material$TextField$setValue,
							$elm$core$Maybe$Just(searchTerm),
							A2(
								$aforemny$material_components_web_elm$Material$TextField$setPlaceholder,
								$elm$core$Maybe$Just('Search'),
								$aforemny$material_components_web_elm$Material$TextField$config)))))));
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Events$onSubmit($author$project$App$Search$SearchBar$Messages$SearchForTerm)
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$TextField$outlined(config)
			]));
};
var $aforemny$material_components_web_elm$Material$LayoutGrid$span4 = $aforemny$material_components_web_elm$Material$LayoutGrid$span(4);
var $aforemny$material_components_web_elm$Material$LayoutGrid$span8 = $aforemny$material_components_web_elm$Material$LayoutGrid$span(8);
var $author$project$Models$Entity$dependencyToConjugation = function (d) {
	if (d.$ === 3) {
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Entity$dependencyToDefinition = function (d) {
	if (d.$ === 4) {
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Entity$dependencyToExample = function (d) {
	if (d.$ === 5) {
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Entity$dependencyToPlural = function (d) {
	if (d.$ === 1) {
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Entity$dependencyToVariant = function (d) {
	if (d.$ === 2) {
		return $elm$core$Maybe$Just(d);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Entity$formatGender = function (g) {
	switch (g) {
		case 0:
			return 'feminine';
		case 1:
			return 'masculine';
		default:
			return 'neuter';
	}
};
var $author$project$Models$Entity$formatDependencyMin = function (d) {
	switch (d.$) {
		case 0:
			var g = d.a;
			return $author$project$Models$Entity$formatGender(g);
		case 1:
			var p = d.a;
			return 'die ' + p;
		case 4:
			var def = d.a;
			return def;
		case 5:
			var exmpl = d.a;
			var def = d.b;
			return exmpl + (': ' + def);
		case 2:
			var _var = d.a;
			var def = d.b;
			return _var + (': ' + def);
		case 3:
			var m = d.a;
			var p = d.b;
			var c = d.c;
			var v = d.d;
			return $author$project$Models$Conjugation$formatMTA(m) + (', ' + (A2($author$project$Models$Conjugation$formatVerbPersonCount, p, c) + (': ' + v)));
		default:
			return '';
	}
};
var $author$project$App$Search$Entities$View$viewDependency = function (_v0) {
	var effort = _v0.dy;
	var dependency = _v0.fQ;
	var effortStr = function () {
		if (!effort.$) {
			var ef = effort.a;
			return '(' + ($elm$core$String$fromInt(ef.fE) + ') ');
		} else {
			return '';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '0px'),
				A2($elm$html$Html$Attributes$style, 'margin', '0px'),
				$aforemny$material_components_web_elm$Material$Typography$body2
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('- '),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[$aforemny$material_components_web_elm$Material$Typography$caption]),
				_List_fromArray(
					[
						$elm$html$Html$text(effortStr)
					])),
				$elm$html$Html$text(
				$author$project$Models$Entity$formatDependencyMin(dependency.fQ))
			]));
};
var $author$project$App$Search$Entities$View$viewDependencies = function (ds) {
	var filterAux = F2(
		function (filterFn, eD) {
			var dependency = eD.fQ;
			var _v0 = filterFn(dependency.fQ);
			if (!_v0.$) {
				var dep = _v0.a;
				return $elm$core$Maybe$Just(eD);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '0px'),
				A2($elm$html$Html$Attributes$style, 'margin', '0px')
			]),
		A2(
			$elm$core$List$map,
			$author$project$App$Search$Entities$View$viewDependency,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2(
						$elm$core$List$filterMap,
						filterAux($author$project$Models$Entity$dependencyToPlural),
						ds),
						A2(
						$elm$core$List$filterMap,
						filterAux($author$project$Models$Entity$dependencyToDefinition),
						ds),
						A2(
						$elm$core$List$filterMap,
						filterAux($author$project$Models$Entity$dependencyToVariant),
						ds),
						A2(
						$elm$core$List$filterMap,
						filterAux($author$project$Models$Entity$dependencyToExample),
						ds),
						A2(
						$elm$core$List$filterMap,
						filterAux($author$project$Models$Entity$dependencyToConjugation),
						ds)
					]))));
};
var $author$project$Models$Entity$dependencyToGender = function (d) {
	if (!d.$) {
		var g = d.a;
		return $elm$core$Maybe$Just(g);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Search$Entities$View$viewEntityPoS = function (entityType) {
	var toText = function (s) {
		return $elm$html$Html$text(
			A2($author$project$Util$brackets, '{', s));
	};
	var formatted = function () {
		switch (entityType) {
			case 1:
				return _List_fromArray(
					[
						toText('inf')
					]);
			case 2:
				return _List_fromArray(
					[
						toText('adj')
					]);
			case 3:
				return _List_fromArray(
					[
						toText('adv')
					]);
			default:
				return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$caption,
				A2($elm$html$Html$Attributes$style, 'padding-left', '2px')
			]),
		formatted);
};
var $author$project$App$Search$Entities$View$viewEntity = F2(
	function (_v0, ds) {
		var entity = _v0.aa;
		var entityType = _v0.bX;
		var genders = A2($elm$core$List$filterMap, $author$project$Models$Entity$dependencyToGender, ds);
		var entityTypeFormatted = $author$project$Models$Entity$formatEntityType(entityType);
		var bgColor = $author$project$Models$Entity$gendersToColor(genders);
		var articles = A2(
			$elm$core$String$join,
			'/',
			A2($elm$core$List$map, $author$project$Models$Entity$genderToDefiniteArticle, genders));
		var entityFormatted = function () {
			if (!entityType) {
				var _v2 = $elm$core$String$length(articles) > 0;
				if (_v2) {
					return articles + (' ' + $author$project$Util$capitalize(entity));
				} else {
					return $author$project$Util$capitalize(entity);
				}
			} else {
				return entity;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'background-color', bgColor),
							$aforemny$material_components_web_elm$Material$Typography$body2
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(entityFormatted)
						])),
					$author$project$App$Search$Entities$View$viewEntityPoS(entityType)
				]));
	});
var $author$project$App$Search$Entities$View$viewEWEDs = function (_v0) {
	var entity = _v0.aa;
	var effortedDependencies = _v0.fZ;
	var ds = A2(
		$elm$core$List$map,
		function ($) {
			return $.fQ;
		},
		A2(
			$elm$core$List$map,
			function ($) {
				return $.fQ;
			},
			effortedDependencies));
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '2px 2px 2px 2px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[
								$aforemny$material_components_web_elm$Material$LayoutGrid$span4,
								A2($elm$html$Html$Attributes$style, 'padding-top', '0px')
							]),
						_List_fromArray(
							[
								A2($author$project$App$Search$Entities$View$viewEntity, entity, ds)
							])),
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[
								$aforemny$material_components_web_elm$Material$LayoutGrid$span8,
								A2($elm$html$Html$Attributes$style, 'padding-top', '0px')
							]),
						_List_fromArray(
							[
								$author$project$App$Search$Entities$View$viewDependencies(effortedDependencies)
							]))
					]))
			]));
};
var $author$project$App$Search$Entities$View$view = function (userEntities) {
	var block = $aforemny$material_components_web_elm$Material$Card$block(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-top', '8px')
				]),
			A2($elm$core$List$map, $author$project$App$Search$Entities$View$viewEWEDs, userEntities)));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		A2(
			$aforemny$material_components_web_elm$Material$Card$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '2px'),
					A2($elm$html$Html$Attributes$style, 'margin-top', '5px')
				]),
			A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config)),
		{
			e8: $elm$core$Maybe$Nothing,
			fn: _Utils_Tuple2(block, _List_Nil)
		});
};
var $author$project$App$Search$Messages$SetResultTab = function (a) {
	return {$: 2, a: a};
};
var $aforemny$material_components_web_elm$Material$Tab$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Tab$config = {
	e9: false,
	bF: _List_Nil,
	S: {gr: $elm$core$Maybe$Nothing, gE: ''},
	cs: $elm$core$Maybe$Nothing
};
var $aforemny$material_components_web_elm$Material$TabBar$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TabBar$config = {bF: _List_Nil, bG: $elm$core$Maybe$Nothing, ba: false, ci: false, cY: false};
var $aforemny$material_components_web_elm$Material$Tab$setActive = F2(
	function (active, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{e9: active});
	});
var $aforemny$material_components_web_elm$Material$Tab$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bF: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TabBar$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bF: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Tab$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				cs: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Tab$Internal$Tab = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Tab$tab = F2(
	function (_v0, content) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{S: content});
	});
var $aforemny$material_components_web_elm$Material$TabBar$activatedHandler = function (tabs) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Events$on,
			'MDCTabBar:activated',
			A2(
				$elm$json$Json$Decode$andThen,
				function (activatedIndex) {
					var _v0 = A2(
						$elm$core$Maybe$andThen,
						function (_v1) {
							var onClick = _v1.cs;
							return onClick;
						},
						$elm$core$List$head(
							A2($elm$core$List$drop, activatedIndex, tabs)));
					if (!_v0.$) {
						var msg = _v0.a;
						return $elm$json$Json$Decode$succeed(msg);
					} else {
						return $elm$json$Json$Decode$fail('');
					}
				},
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['detail', 'index']),
					$elm$json$Json$Decode$int))));
};
var $aforemny$material_components_web_elm$Material$TabBar$activeTabIndexProp = function (tabs) {
	var activeTabIndex = A2(
		$elm$core$Maybe$map,
		$elm$core$Tuple$first,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var active = _v0.b.e9;
					return active;
				},
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, tabs))));
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$property('activeTabIndex'),
			$elm$json$Json$Encode$int),
		activeTabIndex);
};
var $aforemny$material_components_web_elm$Material$TabBar$anyActive = function (tabs) {
	if (!tabs.b) {
		return false;
	} else {
		var active = tabs.a.e9;
		var remainingTabs = tabs.b;
		return active || $aforemny$material_components_web_elm$Material$TabBar$anyActive(remainingTabs);
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$setActive = F2(
	function (active, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{e9: active});
	});
var $aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper = function (tabs) {
	if (!tabs.b) {
		return _List_Nil;
	} else {
		var tab = tabs.a;
		var active = tab.e9;
		var remainingTabs = tabs.b;
		return (!active) ? A2(
			$elm$core$List$cons,
			tab,
			$aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper(remainingTabs)) : A2(
			$elm$core$List$cons,
			tab,
			A2(
				$elm$core$List$map,
				$aforemny$material_components_web_elm$Material$TabBar$setActive(false),
				remainingTabs));
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$enforceActive = F2(
	function (firstTab, otherTabs) {
		var config_ = firstTab;
		return (!$aforemny$material_components_web_elm$Material$TabBar$anyActive(
			A2($elm$core$List$cons, firstTab, otherTabs))) ? A2(
			$elm$core$List$cons,
			A2($aforemny$material_components_web_elm$Material$TabBar$setActive, true, firstTab),
			otherTabs) : $aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper(
			A2($elm$core$List$cons, firstTab, otherTabs));
	});
var $aforemny$material_components_web_elm$Material$TabBar$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab-bar'));
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerAlignCs = function (align) {
	if (!align.$) {
		switch (align.a) {
			case 0:
				var _v1 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-start'));
			case 1:
				var _v2 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-end'));
			default:
				var _v3 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-center'));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab-scroller'));
var $aforemny$material_components_web_elm$Material$TabBar$tabIconElt = function (_v0) {
	var icon = _v0.gr;
	return A2(
		$elm$core$Maybe$map,
		$elm$html$Html$map($elm$core$Basics$never),
		function () {
			if (!icon.$) {
				if (!icon.a.$) {
					var node = icon.a.a.bj;
					var attributes = icon.a.a.aY;
					var nodes = icon.a.a.bk;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$class('mdc-tab__icon'),
								attributes),
							nodes));
				} else {
					var node = icon.a.a.bj;
					var attributes = icon.a.a.aY;
					var nodes = icon.a.a.bk;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('mdc-tab__icon'),
								attributes),
							nodes));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}());
};
var $aforemny$material_components_web_elm$Material$TabBar$tabIndicatorContentElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-tab-indicator__content'),
			$elm$html$Html$Attributes$class('mdc-tab-indicator__content--underline')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt = function (config_) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-indicator')
				]),
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorContentElt])));
};
var $aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt = function (_v0) {
	var label = _v0.gE;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab__text-label')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				])));
};
var $aforemny$material_components_web_elm$Material$TabBar$tabContentElt = F3(
	function (barConfig, config_, content) {
		var indicatorSpansContent = barConfig.ba;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-tab__content')
					]),
				indicatorSpansContent ? A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabIconElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt(config_)
						])) : A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabIconElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt(content)
						]))));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab'));
var $aforemny$material_components_web_elm$Material$TabBar$tabMinWidthCs = function (_v0) {
	var minWidth = _v0.ci;
	return minWidth ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-tab--min-width')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TabBar$tabRippleElt = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-tab__ripple')
			]),
		_List_Nil));
var $aforemny$material_components_web_elm$Material$TabBar$tabRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'tab'));
var $aforemny$material_components_web_elm$Material$TabBar$tabStackedCs = function (_v0) {
	var stacked = _v0.cY;
	return stacked ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-tab--stacked')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TabBar$viewTab = F3(
	function (index, barConfig, tab) {
		var indicatorSpansContent = barConfig.ba;
		var tabConfig = tab;
		var additionalAttributes = tabConfig.bF;
		var content = tabConfig.S;
		return A3(
			$elm$html$Html$node,
			'mdc-tab',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabCs,
							$aforemny$material_components_web_elm$Material$TabBar$tabRoleAttr,
							$aforemny$material_components_web_elm$Material$TabBar$tabStackedCs(barConfig),
							$aforemny$material_components_web_elm$Material$TabBar$tabMinWidthCs(barConfig)
						])),
				additionalAttributes),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				indicatorSpansContent ? _List_fromArray(
					[
						A3($aforemny$material_components_web_elm$Material$TabBar$tabContentElt, barConfig, tabConfig, content),
						$aforemny$material_components_web_elm$Material$TabBar$tabRippleElt
					]) : _List_fromArray(
					[
						A3($aforemny$material_components_web_elm$Material$TabBar$tabContentElt, barConfig, tabConfig, content),
						$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt(tabConfig),
						$aforemny$material_components_web_elm$Material$TabBar$tabRippleElt
					])));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollContentElt = F2(
	function (barConfig, tabs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-scroller__scroll-content')
				]),
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return A2($aforemny$material_components_web_elm$Material$TabBar$viewTab, index, barConfig);
				},
				tabs));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollAreaElt = F2(
	function (barConfig, tabs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-scroller__scroll-area')
				]),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollContentElt, barConfig, tabs)
				]));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScroller = F3(
	function (config_, align, tabs) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$TabBar$tabScrollerCs,
						$aforemny$material_components_web_elm$Material$TabBar$tabScrollerAlignCs(align)
					])),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollAreaElt, config_, tabs)
				]));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tablistRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'tablist'));
var $aforemny$material_components_web_elm$Material$TabBar$tabBar = F3(
	function (config_, tab_, tabs_) {
		var additionalAttributes = config_.bF;
		var align = config_.bG;
		var tabs = A2($aforemny$material_components_web_elm$Material$TabBar$enforceActive, tab_, tabs_);
		return A3(
			$elm$html$Html$node,
			'mdc-tab-bar',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$rootCs,
							$aforemny$material_components_web_elm$Material$TabBar$tablistRoleAttr,
							$aforemny$material_components_web_elm$Material$TabBar$activeTabIndexProp(tabs),
							$aforemny$material_components_web_elm$Material$TabBar$activatedHandler(tabs)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A3($aforemny$material_components_web_elm$Material$TabBar$tabScroller, config_, align, tabs)
				]));
	});
var $author$project$App$Search$View$viewTab = function (_v0) {
	var status = _v0.eM;
	var active = _v0.e9;
	var tabLabel = function (rT) {
		switch (rT) {
			case 0:
				return 'Definition';
			case 1:
				return 'Wiktionary';
			case 2:
				return 'Conjugation';
			default:
				return 'My List';
		}
	};
	var makeTabConfig = function (rT) {
		return A2(
			$aforemny$material_components_web_elm$Material$Tab$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '10px')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$Tab$setOnClick,
				$author$project$App$Search$Messages$SetResultTab(rT),
				A2(
					$aforemny$material_components_web_elm$Material$Tab$setActive,
					_Utils_eq(
						active,
						$elm$core$Maybe$Just(rT)),
					$aforemny$material_components_web_elm$Material$Tab$config)));
	};
	var makeTab = function (rT) {
		return A2(
			$aforemny$material_components_web_elm$Material$Tab$tab,
			makeTabConfig(rT),
			{
				gr: $elm$core$Maybe$Nothing,
				gE: tabLabel(rT)
			});
	};
	var config = A2(
		$aforemny$material_components_web_elm$Material$TabBar$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '50px')
			]),
		$aforemny$material_components_web_elm$Material$TabBar$config);
	if ((!status.$) && status.a.b) {
		if (!status.a.b.b) {
			var _v2 = status.a;
			var first = _v2.a;
			return A3(
				$aforemny$material_components_web_elm$Material$TabBar$tabBar,
				$aforemny$material_components_web_elm$Material$TabBar$config,
				makeTab(first),
				_List_Nil);
		} else {
			var _v3 = status.a;
			var first = _v3.a;
			var others = _v3.b;
			return A3(
				$aforemny$material_components_web_elm$Material$TabBar$tabBar,
				$aforemny$material_components_web_elm$Material$TabBar$config,
				makeTab(first),
				A2($elm$core$List$map, makeTab, others));
		}
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$App$Search$View$viewResult = function (model) {
	var status = model.eM;
	var active = model.e9;
	var result = model.ex;
	var viewForType = function (rT) {
		switch (rT) {
			case 3:
				var _v4 = result.h2;
				if (!_v4.$) {
					var eWEDs = _v4.a;
					return $author$project$App$Search$Entities$View$view(eWEDs);
				} else {
					return A2($elm$html$Html$div, _List_Nil, _List_Nil);
				}
			case 2:
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			case 0:
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			default:
				return A2(
					$elm$html$Html$map,
					$author$project$App$Search$Messages$WiktionaryMsg,
					$author$project$App$Wiktionary$View$view(model.e3));
		}
	};
	var _v0 = _Utils_Tuple2(status, active);
	if (_v0.a.$ === 1) {
		var _v1 = _v0.a;
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		if (!_v0.a.a.b) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			if (!_v0.a.a.b.b) {
				var _v2 = _v0.a.a;
				var first = _v2.a;
				return viewForType(first);
			} else {
				if (!_v0.b.$) {
					var searchResultTypes = _v0.a.a;
					var chosenTab = _v0.b.a;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$App$Search$View$viewTab(model),
								viewForType(chosenTab)
							]));
				} else {
					return A2($elm$html$Html$div, _List_Nil, _List_Nil);
				}
			}
		}
	}
};
var $author$project$App$Search$View$view = function (model) {
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'max-width', '480px'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '70px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$App$Search$Messages$SearchBarMsg,
								$author$project$App$Search$SearchBar$View$view(model.eB))
							])),
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								$author$project$App$Search$View$viewResult(model)
							]))
					]))
			]));
};
var $author$project$App$View$viewLoggedIn = function (model) {
	var viewContent = function (nav) {
		switch (nav) {
			case 0:
				return A2(
					$elm$html$Html$map,
					$author$project$App$Messages$SearchMsg,
					$author$project$App$Search$View$view(model.eA));
			case 1:
				return A2(
					$elm$html$Html$map,
					$author$project$App$Messages$MetricsMsg,
					$author$project$App$Metrics$View$view(model.d7));
			case 2:
				return A2(
					$elm$html$Html$map,
					$author$project$App$Messages$EffortMsg,
					$author$project$App$Effort$View$view(model.dy));
			default:
				return A2(
					$elm$html$Html$map,
					$author$project$App$Messages$EntitiesMsg,
					$author$project$App$Entities$View$view(model.dC));
		}
	};
	var isActive = function (nav) {
		switch (nav) {
			case 0:
				return true;
			case 2:
				return $elm$core$List$length(model.dy.el) > 0;
			case 1:
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							return true;
						},
						model.d7.d7));
			default:
				return $elm$core$List$length(model.dC.b3) > 0;
		}
	};
	var activeNavs = A2(
		$elm$core$List$filter,
		isActive,
		_List_fromArray(
			[2, 1, 3, 0]));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$App$Messages$AppBarMsg,
						A2($author$project$App$AppBar$View$view, activeNavs, model.di))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$aforemny$material_components_web_elm$Material$TopAppBar$fixedAdjust]),
				_List_fromArray(
					[
						viewContent(model.di)
					]))
			]));
};
var $author$project$App$View$view = function (model) {
	return $author$project$App$User$Model$isLoggedIn(model.eX) ? $author$project$App$View$viewLoggedIn(model) : A2(
		$elm$html$Html$map,
		$author$project$App$Messages$UserMsg,
		$author$project$App$User$View$view(model.eX));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{
		gz: function (_v0) {
			return $author$project$App$Update$init;
		},
		hI: $author$project$App$Subscriptions$subscriptions,
		h4: $author$project$App$Update$update,
		id: $author$project$App$View$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));